import React from "react";

const CustomDot = ({ cx, cy, value, index, data , symbol}: any) => {
    // Only render custom dots for the last 5 data points
    if (index >= data.length - 5) {
      // Format the value as a string in European currency format
      const numericValue = Number(value);
      const formattedValue = new Intl.NumberFormat('it-IT', {
        minimumFractionDigits: 2, 
        maximumFractionDigits: 2,
      }).format(numericValue);
      
  
      return (
        <g>
          <circle cx={cx} cy={cy} r={5} fill="black" stroke="none" />
          <text x={cx} y={cy - 10} textAnchor="middle" fill="black" style={{ fontSize: '10px' }} transform={`rotate(-50, ${cx}, ${cy - 10})`}>
            {formattedValue} {symbol}
          </text>
        </g>
      );
    }
    return null;
  };
  
  export default CustomDot;