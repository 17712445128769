import './DateRangePicker.scss';    // import './css/DateRangePicker.scss';

import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';


import CustomButton from '../CustomButton/CustomButton';
import CustomGroup from '../CustomGroup/CustomGroup';


import Datetime from '../../helpers/datetime';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import language from '../../languages';

const DateRangePicker: React.FC<any> = (props:any) => {
    
    const lang = language(/*session?.data?.userData?.lang*/);
    const ref = useRef<any>();

    // const session = useContext(ctxSession);
    // const snackbar = useContext(ctxSnackbar);

    const { name, placeholder, label, singleDay, outerValue } = props;
    const { onChange, onReset, onClose } = props;

    const [focused, setFocused] = useState<boolean>(outerValue ? true : false);

    const [isOpen, setIsOpen] = useState<boolean>(false)

    const toggleCalendar = () => {
        setIsOpen(!isOpen);
    }

    const [interval, setInterval] = useState<any>({
        start: props.start || new Date(),
        end: props.end || new Date()
    });
    const resetInterval = () => setInterval({start: null, end: null});
    const [selectedInterval, setSelectedInterval] = useState<any>({
        start: null,
        end: null
    });

    const formatCurrent = (date:string) => {
        const t = new Datetime(date).getDate().split('-');
        t.pop();
        return {
            year: t[0],
            month: t[1]
        };
    }

    const [current, setCurrent] = useState<any>(formatCurrent(interval.start));

    useEffect(() => {
        if(outerValue){
            setSelectedInterval(outerValue);
        }
    }, [outerValue]);

    useEffect( () => {
        if(onChange){
            if(selectedInterval.start && selectedInterval.end) onChange(selectedInterval, ref);
        }
        if(!selectedInterval.start && !selectedInterval.end) {
            if(onChange) onChange(selectedInterval, ref);
            if(onReset) {
                onReset(selectedInterval, ref);
            }
        }
    }, [interval, selectedInterval]);

    useEffect( () => {
        if(!isOpen && onClose){
            if(selectedInterval.start && selectedInterval.end) onClose(selectedInterval, ref);
        }
    }, [selectedInterval, isOpen]);


    useEffect(() => {
        if(isOpen){
            const handleOutsideClick = (event: MouseEvent) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsOpen(false);
                }
            };

            document.addEventListener('click', handleOutsideClick);

            return () => {
                document.removeEventListener('click', handleOutsideClick);
            };
        }
    }, [isOpen]);

    
    const formatZero = (num:number) => {
        return ('0' + num).slice(-2);
    }

    const daysInMonth = (year:number, month:number) => new Date(year, month + 1, 0).getDate();

    


    const generateMonthCalendar = useCallback( (year:number, month:number) => {

        const {start, end} = selectedInterval;
        const start_ts = new Datetime(start).getUnixTimestamp();
        const end_ts = new Datetime(end).getUnixTimestamp();

        // Funzione per ottenere il numero di giorni in un mese
        const dates = new Array();
        const monthDays = new Array();
      
        for (let i = 1; i <= daysInMonth(year, month); i++) {
            const calc_date = new Date(year, month, i);
        
            monthDays.push(new Date(calc_date)); // Non conosco la classe Datetime, quindi ho usato Date.
            dates.push(calc_date.getDay());
        }
      
        const rows = [];
        let k = 0;
      
        for (let i = 0; i < 6; i++) {
            let row = [];
            for (let j = 0; j < 7; j++) {
                if (j === dates[k]) {
                    const day = k; // Memorizza il giorno in una variabile per passarlo alla funzione onClick

                    const dt = new Datetime(monthDays[day]);
                    const fday = new Date(dt.getDate());
                    const fday_ts = new Datetime(fday).getUnixTimestamp();  //Risolve il problema del GMT
                    
                    // console.log(fday_ts, start_ts)
                    // console.log(fday, start)
                    
                    row.push(
                        <div key={j}
                            className={`day `
                                +`${ fday_ts >= start_ts && (fday_ts <= end_ts || fday_ts === start_ts || fday_ts === end_ts) ? 'range' : '' } `
                                +`${ fday_ts === start_ts ? 'first' : '' } `
                                +`${ fday_ts === end_ts ? 'last' : ''} `
                            }
                            onClick={() => {
                                if(singleDay){
                                    setSelectedInterval({
                                        start: fday,
                                        end: fday
                                    });
                                    resetInterval();
                                    return;
                                }
                                if( selectedInterval.start && selectedInterval.end ){
                                    
                                    if(fday > selectedInterval.end){
                                        setSelectedInterval({
                                            ...selectedInterval,
                                            end: fday
                                        });
                                    }
                                    else if(fday < selectedInterval.start){
                                        setSelectedInterval({
                                            ...selectedInterval,
                                            start: fday
                                        });
                                    }else{
                                        if(fday >= selectedInterval.start || fday <= selectedInterval.end){
                                            setSelectedInterval({
                                                start: null,
                                                end: null
                                            });
                                        }
                                    }

                                }
                                else{
                                    
                                    if( !selectedInterval.start ){
                                        setSelectedInterval({
                                            start: fday,
                                            end: null
                                        });
                                    }
                                    else if( !selectedInterval.end ){
                                        if(fday < selectedInterval.start ){
                                            setSelectedInterval({
                                                ...selectedInterval,
                                                start: fday
                                            });
                                        }else{
                                            setSelectedInterval({
                                                ...selectedInterval,
                                                end: fday
                                            });
                                            
                                            resetInterval();
                                        }
                                    }

                                }
                            }}
                        >
                            {day + 1}
                        </div>
                    );
                    k += 1;
                } else {
                    row.push(<div key={j} className='empty-day'></div>);
                }
            }
            rows.push(<div key={i} className='week'>{row}</div>);
            // Se abbiamo già coperto tutti i giorni del mese, non c'è bisogno di continuare
            if (k === dates.length) {
                break;
            }
        }
      
        return rows;
    }, [selectedInterval]);


    const weekHeader =  <div className="weekHeader">
                            <div className="weekDay">Su</div>
                            <div className="weekDay">Mo</div>
                            <div className="weekDay">Tu</div>
                            <div className="weekDay">We</div>
                            <div className="weekDay">Th</div>
                            <div className="weekDay">Fr</div>
                            <div className="weekDay">Sa</div>
                        </div>

    const calendar = useMemo(() => {
        const leftMonth = generateMonthCalendar(current.year, parseInt(current.month) - 1);
        const rightMonth = !singleDay ? generateMonthCalendar(current.year, parseInt(current.month) ) : <></>;
        

        
        if(singleDay && selectedInterval.start != selectedInterval.end){
            setSelectedInterval({
                ...selectedInterval,
                end: selectedInterval.start
            })
        }
      
        return (
          <>
            <div className='left'>
                {weekHeader}
                {leftMonth}
            </div>
            { !singleDay &&
                <div className='right'>
                    {weekHeader}
                    {rightMonth}
                </div>
            }
          </>
        );
      }, [current, selectedInterval, singleDay]);

    const prevMonth = () => {
        let prev = {...current}
        if(parseInt(current.month) === 1){
            prev.year = ( parseInt(prev.year) - 1) ;
            prev.month = '12';
        }else{
            prev.month = formatZero( parseInt(prev.month) - 1) ;
        }
        setCurrent(prev)
    }
    
    const nextMonth = () => {
        let next = {...current}
        if(parseInt(current.month) === 12){
            next.year = ( parseInt(next.year) + 1) ;
            next.month = '01';
        }else{
            next.month = formatZero( parseInt(next.month) + 1) ;
        }
        setCurrent(next)
    }
      
    const dayDiff = (tsEnd:number, tsStart:number) =>  ( (tsEnd - tsStart) / 86400 ) + 1  ;

    const dayCount = useMemo( () => {
        if(selectedInterval.start && selectedInterval.end){
            const tsEnd = new Datetime(selectedInterval.end).getUnixTimestamp() ;
            const tsStart = new Datetime(selectedInterval.start).getUnixTimestamp() ;
            
            return dayDiff(tsEnd, tsStart);
        }else if(interval.start && interval.end){
            const tsEnd = new Datetime(interval.end).getUnixTimestamp() ;
            const tsStart = new Datetime(interval.start).getUnixTimestamp() ;

            return dayDiff(tsEnd, tsStart);
        }else{
            return 0;
        }
    }, [interval, selectedInterval])

    let className="dateRangePicker ";
    switch(props.variant){
        case 'cloud': {
            className += "dateRangePickerCloud ";
            break;
        }
        case 'auto':
        default: {
            
        }
    }
    
    return (
        <div ref={ref} className={`${className} ${singleDay ? 'singleDay' : '' } ${focused || selectedInterval?.start !== null ? 'focused' : ''}`}>
            {
                name &&
                <>
                {
                singleDay
                ?
                <input type="date" name={name} value={selectedInterval?.start?.toISOString().split('T')[0]} />
                :
                <>
                <input type="date" name={name+"_start"} value={selectedInterval?.start?.toISOString().split('T')[0]} />
                <input type="date" name={name+"_end"} value={selectedInterval?.end?.toISOString().split('T')[0]} />
                </>
                }
                </>
            }
            <div className={`input`} onClick={toggleCalendar}
            // style={style}
            >
                <div className='icon'>
                    <CalendarMonthIcon/>
                </div>
                <div className='range'>
                    {label && <label>{label}</label>}
                    {
                        (placeholder && !label) && !selectedInterval.start
                        ?
                        <div className='start'>
                            {placeholder}
                        </div>
                        :
                        <>
                        {
                            label && !selectedInterval.start
                            ?
                            <>
                                <div className="start"></div>
                            </>
                            :
                            <>
                                <div className='start'>
                                    {selectedInterval.start ? new Date(selectedInterval.start).toLocaleDateString(navigator.language, { year: 'numeric', month: '2-digit', day: '2-digit' }) : lang.date_format }
                                </div>
                                {
                                    !singleDay && 
                                    <div className='end'>
                                        {selectedInterval.start || selectedInterval.end ? (selectedInterval.end ? new Date(selectedInterval.end).toLocaleDateString(navigator.language, { year: 'numeric', month: '2-digit', day: '2-digit' }) : lang.date_format ) : lang.date_format }
                                    </div>
                                }
                            </>
                        }
                            
                        </>
                    }
                </div>
            </div>
            <div className={`calendar ${isOpen ? 'show' : '' } ${props.position || ''}`}>
                
                <div className='header'>
                    <div className='leftButton' onClick={prevMonth}>
                        <ChevronLeftIcon />
                    </div>
                    <div className='leftTitle'>
                        {new Date(current.year, current.month-1, 1).toLocaleDateString(navigator.language, { year: 'numeric', month: 'long' })}
                        {/* {current.year}{props.separator || '-'}{formatZero(parseInt(current.month))} */}
                    </div>
                    {
                        !singleDay && 
                        <div className='rightTitle'>
                            {new Date(current.year, current.month, 1).toLocaleDateString(navigator.language, { year: 'numeric', month: 'long' })}
                            {/* {current.year}{props.separator || '-'}{formatZero(parseInt(current.month)+1)} */}
                        </div>
                    }
                    <div className='rightButton' onClick={nextMonth}>
                        <ChevronRightIcon />
                    </div>
                </div>
                <div className='body'>
                    {calendar}
                </div>
                <div className='footer'>
                    <div>
                        {lang.selected_days}: {dayCount}
                    </div>
                    <div>
                        <CustomGroup
                            type="separated"
                        >
                            {/* <CustomButton
                                variant="secondary"
                                size="sm"
                                onClick={() => {
                                    setSelectedInterval({start: null, end: null});
                                    setIsOpen(false);
                                }}
                                disabled={!(selectedInterval.start && selectedInterval.end)}
                            >
                                {lang.reset}
                            </CustomButton> */}
                            <CustomButton
                                size="sm"
                                onClick={() => {
                                    if(props.onConfirm){
                                        if(selectedInterval.start && selectedInterval.end) props.onConfirm(selectedInterval, ref);
                                    }
                                    setIsOpen(false);
                                }}
                                disabled={!(selectedInterval.start && selectedInterval.end)}
                            >
                                {lang.confirm}
                            </CustomButton>
                        </CustomGroup>
                        
                    </div>
                </div>
            </div>
        </div>
    );
};
export default DateRangePicker;


// http://localhost:3001/