import './css/Report.scss';
import importer from '../helpers/importer';
import React from 'react';


const CheckBox = ({ label, isChecked }:any) => (
    <label>
      <input type="checkbox" checked={isChecked} readOnly />
      {label}
    </label>
  );

function ReportSteamTrap({ reportData }:any) {
    console.log('ReportInsulation props:', reportData);


    if (!reportData) {
        return <div>Loading...</div>;
      }

    
      return (

        <div className="report-section">

            <div id="Title" className="df"> STEAM TRAP / ST  <p style={{fontSize:'8px'}}>SCARICATORE DI CONDENSA</p></div>

            <div id="container" className="dfc">

                <header>
                    
                    <img width={80} src={importer.img.require('fullLogo.png')} /> 

                      <div className="client-header">
                          <div><strong>Cliente:</strong> SONATRACH </div>
                          <div><strong>SITO:</strong> RAFFINERIA DI AUGUSTA </div>
                          <div><strong>CAMPAGNA MONITORAGGIO:</strong> ISME_2024 </div>
                      </div>

                </header>


                <div className="intervention-details">
                    <div><strong>TAG number:</strong> {reportData.tag}</div>
                    <div><strong>Nome Rilevatore:</strong> {reportData.operator}</div>
                    <div><strong>Unit:</strong> {reportData.unit}</div>
                    <div><strong>Business Team:</strong> {reportData.business_team}</div>
                    <div><strong>Location:</strong> {reportData.location}</div>
                    <div><strong>Apparecchiatura / Componente:</strong>
                    {reportData.component_equipment} <span>Size:</span>{reportData.size}</div>
                </div>
                <div className="intervention-details-v">
                    <div><strong>Data rilevazione</strong> {reportData.f_inspection_date}</div>
                </div>


                <div className="photo-placeholder">
                  <h4>FOTO PRIMA</h4>
                  <div>
                  <img src={`/api/Images/fotoPerdita/${reportData.tag}.jpg`} alt={reportData.tag} />
                  </div>
                </div>

                <div className='details-h'>Dettagli</div>
                <div className="intervention-details-v">
                    <div><strong>Portata nominale scaricatore di condensa (t/h)</strong> {reportData.nominal_flow}</div>
                    <div><strong>DN scaricatore di condensa</strong> {reportData.dn_discharger}</div>
                    <div><strong>Scarico condensa a Sistema Chiuso</strong> {reportData.steam_discharge_to_closed_system ? 'Si' : 'No'}</div>
                    <div><strong>Tipologia di malfuzionamento (Bloccato / In perdita)</strong> {reportData.malfunctioning_type}</div>
                    <div><strong>Pressione (600, 365, 125, 55, 40 psig)</strong> {reportData.pressure}</div>
                    <div><strong>Tipologia scaricatore di condensa (termodinamico, secchiello, etc)</strong> {reportData.discharger_type}</div>
                    <div><strong>Necessario scoibentare</strong> {reportData.need_for_insulation === 0 ? "No" : "Si"}</div>
                    <div><strong>Possibilità di intercetto on stream</strong> {reportData.interception_possibility} </div>
                    <div><strong>Stato valvola di intercetto (Funzionante o no)</strong> {reportData.interception_valve_status === 1 ? 'Si' : reportData.interception_valve_status === 0 ? 'No' : reportData.interception_valve_status}</div>
                    <div><strong>Servizio (Traccia o Processo)</strong> {reportData.service ? reportData.service : ''}</div>
                    <div><strong>Presenza Notification</strong> {reportData.notification ? `Si (${reportData.notification})` : 'No' }</div>
                    <div><strong>Status</strong> {reportData.status ? 'Aperto' : 'Chiuso'}</div>
                    <div><strong>Data chiusura Notification</strong> {reportData.closure_notification !== 0 ? reportData.closure_notification : ""}</div>
                    
                    <div className='details-table'>
                      <div className='info'>
                        <strong>Priorità Energy (1-2-3)</strong>
                        <span style={{display: 'block', fontSize: '6px', lineHeight: '6px'}}>Funzione della pressione di scarico e portata elaborata qualora questo dato sia disponibile da misurazione o datasheet oppure da survey specifico. In mancanza del dettaglio relativo alla portata di vapore, il criterio di priorità consiste nel livello di pressione e nel servizio a cui lo scaricatore è deputato (scambiatori di calore maggiormente prioritari rispetto a tracce)</span>
                      </div>
                      <div className='tbl'>
                        <div>
                          <div className='value'>
                            <strong>Valore:{'\u00A0'}</strong>
                            {reportData.priority}
                          </div>
                        </div>
                        <div>
                          {'> 1 tonn/h'}
                        </div>
                        <div>
                          {'> 0.5 tonn/h'}
                        </div>
                        <div>
                          {'< 0.5 tonn/h'}
                        </div>
                        <div>
                        Alta pressione (600, 365)
                        </div>
                        <div className='red'>
                          1
                        </div>
                        <div className='red'>
                          1
                        </div>
                        <div className='orange'>
                          2
                        </div>
                        <div>
                        Media pressione (125)
                        </div>
                        <div className='red'>
                          1
                        </div>
                        <div className='orange'>
                          2
                        </div>
                        <div className='yellow'>
                          3
                        </div>
                        <div>
                          Bassa pressione (40, 55)
                        </div>
                        <div className='orange'>
                          2
                        </div>
                        <div className='yellow'>
                          3
                        </div>
                        <div className='yellow'>
                          3
                        </div>
                      </div>
                    </div>
                </div>
                
                <div className="photo-placeholder">
                  <h4>FOTO DOPO</h4>
                  <div>
                  <img src={`/api/Images/fotoRiparazione/${reportData.tag}.jpg`} alt={reportData.tag} />
                  </div>
                </div>
                
                <div className="intervention-details-v">
                    <div><strong>Data riparazione</strong> { reportData.f_repair_date ? reportData.f_repair_date : ''}</div>
                    <div><strong>Tipo intervento</strong> {reportData.intervention_description}</div>
                    <div><strong>POSTDATE</strong> {reportData.post_date}</div>
                    <div><strong>Motivazione</strong> {reportData.reason}</div>
                </div>

            </div>

        </div>

      );

    };

export default ReportSteamTrap;


