import './css/Dashboard.scss';
import React, { useRef, useState, useEffect, useContext, useCallback, ChangeEventHandler, ChangeEvent, useMemo } from 'react';

// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { ResponsiveContainer, BarChart, AreaChart, Area, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, ReferenceLine, Line, ComposedChart, PieChart, Pie, Cell, Customized } from 'recharts'
import { startOfYear, endOfYear, eachWeekOfInterval, getISOWeek, format, getMonth, eachMonthOfInterval, startOfWeek, startOfMonth, addWeeks, endOfWeek, addDays, isWithinInterval, endOfMonth, addMonths } from 'date-fns';
import { saveAs } from 'file-saver';

import regression from 'regression';


import ReportInsulation from './ReportInsulation';
import ReportSteamLeak from './ReportSteamLeak';
import ReportSteamTrap from './ReportSteamTrap';


import {
    Card,
    CustomTable,
    CustomButton,
    CustomIconButton,
    DateRangePicker,
    CustomGroup,
    CustomSelect,
    CustomModal,
    CustomActiveDot,
    CustomTooltip,
    CustomTooltipContent,
    CustomInput
} from '../components';

import CakeChart from '../components/charts/CakeChart';
// import Title from '../components/Title';

import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import DonutLargeRoundedIcon from '@mui/icons-material/DonutLargeRounded';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';// import importer from '../helpers/importer';
import PieChartIcon from '@mui/icons-material/PieChart';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import EditIcon from '@mui/icons-material/Edit';
import Fade from '@mui/material/Fade';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import ClearIcon from '@mui/icons-material/Clear';
import SettingsBackupRestoreRoundedIcon from '@mui/icons-material/SettingsBackupRestoreRounded';

import { ctxSession, ctxSettings } from '../store';

import Axios from '../helpers/axios';
import config from '../config';

import language from '../languages';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import axios, { AxiosError, AxiosResponse } from 'axios';
import CustomDot from '../components/CustomDot';

import {PriorityStats,
        Intervention,
        InterventionType, 
        InterventionStatus, 
        ColorPriority, 
        ColorValue, 
        colorPalettePriority,
        defaultColor} from '../types';

import {calcStats} from '../services/CalcStatsService';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';

const html2pdf = require('html2pdf.js');


/** UTILITIES -- DashboardUtilities.ts */


// Utility function to format date in European format (DD/MM/YYYY)
export const formatDateToEuropean = (timestamp:any) => {
    const date = new Date(timestamp);
    const day = date.getUTCDate().toString().padStart(2, '0'); // Use UTC date
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Use UTC month, getUTCMonth() returns month from 0-11
    const year = date.getUTCFullYear(); // Use UTC year

    return `${day}/${month}/${year}`;
};


/** INTERFACES -- DashboardInterfaces.ts */


// --- Dates
type DateRange = {
    startDate: Date | null;
    endDate: Date | null;
};

enum TimeFilterType {
    inspection_date = 0,
    repair_date = 1,
}


// --- Charts
interface HistogramCandle {
    id: string;
    date: string;
    interventions: number;
    candleInterventions: number;
    leaks: number;
    leaksCO2: number;
    avgLeakage: number;
    resolvedLeakage: number;
    resolvedLeakageCO2: number;
    repair_date: string;
    inspection_date: string;
    deltaTime: number;
    // f_repair_date?: string;
}

interface DataPoint {
    dateNumeric: number;
    loc?: number;
    gain?: number;
    co2?: number;
    sparedCO2?: number;
    resolvedLeakageCO2?: number;
    regressionLoss?: number;
    crl?: number;
    deltaY?: number;
    interventionsAmount?: number;
    inspection_date: string;
    steam_flow_tonne?: number;
    repair_date: string;
    formattedDate: string | undefined;
    deltaTime: number;
    forecast?: number;
    weekOfYear?: number;
}


type Point = [number, number];




/** MAIN COMPONENT */

const Dashboard = () => {

    /* Document */
    document.title = config.app.name;
    const session = useContext(ctxSession);
    const settings = useContext(ctxSettings);
    const lang = language(settings?.data?.lang);

    const deletedColumnsRef = useRef<any>([
        {
            name: 'id',
            displayName: 'ID',
            width: 80,
            filtering: true,
            hide: true
        },
        {
            name: 'operator',
            displayName: 'Operatore',
            width: 140,
            filtering: true
        },
        {
            name: 'tag',
            displayName: 'TAG',
            width: 80,
            filtering: true,
            render: (row:any) => <div><strong>{row.tag}</strong></div>,
        },
        {
            name: 'unit',
            displayName: 'Unità',
            width: 100,
            filtering: true
        },
        {
            name: 'business_team',
            displayName: 'Team',
            width: 100,
            filtering: true
        },
        {
            name: 'location',
            displayName: 'Location',
            width: 120,
            filtering: true
        },
    ])


    /* Interventions Table */
    const columnsRef = useRef<any>([
        {
            name: 'id',
            displayName: 'ID',
            width: 80,
            filtering: true,
            hide: true
        },
        {
            name: 'plume_spec',
            displayName: 'plume_spec',
            width: 80,
            filtering: true,
            hide: true
        },
        {
            name: 'rowShow',
            filtering: false,
            disableSort: true,
            displayName: '',
            width: 120,
            render: (row:Intervention) => {

                return <CustomGroup type="separated">
                    <CustomIconButton onClick={(e:React.MouseEvent) => {
                        e.stopPropagation();
                        setSelectedData(row);
                        setIsModalOpen(true);
                        console.log(row)
                    }}>
                        <VisibilityRoundedIcon />
                    </CustomIconButton>
                    
                    {session?.data?.userData?.role === 'admin' &&
                        <CustomIconButton onClick={(e:React.MouseEvent) => {
                            e.stopPropagation();
                            setEditingReport(row);
                            editingReportRef.current = row;
                        }}>
                            <EditIcon />
                        </CustomIconButton>
                    }
                </CustomGroup>

            }


        },
        // {
        //     name: 'rowSelect',
        //     filtering: false,
        //     disableSort: true,
        //     // Display a checkbox that toggles all checkboxes on the table
        //     displayName: <input className="una-classe" type="checkbox" onClick={(e) => e.stopPropagation()} onChange={() => {
        //         if (selectedRowsRef.current.length !== 0) {
        //             selectedRowsRef.current = [];
        //         } else {
        //             selectedRowsRef.current = [...filteredRowsRef.current];
        //         }
        //         setSelectedRows(selectedRowsRef.current);
        //     }} />,
        //     width: 50,
        //     render: (row:any) => {

        //         // Render a checkbox and toggle the selection on selectedRows
        //         return <div key={row.id}><input type="checkbox" onClick={(e) => e.stopPropagation()} checked={selectedRowsRef.current.some(selectedRow => selectedRow.id === row.id)} onChange={() => handleRowSelect(row)} /></div>

        //     }
        // },
        {
            name: 'status',
            displayName: 'Stato',
            width: 90,
            render: () => null,
            dynamicClass: (row:any) => row.status ? 'opened' : 'closed'
        },
        {
            name: 'f_inspection_date',
            displayName: 'Data Ispezione',
            width: 160,
            filtering: true,
        },
        {
            name: 'operator',
            displayName: 'Operatore',
            width: 140,
            filtering: true
        },
        {
            name: 'priority',
            displayName: 'Priorità',
            width: 100,
            dynamicClass: (row:any) => {
                switch(row.priority){
                    case 1: {
                        return 'priority1'
                    }
                    case 2: {
                        return 'priority2'
                    }
                    case 3: {
                        return 'priority3'
                    }
                    default: {
                        return;
                    }
                }
            },
            render: (row:any) => <div>{row.priority}</div>,
            filtering: true
        },
        {
            name: 'tag',
            displayName: 'TAG',
            width: 80,
            filtering: true,
            render: (row:any) => <div><strong>{row.tag}</strong></div>,
        },
        {
            name: 'unit',
            displayName: 'Unità',
            width: 100,
            filtering: true
        },
        {
            name: 'business_team',
            displayName: 'Team',
            width: 100,
            filtering: true
        },
        {
            name: 'location',
            displayName: 'Location',
            width: 120,
            filtering: true
        },
        {
            name: 'component_equipment',
            displayName: 'Equipaggiamento',
            width: 180,
            filtering: true,
            render: (row:any) => {

                // First word and each word after each space must have first uppercase letter
                return <div>{row.component_equipment?.split(' ').map((word:string) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</div>

            }
            
        },
        {
            name: 'size',
            displayName: 'Misura',
            width: 100,
            filtering: true
        },
        {
            name: 'nominal_flow',
            displayName: 'Portata Nominale',
            width: 180,
            filtering: true,
            render: (row:any) => {
                    
                // If null or undefined or zero leave it empty
                if(row.nominal_flow === null || row.nominal_flow === undefined || row.nominal_flow === "0" || row.nominal_flow === ""){
                    return <div></div>
                }
                else{
                    return <div>{row.nominal_flow + " t/h"}</div>
                }

            }
        },
        {
            name: 'dn_discharger',
            displayName: 'DN Scaricatore',
            width: 180,
            filtering: true,
            render: (row:any) => {

                // If null or undefined or zero leave it empty
                if(row.dn_discharger === null || row.dn_discharger === undefined || row.dn_discharger === "0" || row.dn_discharger === ""){
                    return <div></div>
                }
                else{
                    return <div>{row.dn_discharger}</div>
                }

            }
        },
        {
            name: 'malfunctioning_type',
            displayName: 'Tipo Malfunzionamento',
            width: 180,
            filtering: true,
            render: (row:any) => {
                    
                    // If null or undefined or zero leave it empty
                    if(row.malfunctioning_type === null || row.malfunctioning_type === undefined || row.malfunctioning_type === "0" || row.malfunctioning_type === ""){
                        return <div></div>
                    }
                    else{
                        return <div>{row.malfunctioning_type}</div>
                    }
    
                }
        },
        {
            name: 'pressure',
            displayName: 'Pressione',
            width: 140,
            filtering: true,
            render: (row:any) => {

                // If null or undefined or zero leave it empty
                if(row.pressure === null || row.pressure === undefined || row.pressure === "0" || row.pressure === ""){
                    return <div></div>
                }
                else{
                    return <div>{row.pressure} psig</div>
                }

            }
        },
        {
            name: 'discharger_type',
            displayName: 'Tipo Scaricatore',
            width: 180,
            filtering: true,
            render: (row:any) => {
                    
                    // If null or undefined or zero leave it empty
                    if(row.discharger_type === null || row.discharger_type === undefined || row.discharger_type === "0" || row.discharger_type === ""){
                        return <div></div>
                    }
                    else{
                        return <div>{row.discharger_type}</div>
                    }
    
                }
        },
        {
            name: 'need_for_insulation',
            displayName: 'Necessario Scoibentare',
            width: 200,
            filtering: true,
            render: (row:any) => <div>{row.need_for_insulation ? 'Si' : 'No'}</div>
        },
        {
            name: 'trait_length',
            displayName: 'Lunghezza Tratto Ammalorato (m)',
            width: 180,
            filtering: true,
            render: (row:any) => {
                    
                // If null or undefined or zero leave it empty
                if(row.trait_length === null || row.trait_length === undefined || row.trait_length === "0" || row.trait_length === ""){
                    return <div></div>
                }
                else{
                    return <div>{row.trait_length.includes("mt") ? row.trait_length : row.trait_length + " mt"}</div>
                }

            }
        },
        {
            name: 'plume_length',
            displayName: 'Lunghezza Pennacchio',
            width: 200,
            filtering: true
        },
        {
            name: 'metal_sheet',
            displayName: 'Lamierino',
            width: 180,
            filtering: true,
            render: (row:any) => {
                    
                // If null or undefined or zero leave it empty
                if(row.metal_sheet === null || row.metal_sheet === undefined || row.metal_sheet === "0" || row.metal_sheet === ""){
                    return <div></div>
                }
                else{
                    return <div>{row.metal_sheet}</div>
                }

            }
        },
        {
            name: 'insulation_material',
            displayName: 'Mezzo Isolante',
            width: 180,
            filtering: true,
            render: (row:any) => {
                    
                // If null or undefined or zero leave it empty
                if(row.insulation_material === null || row.insulation_material === undefined || row.insulation_material === "0" || row.insulation_material === ""){
                    return <div></div>
                }
                else{
                    return <div>{row.insulation_material}</div>
                }

            }
        },
        {
            name: 'scaffolding',
            displayName: 'Necessario Ponteggio (m)',
            width: 160,
            filtering: true,
            render: (row:any) => {
                if(!isNaN(row.scaffolding) && !isNaN(parseFloat(row.scaffolding))){
                    return <div>{row.scaffolding + " mt"}</div>
                }else{
                    return <div>{row.scaffolding}</div>
                }
            }
        },
        {
            name: 'interception_possibility',
            displayName: 'Possibilità Intercetto',
            width: 160,
            filtering: true,
            render: (row:any) => <div> {row.interception_possibility} </div>
        },
        {
            name: 'metal_sheet_temperature',
            displayName: 'Temperatura Lamierino',
            width: 180,
            filtering: true,
            render: (row:any) => {
                    
                // If null or undefined or zero leave it empty
                if(row.metal_sheet_temperature === null || row.metal_sheet_temperature === undefined || row.metal_sheet_temperature === "0"){
                    return <div></div>
                }
                else{
                    return <div>{(row.metal_sheet_temperature.includes("°C") | row.metal_sheet_temperature.includes("° C")) ? row.metal_sheet_temperature : row.metal_sheet_temperature + " ° C"}</div>
                }

            }
        },
        {
            name: 'pipe_temperature',
            displayName: 'Temperatura Tubazione',
            width: 180,
            filtering: true,
            render: (row:any) => {
                    
                // If null or undefined or zero leave it empty
                if(row.pipe_temperature === null || row.pipe_temperature === undefined || row.pipe_temperature === "0" || row.pipe_temperature === ""){
                    return <div></div>
                }
                else{
                    return <div>{(row.pipe_temperature.includes("°C") | row.pipe_temperature.includes("° C")) ? row.pipe_temperature : row.pipe_temperature + " ° C"}</div>
                }

            }
        },
        {
            name: 'competence',
            displayName: 'Competenza',
            width: 200,
            filtering: true,
        //     render: (row:any) => {
                    
        //         // If null or undefined or zero leave it empty
        //         if(row.competence === null || row.competence === undefined || row.competence === "0" || row.reason === ""){
        //             return <div></div>
        //         }
        //         else{
        //             return <div>{row.competence}</div>
        //         }

        // }
        },
        {
            name: 'interception_valve_status',
            displayName: 'Stato Intercetto Valvola',
            width: 200,
            filtering: true,
            hide: true,
            render: (row:any) => <div>{row.interception_valve_status ? 'Si' : 'No'}</div>
        },
        {
            name: 'asbestos',
            displayName: 'Amianto',
            width: 120,
            filtering: true,
            render: (row:any) => <div>{row.asbestos ? 'Si' : 'No'}</div>
        },
        {
            name: 'intervention_type',
            displayName: 'Tipo Intervento',
            width: 160,
            filtering: true,
            hide: true,
            render: (row:any) => {

                // Matches with the Select order, INSULATION REPORT = 0, STEAM LEAK REPORT = 1, STEAM TRAP REPORT = 2
                switch(row.intervention_type){
                    case 0: {
                        return <div>INSULATION REPORT</div>
                    }
                    case 1: {
                        return <div>STEAM LEAK</div>
                    }
                    case 2: {
                        return <div>STEAM TRAP</div>
                    }
                    default: {
                        return;
                    }
                }
                

            }
        },
        {
            name: 'intervention_description',
            displayName: 'Descrizione Intervento',
            width: 200,
            filtering: true,
            hide: true
        },
        {
            name: 'reason',
            displayName: 'Motivazione',
            width: 140,
            filtering: true,
            hide: true,
            render: (row:any) => {
                    
                    // If null or undefined or zero leave it empty
                    if(row.reason === null || row.reason === undefined || row.reason === "0" || row.reason === ""){
                        return <div></div>
                    }
                    else{
                        return <div>{row.reason}</div>
                    }
    
            }
        },
        {
            name: 'steam_flow_kg',
            displayName: 'Portata Vapore (Kg)',
            width: 180,
            filtering: true,
            hide: true,
            render: (row:any) => {
                    
                // If null return empty, else display the value
                if(row.steam_flow_kg === null || row.steam_flow_kg === undefined){
                    return <div></div>
                }
                else{
                    return <div>{row.steam_flow_kg}</div>
                }

            }   
        },
        {
            name: 'steam_flow_tonne',
            displayName: 'Portata Vapore (T.)',
            width: 180,
            filtering: true,
            hide: true
        },
        {
            name: 'service',
            displayName: 'Servizio',
            width: 180,
            filtering: true,
            hide: true
        },
        {
            name: 'steam_discharge_to_closed_system',
            displayName: 'Scarico Condensa a sistema chiuso',
            width: 180,
            filtering: true,
            hide: true,
            render : (row:any) => <div>{row.steam_discharge_to_closed_system ? 'Si' : 'No'}</div>
        },
        {
            name: 'notification',
            displayName: 'Notification',
            width: 140,
            filtering: true,
            render: (row:any) => {

                // Notification can be boolean or number
                if(row.notification){
                    return <div>{`Si (${row.notification})`}</div>
                }
                else{
                    return <div>No</div>
                }

            }
        },
        {
            name: 'closure_notification',
            displayName: 'Chiusura Notification',
            width: 180,
            filtering: true,
            hide: true,
            render: (row:any) => <div>{row.closure_notification ? 'Si' : 'No'}</div>
        },
        {
            name: 'post_date',
            displayName: 'POST DATE',
            width: 140,
            filtering: true,
            render: (row:any) => {
                    
                // If null or undefined or zero leave it empty
                if(row.reason === null || row.post_date === undefined || row.post_date === "0" || row.post_date === ""){
                    return <div></div>
                }
                else{
                    return <div>{row.post_date}</div>
                }

            }
        },
        {
            name: 'f_repair_date',
            displayName: 'Data Riparazione',
            width: 160,
            filtering: true,
            searchInRender: true,
            render: (row:any) => {

                // Return empty if the repair date is not available
                if(row.f_repair_date === null || row.f_repair_date === undefined || row.f_repair_date === "0" || row.f_repair_date === ""){
                    return <div></div>
                }else{
                    return <div>{row.f_repair_date}</div>
                }

            }
        },
    ]);

    /* Customer Configuration */
    const [steamPrice, setSteamPrice] = useState<number>(50.0); // [€/t]
    const [co2Price, setCo2Price] = useState<number>(80.0); // [€/tCO2

    // Picker reset
    const [pickerReset, setPickerReset] = useState<any>();

    // Modal
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<any>(null);
    const selectedDataRef = useRef<any>(null);

    // Recovery Modal
    const [isRecoveryModalOpen, setIsRecoveryModalOpen] = useState<boolean>(false);

    // Group Filters
    const [priorityFilter, setPriorityFilter] = useState<number | null>(null);
    const [statusFilter, setStatusFilter] = useState<"opened" | "closed" | null>(null);


    // Conditional Rendering
    type ShowTableType = "half" | "full" | "hidden";
    const [showTable, setShowTable] = useState<ShowTableType>("hidden" as ShowTableType);


    
    const [editingReport, setEditingReport] = useState<Intervention|null>(null);
    const editingReportRef = useRef<Intervention|null>(null);



 /**
     * @section TimeFrame Selector
     * @brief Handles the selection of the time frame and the active state between CustomButtons
    */

    /* States/Ref */
    const [timeFrame, setTimeFrame] = useState<"day" | "week" | "month" | "year" | "all">("day");



    /** 
     * @section Fetching
     * @brief Fetch the data from the API
    */


    /* States/Refs */
    const [rows, setRows] = useState<Intervention[]>([]);                   
    const rowsRef = useRef<Intervention[]>([]);  
    const originalDataRef = useRef<Intervention[]>([]);                          


    /* Fetch the data from the API */
    const fetchData = async () => {

        const onSuccess = (response:AxiosResponse) => {

            /// Early return if no data is available
            if(!response.data.data) return;

            // Reset Filters
            setSelectedTimeFilterType(0);
            setDateInterval(null);
            setPickerReset({start:null, end:null});
            setPriorityFilter(null);
            setStatusFilter(null);

            // Map the response to convert the dates of inspection and repair from ISO to dd/mm/yyyy
            response.data.data.forEach((row: Intervention) => {
                
                // Convert the ISO date string to a Date object
                if (row.inspection_date) {
                    row.f_inspection_date = formatDateToEuropean(row.inspection_date);
                }

                // Convert the ISO date string to a Date object
                if (row.repair_date) {
                    row.f_repair_date = formatDateToEuropean(row.repair_date);
                }

            });

            // Update the state with the fetched data
            setRows(response.data.data);
            rowsRef.current = response.data.data;
            originalDataRef.current = response.data.data;      
        }

        const onError = (error:AxiosError) => { console.error(error); alert('Errore durante il caricamento dei dati!')}
        
        // Fetch the data from the API
        if(!session) return;
        Axios(session, 'get', `${config.api.internal}/steamleaks/reports`, onSuccess, onError);

    }

    /* Fetch the data from the API */
    useEffect(() => {
        const getData = async () => {
            await fetchData();
        }
        getData();
    }, []);


    /** 
     * @section Deletion
     * @brief Delete one or more Records
    */

    /* Delete one or more rows */
    const deleteRows = async (mode:string) => {

        /// If no rows are selected, return
        let data = {};
        switch(mode){
            case 'delete': {
                if(selectedRowsRef.current.length === 0) return;
                data = {
                    ids: selectedRowsRef.current.map(row => row.id)
                }        
                break;
            }
            case 'restore': {
                if(selectedDeletedRowsRef.current.length === 0) return;
                data = {
                    ids: selectedDeletedRowsRef.current.map(row => row.id)
                }   
                break;
            }
        }

        /// Confirm the deletion
        if(!window.confirm('Sei sicuro di voler procedere?')) return;

        const onSuccess = (response:AxiosResponse) => {
            if(response.data.success){
                alert('Operazione completata con successo!');
                fetchData();
            }
        }

        const onError = (error:AxiosError) => { 
            console.error(error);
            alert(`Errore: ${error}`);
        }

        // const data = {
        //     ids: selectedRowsRef.current.map(row => ({
        //         id: row.id,
        //         inspection_date: row.f_inspection_date,
        //     }))
        // };

        // Fetch the data from the API
        if(!session) return;
        Axios(session, 'post', `${config.api.internal}/steamleaks/toggleDeleteReports`, onSuccess, onError, data);
    }






    /** 
     * @section Chart Selection 
     * @brief Toggle between Pie and Bar Chart
    */


    /* States/Refs */
    type PieChartIcon = "pie" | "bar" | "interventions";
    
    const [currentChart, setCurrentChart] = useState<PieChartIcon>("bar");

    /* Utility function to toggle between Piechart and Histogram Icons */

    const nextPieIconMap: Record<string, string> = {
        'interventions': 'bar',
        'bar': 'pie',
        'pie': 'interventions' // Ensures the cycle is circular
    };

    /* Utility function to toggle between Pie and Bar Chart */
    const toggleChart = useCallback(() => {
        // console.log(nextPieIconMap[currentChart])
        setCurrentChart(nextPieIconMap[currentChart] as PieChartIcon);
    }, [currentChart]);






    /** 
     * @section DataSource Filters
     * @brief Filter the data based on the selected filters
     * @description This section is responsible for filtering the data based on the selected intervention type, date range, priority and status.
     *              It also handles the column masking based on the selected intervention type.
    */


    /* States/Ref */
    const [currentColumns, setCurrentColumns] = useState<any>(columnsRef.current);
    const [currentDeletedColumns, setCurrentDeletedColumns] = useState<any>(deletedColumnsRef.current);
    const [selectedInterventionType, setSelectedInterventionType] = useState<number>(-1);
    const [selectedTimeFilterType, setSelectedTimeFilterType] = useState<number>(0);
    const [dateInterval, setDateInterval] = useState<DateRange | null>(null);


    /* Column Masking */
    useEffect(() => {

        /// Filter the data based on the selected intervention type
        let filteredByType = originalDataRef.current.filter(row => row.intervention_type === selectedInterventionType);
        if (selectedInterventionType === InterventionType.GLOBAL) {
            setCurrentColumns(columnsRef.current);
            return;
        }

            
        //Determine which columns should be visible, avoid hiding the rowSelect column
        const visibleColumns = columnsRef.current.filter((column: { name: keyof Intervention }) => {

            // Always keep the 'rowSelect' column
            if (column.name === 'rowSelect' || column.name === 'rowShow' || column.name === "intervention_type") {
                return true;
            }
        
            // Check if any row has a value for the column that is not null, not undefined, not '', and not 0
            const hasValidValue = filteredByType.some((row: Intervention) => {
                const value = row[column.name];
                return value !== null && value !== undefined && value !== '' && value !== 0 && value !== '0' && value !== 'N/A';
            });
        
            // Return true for columns that have at least one valid value
            return hasValidValue;
        });

        // Update the state that holds the current configuration of columns
        setCurrentColumns(visibleColumns);


    }, [selectedInterventionType, originalDataRef.current, dateInterval, selectedTimeFilterType, rows]);
 

    /* TypeDate Filter */
    useEffect(() => {

        /// Filter by intervention type if it's not the default value (-1).
        let filteredByType = originalDataRef.current.filter(row => row.intervention_type === selectedInterventionType);
        if (selectedInterventionType === InterventionType.GLOBAL) {
            filteredByType = originalDataRef.current;
        }


        // If priority filter is enabled, filter only records with the given priority
        if (priorityFilter) {
            filteredByType = filteredByType.filter(row => row.priority === priorityFilter);
        }

        // If status filter is enabled, filter only records with the given status
        if (statusFilter) {
            filteredByType = filteredByType.filter(row => row.status === (statusFilter === 'opened' ? 1 : 0));
        }

    
        // Filter by the selected date range if it's properly set.
        if (dateInterval?.startDate && dateInterval.endDate) {

            // Adjust endDate to include the entire day by setting the time to the end of the day.
            const startDate = new Date(dateInterval.startDate);
            const endDate = new Date(dateInterval.endDate);
            endDate.setHours(23, 59, 59, 999);
    
            /// Determine the field to filter based on the selected time filter type
            const selectedField = TimeFilterType[selectedTimeFilterType];
            if (!selectedField) {
                // If no valid field is selected, fallback to Type Filter
                setRows(filteredByType);
                rowsRef.current = filteredByType;
                return;
            }
    
            // Filter the already filteredByType rows based on the date range.
            const filteredRows = filteredByType.filter(row => {

                /// If the selected field is not a valid date field, return false.
                const rowDateValue = row[selectedField as keyof Intervention];
                if (!rowDateValue) return false;

                // Ensure rowDateValue is a string or number before converting to Date, this because Intervention contains boolean values (is_deleted)
                if (typeof rowDateValue !== 'string' && typeof rowDateValue !== 'number') {
                    return false;  // Skip rows where rowDateValue is not a string or number
                }

                // Convert the rowDateValue to a Date object
                const rowDate = new Date(rowDateValue);

                // Return true if the rowDate is within the selected date range.
                return rowDate >= startDate && rowDate <= endDate;

            });

            console.log("Filtered Rows: ", filteredRows);

            // Finally, update the state with the filtered rows.
            setRows(filteredRows);
            rowsRef.current = filteredRows;

        } else {

            // If dateInterval is not set, just apply the intervention type filter.
            setRows(filteredByType);
            rowsRef.current = filteredByType;

        }

    }, [selectedInterventionType, pickerReset, dateInterval, selectedTimeFilterType, priorityFilter, statusFilter, originalDataRef.current]);


    



    /** 
     * @section Stats 
     * @brief Calculate the stats of the data
     * @todo Make custom Hooks and Services for this
    */


    /* States/Refs */
    const [stats, setStats] = useState<PriorityStats[]|null>(null);         

    /* Calculate the stats when the rows or the selected intervention type change */
    useEffect(() => {

        // Calculate the stats of the data
        const stats = calcStats(selectedInterventionType, rowsRef);
        if(!stats) return; /// Early return if stats is null

        // Update the state with the calculated stats
        setStats(stats);

    }, [rows, selectedInterventionType]);


    /* Calculate the pie chart data when the rows change */
    useEffect(() => {

        // Filter the data based on the selected intervention type
        let filteredByType = selectedInterventionType === -1
            ? rows
            : rows.filter(row => row.intervention_type === selectedInterventionType);

        // 'status' indicates if an intervention is open (1) or closed (0)
        const statusData = filteredByType.reduce<{ [key: string]: number }>((acc, curr) => {
            const statusKey = curr.status === 0 ? 'Chiusi' : 'Aperti';
            acc[statusKey] = (acc[statusKey] || 0) + 1;
            return acc;
        }, {});

        const pieChartData = Object.keys(statusData).map(key => ({
            name: key,
            value: statusData[key],
        }));

        setInterventionStatusData(pieChartData);

    }, [rows]);






    /** 
     * @section Histogram 
     * @brief Aggregate Intervention data in timesteps
    */

    
    /* States/Refs */
    const [histogramData, setHistogramData] = useState<HistogramCandle[]>([]);
    const [averageLeakage, setAverageLeakage] = useState<number>(0);
    const [resolvedLeakageByIntervention, setResolvedLeakageByIntervention] = useState<number>(0);

    /** Aggregate Intervention data in timesteps */
    useEffect(() => {

        // Filter the data based on the selected intervention type
        let filteredByType = selectedInterventionType === -1
            ? rowsRef.current
            : rowsRef.current.filter(row => row.intervention_type === selectedInterventionType);
        if (selectedInterventionType === -1) {
            filteredByType = originalDataRef.current;
        }


        // -- AGGREGATION BY DATE
    
        // Aggregate the data by repair_date
        const aggregateDataRepair: Record<string, HistogramCandle> = filteredByType.reduce<{ [key: string]: HistogramCandle }>((acc, curr) => {
            const dateKey = curr.repair_date ? new Date(curr.repair_date).toISOString().split('T')[0] : 'Unknown';
            if (dateKey !== 'Unknown') {
                if (!acc[dateKey]) {
                    acc[dateKey] = {
                        id: curr.id,
                        date: dateKey,
                        interventions: 1,
                        candleInterventions: -1,
                        leaks: curr.steam_flow_tonne,
                        leaksCO2: curr.steam_flow_tonne * 0.19,
                        avgLeakage: 0,
                        resolvedLeakage: 0,
                        resolvedLeakageCO2: 0,
                        repair_date: curr.repair_date,     
                        inspection_date: curr.inspection_date,     
                        deltaTime: 0,          
                    };
                } else {
                    acc[dateKey].interventions += 1;
                    acc[dateKey].leaks += curr.steam_flow_tonne;
                }
            }
            return acc;
        }, {});



        // -- PROCESSING

        // Convert the aggregateData object to an array and sort it by date
        let processedDataRepair: HistogramCandle[] = Object.values(aggregateDataRepair)
            .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

        // Calculate the cumulative interventions and total leakage
        let cumulativeInterventions = 0;
        let totalLeakage = filteredByType.reduce((total, curr) => total + curr.steam_flow_tonne, 0);
    
        // Initialize a variable to keep track of the previous leakage value.
        let previousLeakage = 0;
        let previousResolvedLeakage = 0;
        let previousResolvedCO2Leakage = 0;

        
        // Update the processedDataRepair mapping to calculate the resolved leakage correctly.
        processedDataRepair = processedDataRepair.map((data, index) => {

            // Calculate the cumulative interventions and total leakage
            cumulativeInterventions += data.interventions;
            totalLeakage -= data.leaks;

            // Calculate resolved leakage as the difference between the previous and current leaks.
            // For the first element, resolved leakage is 0.
            const resolvedLeakage = index === 0 ? data.leaks : (previousLeakage - totalLeakage) + previousResolvedLeakage;

            // Update previousLeakage for the next iteration.
            previousLeakage = totalLeakage;
            previousResolvedLeakage = resolvedLeakage;
            previousResolvedCO2Leakage = resolvedLeakage * 0.19;

            // Calculate average candle repair time
            const inspectionDate = new Date(data.inspection_date);
            const repairDate = new Date(data.repair_date);
            const deltaTime = (repairDate.getTime() - inspectionDate.getTime()) / (1000 * 60 * 60 * 24);

            return {
                ...data,
                id: data.id,
                interventions: cumulativeInterventions,
                candleInterventions: data.interventions,
                leaks: Math.max(totalLeakage, 0), // Ensure leaks don't go negative.
                leaksCO2: Math.max(totalLeakage * 0.19, 0), 
                avgLeakage: totalLeakage / (index + 1),
                resolvedLeakage: Math.max(resolvedLeakage, 0), // Add resolved leakage to the data, ensure it doesn't go negative.
                resolvedLeakageCO2: Math.max(resolvedLeakage * 0.19, 0), 
                date: formatDateToEuropean(new Date(data.date)),
                deltaTime: deltaTime
            };
            
        });

        // Update the state with the processed data
        setAverageLeakage(processedDataRepair.reduce((acc, curr) => acc + curr.leaks, 0) / processedDataRepair.length);
        setHistogramData(processedDataRepair);
        setResolvedLeakageByIntervention(processedDataRepair.reduce((acc, curr) => acc + curr.resolvedLeakage, 0) / processedDataRepair.length);


    }, [rows, dateInterval, selectedInterventionType, selectedTimeFilterType]);
    
      
      
    
    
    
    /** 
     * @section Trend Chart 
     * @brief Process Data for Trend Chart
    */


    /* States/Refs */
    const [interventionStatusData, setInterventionStatusData] = useState<InterventionStatus[]>([]);
    const [preProcessedChartData, setPreProcessedChartData ] = useState<any>([]); 
    const preProcessedChartDataRef = useRef<DataPoint[]>([]); 
    const [trendChart, setTrendChart] = useState<ChartType>('LOC');
    const [avgRepairTimeDays, setAvgRepairTimeDays] = useState<number>(0);
    const [avgRepairProfit, setAvgRepairProfit] = useState<number>(0);
    const [avgRepairTonne, setAvgRepairTonne] = useState<number>(0);
    const CO2_FACTOR = 0.19;


    /* Utility function to toggle between LOC and RL */
    type ChartType = 'RL' | 'LOC' | 'TM' | 'CO2' | 'CO2-E';

    const nextValueMap: Record<ChartType, ChartType> = {
        'LOC': 'RL',
        'RL': 'CO2',
        'CO2': 'TM',
        'TM': 'CO2-E',
        'CO2-E': 'LOC' // Ensures the cycle is circular
    };

    const titleMap: Record<string, string> = {
        'LOC': 'RIENTRO PERDITE PER DATA RIPARAZIONE (LOC)',
        'RL': 'LEFT ON TABLE - REGRESSION LOSS PER GRUPPO RIPARAZIONI (CUMULATIVO)',
        'CO2': 'RIENTRO CO2 PER DATA RIPARAZIONE',
        // 'DY': 'ANDAMENTO REGRESSION LOSS DAL 31/12/2023',
        'TM': 'TEMPO MEDIO INTERVENTO PER ELIMINAZIONE PERDITE VAPORE',
        'CO2-E': 'ANDAMENTO EMISSIONI CO2'
    };



    const toggleTrendChart = (value:string) => {
        setTrendChart(value as ChartType);
    }

    /** Process Data for Trend Chart */
    useEffect(() => {

        // Filter the data based on the selected intervention type
        let filteredByType = selectedInterventionType === -1 
            ? rowsRef.current 
            : rowsRef.current.filter(row => row.intervention_type === selectedInterventionType);
        if (selectedInterventionType === -1) {
            filteredByType = originalDataRef.current;
        }

       
        // Dynamic LOC income that will decrease with each repair 
        let totalLoc = filteredByType.reduce((acc, curr) => acc + (curr.steam_flow_tonne * steamPrice * 24 * 365), 0); 
        let dynamicLOC = totalLoc;         
        let dynamicGain = 0;    
        
        // Dynamic RL income that will decrease with each repair
        let dynamicRL = 0;

        // Calculate the total time in days for all interventions
        let totalCountedInterventions = 0;
        let totalInterventionTime = filteredByType.map((row:Intervention) => {
                
                // Calculate the time between inspection and repair
                const inspectionDate = new Date(row.inspection_date);
                const repairDate = new Date(row.repair_date);

                if(!row.repair_date || row.repair_date === undefined || row.repair_date === "") return 0;
                let deltaTime = (repairDate.getTime() - inspectionDate.getTime()) / (1000 * 60 * 60 * 24);
                if(deltaTime < 0) return 0;

                totalCountedInterventions += 1;
                return deltaTime;
    
            }
        ).reduce((acc, curr) => acc + curr, 0);  // Total time in days for all interventions

        // Store for processed data
        // let processedDataRL: DataPoint[];    
        let processedDataCRL: DataPoint[];       
        let processedDataLOC: DataPoint[];  
        let processedDataCO2: DataPoint[];       
        let processedDataAVG: DataPoint[]; 





        // Process the data for the trend chart, calculating the Cumulatative RL (based on candles)
        if (trendChart === "RL") {
            // Initial mapping to process each row into the desired structure
            processedDataCRL = histogramData.map((row: HistogramCandle): DataPoint => {
                const matchingInterventions = filteredByType.filter((intervention: Intervention) => intervention.repair_date === row.repair_date);
                const rlPerLeak = matchingInterventions.reduce((acc, curr) => acc + (curr.steam_flow_tonne * steamPrice * (new Date(curr.repair_date).getTime() - new Date(curr.inspection_date).getTime()) / (3600000)), 0);
                dynamicRL += rlPerLeak; // Update the dynamic RL value
        
                return {
                    inspection_date: row.repair_date ?? "01/01/1999",
                    steam_flow_tonne: -1, // Placeholder
                    repair_date: row.repair_date ?? "01/01/1999",
                    crl: dynamicRL, // Cumulative RL value
                    dateNumeric: new Date(row.date).getTime(),
                    formattedDate: formatDateToEuropean(row.repair_date),
                    deltaTime: 0, // Placeholder
                    interventionsAmount: matchingInterventions.length,
                    weekOfYear: getISOWeek(new Date(row.repair_date)) // Assuming this function correctly calculates the ISO week number
                };
            });
        
            // Adjust each data point date to the Monday of its week for weekly aggregation
            processedDataCRL.forEach(data => {
                if (data.repair_date) {
                    const date = new Date(data.repair_date);
                    const dayOfWeek = date.getDay();
                    const difference = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust to previous Monday
                    date.setDate(difference);
                    data.repair_date = date.toISOString().split('T')[0]; // Set repair_date to Monday
                    data.weekOfYear = getISOWeek(date); // Recalculate weekOfYear based on adjusted date
                }
            });
        
            if (timeFrame === 'week') {
                // Group by weekOfYear and select the latest value for each week
                let weeklyData: DataPoint[] = Object.values(processedDataCRL.reduce((acc, data) => {
                    const weekKey = data.weekOfYear; // Use weekOfYear as the key
                    if (!weekKey) return acc; // Skip if weekOfYear is undefined
                    if (!acc[weekKey] || new Date(data.repair_date) > new Date(acc[weekKey].repair_date)) {
                        acc[weekKey] = data; // Store or update the data for this week
                    }
                    return acc;
                }, {} as Record<number, DataPoint>));
        
                processedDataCRL = weeklyData; // Update with the processed weekly data
                
            } else if (timeFrame === 'month') {
                // Group by month and select the latest value for each month
                let monthlyData: DataPoint[] = Object.values(processedDataCRL.reduce((acc, data) => {
                    const date = new Date(data.repair_date);
                    const monthKey = `${date.getFullYear()}-${date.getMonth()}`; // Use year-month as the key
                    if (!acc[monthKey] || new Date(data.repair_date) > new Date(acc[monthKey].repair_date)) {
                        acc[monthKey] = data; // Store or update the data for this month
                    }
                    return acc;
                }, {} as Record<string, DataPoint>));
        
                processedDataCRL = monthlyData; // Update with the processed monthly data
            }
        
            // Update your state/hooks with this processed data
            setPreProcessedChartData(processedDataCRL);
            preProcessedChartDataRef.current = processedDataCRL;
        }





        // Process the data for the trend chart, calculating the LOC
        if (trendChart === "LOC") {
            let cumulativeInterventions = 0; // Initialize cumulative interventions counter
        
            // First, map through histogramData to calculate initial values and interventionsAmount
            processedDataLOC = histogramData.map((row: HistogramCandle): DataPoint => {
                const matchingInterventions = filteredByType.filter((intervention: Intervention) => intervention.repair_date === row.repair_date);
                const durationHours = matchingInterventions.reduce((acc, curr) => acc + (new Date(curr.repair_date).getTime() - new Date(curr.inspection_date).getTime()) / 3600000, 0) / matchingInterventions.length;
                const locPerLeak = row.leaks * steamPrice * 24 * 365;
                const gainPerLeak = row.resolvedLeakage * steamPrice * 24 * 365;
        
                dynamicLOC = locPerLeak // Update the dynamic LOC
                dynamicGain = gainPerLeak; // Update the dynamic Gain

        
                // Return the structured DataPoint
                return {
                    inspection_date: row.repair_date ?? "01/01/1999",
                    steam_flow_tonne: -1,
                    repair_date: row.repair_date ?? "01/01/1999",
                    loc: dynamicLOC,
                    gain: dynamicGain,
                    dateNumeric: new Date(row.repair_date).getTime(),
                    formattedDate: formatDateToEuropean(row.repair_date),
                    deltaTime: durationHours / 24,
                    interventionsAmount: matchingInterventions.length,
                    weekOfYear: getISOWeek(new Date(row.repair_date)),
                };
            });
        
            // For daily mode, interventionsAmount needs to be cumulative
            if (timeFrame === 'day') {
                processedDataLOC.forEach(data => {
                    cumulativeInterventions += data.interventionsAmount ?? 0;
                    data.interventionsAmount = cumulativeInterventions; // Update to be cumulative
                });
            }
        
            // Weekly aggregation with adjustments for cumulative interventions
            if (timeFrame === 'week') {
                cumulativeInterventions = 0; // Reset for weekly calculation
                processedDataLOC = aggregateData(processedDataLOC, 'week', cumulativeInterventions);
            }
        
            // Monthly aggregation with adjustments for cumulative interventions
            if (timeFrame === 'month') {
                cumulativeInterventions = 0; // Reset for monthly calculation
                processedDataLOC = aggregateData(processedDataLOC, 'month', cumulativeInterventions);
            }
        
            setPreProcessedChartData(processedDataLOC);
            preProcessedChartDataRef.current = processedDataLOC;
        }
        
        function aggregateData(data: DataPoint[], mode: 'week' | 'month', cumulativeInterventions: number): DataPoint[] {
            const aggregatedData: DataPoint[] = [];
            const dataMap: { [key: string]: DataPoint & { interventionsAmount: number } } = {};
        
            data.forEach(item => {
                const date = new Date(item.repair_date);
                let key: string | undefined;
                let weekStart; // Declare weekStart outside of the if block
        
                if (mode === 'week') {
                    // Calculate the weekStart only once
                    weekStart = startOfWeek(date, { weekStartsOn: 1 });
                    key = `Week-${weekStart.getDate()}-${weekStart.getMonth() + 1}-${weekStart.getFullYear()}`;
                } else if (mode === 'month') {
                    const monthStart = startOfMonth(date);
                    key = `Month-${monthStart.getMonth() + 1}-${monthStart.getFullYear()}`;
                }
        
                // Now weekStart is defined for usage below
                if (key) {
                    if (!dataMap[key]) {
                        // Use weekStart here safely as it's defined in the upper scope
                        dataMap[key] = { 
                            ...item, 
                            interventionsAmount: 0, 
                            // repair_date: mode === 'week' ? (weekStart?.toISOString().split('T')[0] ?? '') : startOfMonth(date).toISOString().split('T')[0]
                        };
                    }
        
                    cumulativeInterventions += item.interventionsAmount ?? 0;
                    dataMap[key].interventionsAmount = cumulativeInterventions; // Cumulative count
                    dataMap[key].loc = item.loc; // Update with the latest loc
                    dataMap[key].gain = item.gain; // Update with the latest gain
                }
            });
        
            Object.values(dataMap).forEach(item => aggregatedData.push(item));
        
            return aggregatedData;
        }
        
        
        
        



        

        // Process the data for the trend chart, calculating the CO2
        if (trendChart === "CO2") {
            processedDataCO2 = histogramData.map((row: HistogramCandle): DataPoint => {
                const repairDate = new Date(row.date);
                const matchingInterventions = filteredByType.filter((intervention: Intervention) => intervention.repair_date === row.repair_date);
                const durationHours = matchingInterventions.reduce((acc, curr) => acc + (repairDate.getTime() - new Date(curr.inspection_date).getTime()) / (3600000), 0) / matchingInterventions.length;
        
                return {
                    inspection_date: row.repair_date ?? "01/01/1999",
                    steam_flow_tonne: -1,
                    repair_date: row.repair_date ?? "01/01/1999",
                    co2: row.leaksCO2,
                    resolvedLeakageCO2: row.resolvedLeakageCO2,
                    dateNumeric: repairDate.getTime(),
                    formattedDate: formatDateToEuropean(row.repair_date),
                    deltaTime: durationHours / 24,
                    interventionsAmount: matchingInterventions.length,
                    weekOfYear: getISOWeek(repairDate) // Assuming this function correctly calculates the ISO week number
                };
            });
        
            // Adjust each data point date to the Monday of its week for weekly aggregation
            processedDataCO2.forEach(data => {
                if (data.repair_date) {
                    const date = new Date(data.repair_date);
                    const dayOfWeek = date.getDay();
                    const difference = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust to the previous Monday
                    date.setDate(difference);
                    data.repair_date = date.toISOString().split('T')[0]; // Set repair_date to Monday
                    data.weekOfYear = getISOWeek(date); // Recalculate weekOfYear based on adjusted date
                }
            });
        
            if (timeFrame === 'week') {
                // Group by weekOfYear and select the latest value for each week
                let weeklyData: DataPoint[] = Object.values(processedDataCO2.reduce((acc, data) => {
                    const weekKey = data.weekOfYear; // Use weekOfYear as the key
                    if (!weekKey) return acc; // Skip if weekOfYear is undefined
                    if (!acc[weekKey] || new Date(data.repair_date) > new Date(acc[weekKey].repair_date)) {
                        acc[weekKey] = data; // Store or update the data for this week
                    }
                    return acc;
                }, {} as Record<number, DataPoint>));
        
                processedDataCO2 = weeklyData; // Update with the processed weekly data
            } else if (timeFrame === 'month') {
                // Group by month and select the latest value for each month
                let monthlyData: DataPoint[] = Object.values(processedDataCO2.reduce((acc, data) => {
                    const date = new Date(data.repair_date);
                    const monthKey = `${date.getFullYear()}-${date.getMonth()}`; // Use year-month as the key
                    if (!acc[monthKey] || new Date(data.repair_date) > new Date(acc[monthKey].repair_date)) {
                        acc[monthKey] = data; // Store or update the data for this month
                    }
                    return acc;
                }, {} as Record<string, DataPoint>));
        
                processedDataCO2 = monthlyData; // Update with the processed monthly data
            }
        
            // Update your state/hooks with this processed data
            setPreProcessedChartData(processedDataCO2);
            preProcessedChartDataRef.current = processedDataCO2;
        }




        
        // Process the data for the trend chart, calculating the average repair time in days from the first inspection date to the last repair date
        if (trendChart === "TM") {
            // Correctly type the function parameters and ensure that nulls are filtered out properly
            let processedDataAVG: DataPoint[] = filteredByType.map((row: Intervention): DataPoint | null => {
                if (!row.repair_date) {
                    return null;
                }

                const inspectionDate = new Date(row.inspection_date);
                const repairDate = new Date(row.repair_date);
                const deltaTime = (repairDate.getTime() - inspectionDate.getTime()) / (1000 * 3600 * 24);

                return {
                    ...row,
                    dateNumeric: repairDate.getTime(),
                    formattedDate: formatDateToEuropean(row.repair_date),
                    deltaTime: Math.max(0, deltaTime), // Ensure non-negative
                };
            }).filter((item): item is DataPoint => item !== null)
            .sort((a: DataPoint, b: DataPoint) => a.dateNumeric - b.dateNumeric); 
          

            if (timeFrame === 'week') {
                const yearStart = new Date(new Date().getFullYear(), 0, 1);
                const endOfYear = new Date(new Date().getFullYear() + 1, 0, 1);
                let currentWeekStart = startOfWeek(yearStart, { weekStartsOn: 1 });
                const weeklyData:any = [];
            
                // Assuming processedDataAVG has at least one item to use as a structure reference
                const exampleRow = processedDataAVG.length > 0 ? processedDataAVG[0] : null;
            
                if (!exampleRow) {
                    console.error("No example row found in processedDataAVG, unable to generate weekly data structure.");
                    return;
                }
            
                while (currentWeekStart < endOfYear) {
                    const weekEnd = endOfWeek(currentWeekStart, { weekStartsOn: 1 });
            
                    const interventionsThisWeek = processedDataAVG.filter(({ repair_date }) => {
                        const repairDate = new Date(repair_date);
                        return repairDate >= currentWeekStart && repairDate <= weekEnd;
                    });
            
                    const totalDeltaTime = interventionsThisWeek.reduce((sum, item) => sum + item.deltaTime, 0);
                    const averageDeltaTime = interventionsThisWeek.length > 0 ? totalDeltaTime / interventionsThisWeek.length : 0;
            
                    // Use exampleRow to maintain structure, override with current week's data
                    weeklyData.push({
                        ...exampleRow,
                        dateNumeric: currentWeekStart.getTime(),
                        formattedDate: formatDateToEuropean(currentWeekStart),
                        deltaTime: Number(averageDeltaTime.toFixed(2)),
                        interventionsAmount: interventionsThisWeek.length,
                        // Ensure all necessary fields are filled in or calculated appropriately
                    });
            
                    currentWeekStart = addDays(weekEnd, 1);
                }

                // Remove the first week data if it's not necessary
                const adjustedWeeklyData = weeklyData.slice(1);
            
                setPreProcessedChartData(adjustedWeeklyData);
                preProcessedChartDataRef.current = adjustedWeeklyData;

            } else if (timeFrame === 'month') {
                const startOfYear = new Date(new Date().getFullYear(), 0, 1);
                // Adjust endOfYear to be the last day of the current year
                const endOfYear = new Date(new Date().getFullYear(), 11, 31); // December 31st of the current year
                const months = eachMonthOfInterval({ start: startOfYear, end: endOfYear });
            
                const monthlyData = months.map((monthStart:any) => {
                    const monthEnd = endOfMonth(monthStart);
            
                    const interventionsThisMonth = processedDataAVG.filter(({ repair_date }) => {
                        const repairDate = new Date(repair_date);
                        return repairDate >= monthStart && repairDate <= monthEnd;
                    });
            
                    if (interventionsThisMonth.length > 0) {
                        const exampleRow = { ...interventionsThisMonth[0] };
                        const totalDeltaTime = interventionsThisMonth.reduce((sum, { deltaTime }) => sum + deltaTime, 0);
                        const averageDeltaTime = totalDeltaTime / interventionsThisMonth.length;
            
                        return {
                            ...exampleRow,
                            dateNumeric: monthStart.getTime(),
                            formattedDate: formatDateToEuropean(`${monthStart.getFullYear()}-${(monthStart.getMonth() + 1).toString().padStart(2, '0')}-01`),
                            deltaTime: Number(averageDeltaTime.toFixed(2)),
                            interventionsAmount: interventionsThisMonth.length,
                        };
                    }
            
                    // Use the base structure for consistency, adjusted as suggested
                    const baseStructure = processedDataAVG[0] ? { ...processedDataAVG[0] } : {};
            
                    return {
                        ...baseStructure,
                        dateNumeric: monthStart.getTime(),
                        formattedDate: formatDateToEuropean(`${monthStart.getFullYear()}-${(monthStart.getMonth() + 1).toString().padStart(2, '0')}-01`),
                        deltaTime: 0, // Placeholder for months without data
                        interventionsAmount: 0, // No interventions this month
                        inspection_date: `${monthStart.getFullYear()}-${(monthStart.getMonth() + 1).toString().padStart(2, '0')}-01`, // Use the start of the month
                        repair_date: `${monthStart.getFullYear()}-${(monthStart.getMonth() + 1).toString().padStart(2, '0')}-01`, // Use the start of the month
                    };
                });
            
                setPreProcessedChartData(monthlyData);
                preProcessedChartDataRef.current = monthlyData;
            }else{
                // Set daily data
                setPreProcessedChartData(processedDataAVG);
                preProcessedChartDataRef.current = processedDataAVG;
            }

        }


        // Process the data for the trend chart, calculating the CO2 losses for each repair
        if (trendChart === "CO2-E") {
            let cumulativeCO2Emissions = 0;
            let cumulativeSparedCO2 = 0;
        
            let processedData: DataPoint[] = histogramData.map((row): DataPoint | null => {
                if (!row.repair_date) return null;
        
                const inspectionDate = new Date(row.inspection_date);
                const repairDate = new Date(row.repair_date);
                const interventionTime = (repairDate.getTime() - inspectionDate.getTime()) / (1000 * 60 * 60);
        
                cumulativeCO2Emissions += row.leaksCO2 * interventionTime;
                cumulativeSparedCO2 += row.leaksCO2 * ((365 * 24) - interventionTime);
        
                return {
                    ...row,
                    steam_flow_tonne: -1,
                    dateNumeric: repairDate.getTime(),
                    formattedDate: formatDateToEuropean(repairDate.toISOString().split('T')[0]),
                    deltaTime: interventionTime,
                    co2: cumulativeCO2Emissions,
                    sparedCO2: cumulativeSparedCO2,
                };
            }).filter(row => row !== null) as DataPoint[];
        
            const aggregateDataByTimeFrame = (data: DataPoint[], frame: 'week' | 'month'): DataPoint[] => {
                const result: DataPoint[] = [];
                const aggregationMap: { [key: string]: DataPoint } = {};
            
                data.forEach(d => {
                    const date = new Date(d.repair_date);
                    let key;
                    if (frame === 'week') {
                        // Ensure the date is adjusted to the beginning of the week, considering the local timezone
                        const weekStart = startOfWeek(date, { weekStartsOn: 1 });
                        // Generate a consistent key format for weekly aggregation
                        key = `${weekStart.getFullYear()}-W${getISOWeek(weekStart)}`;
                        // Correctly adjust the repair_date to ensure it's a Monday
                        d.repair_date = format(weekStart, 'yyyy-MM-dd');
                    } else if (frame === 'month') {
                        // For monthly aggregation, simply use the year and month as the key
                        key = `${date.getFullYear()}-M${date.getMonth() + 1}`;
                        // Optionally, adjust the repair_date to the first of the month, if needed
                        d.repair_date = format(startOfMonth(date), 'yyyy-MM-dd');
                    }
                    
                    // Only assign if the key is defined to avoid TypeScript errors
                    if (key) {
                        aggregationMap[key] = d;
                    }
                });
            
                // Push the last value of each aggregated key into the result array.
                Object.values(aggregationMap).forEach(value => result.push(value));
            
                return result;
            };
        
            if (timeFrame === 'week' || timeFrame === 'month') {
                processedData = aggregateDataByTimeFrame(processedData, timeFrame);
            }
        
            setPreProcessedChartData(processedData);
            preProcessedChartDataRef.current = processedData;
        }
        
        
        // Helper function to safely parse dates from your specific format (e.g., "dd/mm/yyyy") to a Date object
        const parseDateSafe = (dateStr:string) => {
            // Assuming dateStr is in "dd/mm/yyyy" format
            const parts = dateStr.split('/');
            if (parts.length === 3) {
                const day = parseInt(parts[0], 10);
                const month = parseInt(parts[1], 10) - 1; // Month is 0-based in JavaScript Date
                const year = parseInt(parts[2], 10);
                return new Date(year, month, day);
            }
            return new Date(NaN); // Return an invalid Date object if the format does not match
        };

        if (timeFrame === 'week') {
            const adjustedData = preProcessedChartDataRef.current.map(data => {
                if (!data.formattedDate) {
                    console.error('Invalid date encountered:', data.formattedDate);
                    return { ...data, formattedDate: 'Invalid Date' }; // Handle invalid date as appropriate
                }
        
                // Assuming parseDateSafe and formatDateToEuropean are correctly implemented
                const parsedDate = parseDateSafe(data.formattedDate);
                if (isNaN(parsedDate.getTime())) {
                    console.error('Invalid NaN date encountered:', data.formattedDate);
                    return data; // Skipping adjustment for this item; adjust handling as needed
                }
        
                // Adjustment logic for the date to the previous Monday
                const dayOfWeek = parsedDate.getDay();
                const difference = dayOfWeek === 0 ? -6 : 2 - dayOfWeek; // Adjust to the previous Monday, considering Sunday as 0
                parsedDate.setDate(parsedDate.getDate() + difference);
        
                // Correctly updating formattedDate with the adjusted date
                data.formattedDate = formatDateToEuropean(parsedDate);
        
                return data;
            });
        
            // After adjusting the dates, proceed with updating your state or handling the adjusted data as needed
            setPreProcessedChartData(adjustedData);
            preProcessedChartDataRef.current = adjustedData;
        }

        // Additional helper function to format the date to the first of the month in European format
        const formatDateToFirstOfMonth = (date:any) => {
            const year = date.getFullYear();
            const month = date.getMonth() + 1; // JS months are 0-based
            return `01/${month < 10 ? `0${month}` : month}/${year}`;
        };

        if (timeFrame === 'month') {
            const adjustedDataForMonth = preProcessedChartDataRef.current.map(data => {
                if (!data.repair_date || isNaN(new Date(data.repair_date).getTime())) {
                    console.error('Invalid month date encountered:', data.repair_date);
                    return { ...data, formattedDate: 'Invalid Date' }; // Handle invalid date as appropriate
                }
        
                // Ensure the date is parsed correctly to avoid timezone issues
                const date = new Date(data.repair_date);
                // Explicitly set to the first of the month to ensure correct month representation
                // The adjustment ensures the month and day are in the correct format
                data.formattedDate = `01/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
        
                return data;
            });
        
            // Update your state/hooks with the adjusted data for the monthly time frame
            setPreProcessedChartData(adjustedDataForMonth);
            preProcessedChartDataRef.current = adjustedDataForMonth;
        }

        // Calculate the average repair time in days
        const avgRepairTime = totalInterventionTime / totalCountedInterventions;
        setAvgRepairTimeDays(avgRepairTime);        

        // Calculate the average repair profit
        const avgRepairProfit = dynamicLOC / totalCountedInterventions;
        setAvgRepairProfit(avgRepairProfit);

        // Calculate the average repair tonne
        const avgRepairTonne = filteredByType.reduce((acc, curr) => acc + curr.steam_flow_tonne, 0) / totalCountedInterventions;
        setAvgRepairTonne(avgRepairTonne);
    

    }, [histogramData, steamPrice, selectedInterventionType, trendChart, dateInterval, selectedTimeFilterType, timeFrame]);






    
    /** 
     * @section Regression Chart
     * @brief Process Data for Regression Chart
    */

    /* States/Refs */
    const [processedChartData, setProcessedChartData] = useState<DataPoint[]>([]);
    const processChartDataRef = useRef<DataPoint[]>([]);


    /* Process Data for Regression Chart */
    useEffect(() => {
        
        /// Ensure validData is properly typed or filtered to match expectations
        const validData = preProcessedChartDataRef.current.filter(item => item.inspection_date && item.steam_flow_tonne && item.repair_date);
        if (validData.length === 0) {
            return;
        }


        // Convert inspection_date to numeric format for regression modeling
        const dateToNumeric = (date: string): number => {
            const startDate = new Date(validData[0].inspection_date).getTime();
            const currentDate = new Date(date).getTime();
            return (currentDate - startDate) / (1000 * 60 * 60 * 24);
        };
    
        // Convert the validData to numeric format for regression modeling
        const numericDataLoc: Point[] = validData.map(item => [dateToNumeric(item.repair_date), item.loc ?? 0]);
        const numericDataGain: Point[] = validData.map(item => [dateToNumeric(item.repair_date), item.gain ?? 0]);
        const numericDataCO2: Point[] = validData.map(item => [dateToNumeric(item.repair_date), item.co2 ?? 0]);
        const numericDataResolvedLeakageCO2: Point[] = validData.map(item => [dateToNumeric(item.repair_date), item.resolvedLeakageCO2 ?? 0]);
        const numericDataRegressionLoss: Point[] = validData.map(item => [dateToNumeric(item.repair_date), item.regressionLoss ?? 0]);
    
        // Perform cubic regression for LOC
        const regressionResultLoc = regression.polynomial(numericDataLoc, { order: 3, precision: 10 });
        const regressionResultGain = regression.polynomial(numericDataGain, { order: 3, precision: 10 });
        const regressionResultCO2 = regression.polynomial(numericDataCO2, { order: 3, precision: 10 });
        const regressionResultResolvedLeakageCO2 = regression.polynomial(numericDataResolvedLeakageCO2, { order: 3, precision: 10 });
        const regressionResultRegressionLoss = regression.polynomial(numericDataRegressionLoss, { order: 3, precision: 10 });
    
        // Forecast 5 future points for both LOC and Regression Loss
        let markedForecastData: Point[] = [];
        if (numericDataLoc.length > 0) {

            // Get the last dateNumeric value
            const lastDateNumeric = numericDataLoc[numericDataLoc.length - 1][0];

            // Forecast 5 future points for both LOC and Regression Loss
            const forecastData: any[] = Array.from({ length: 5 }).map((_, index) => {

                // Calculate the future dateNumeric, LOC, and Regression Loss
                const futureDateNumeric = lastDateNumeric + (index + 1); // Assuming each unit in dateNumeric represents a day
                const futureLoc = regressionResultLoc.predict(futureDateNumeric)[1];
                const futureGain = regressionResultGain.predict(futureDateNumeric)[1];
                const futureCO2 = regressionResultCO2.predict(futureDateNumeric)[1];
                const futureResolvedLeakageCO2 = regressionResultResolvedLeakageCO2.predict(futureDateNumeric)[1];
                const futureRegressionLoss = regressionResultRegressionLoss.predict(futureDateNumeric)[1];

                return {
                    dateNumeric: futureDateNumeric,
                    loc: futureLoc,
                    gain: futureGain,
                    co2: futureCO2,
                    resolvedLeakageCO2: futureResolvedLeakageCO2,
                    regressionLoss: futureRegressionLoss,
                    inspection_date: new Date(validData[0].inspection_date).getTime() + (futureDateNumeric * (1000 * 60 * 60 * 24))
                };
            });
    
            // Mark the forecast data as forecast: true
          markedForecastData = forecastData.map(d => ({ ...d, forecast: true }))
           

        }

        // Update the state with the processed data
        let payload:any = {}
        if (trendChart === "LOC" || trendChart === "CO2") { payload = [...validData, ...markedForecastData] } else { payload = validData };



        setProcessedChartData(payload);
        processChartDataRef.current = payload;

    }, [histogramData, steamPrice, preProcessedChartData, dateInterval, trendChart, selectedTimeFilterType, steamPrice]);
    





    /** UTILITIES */

    const handleOpenModalWithData = (data:any) => {
        setSelectedData(data); 
        setIsModalOpen(true);
        selectedDataRef.current = data;
    };

    const handleRowSelect = (row: any) => {
        setSelectedRows((prevSelectedRows) => {
            const index = prevSelectedRows.findIndex((selectedRow) => selectedRow.id === row.id);
    
            if (index === -1) {
                // If the row isn't already selected, add it
                selectedRowsRef.current = [...prevSelectedRows, row];
                return [...prevSelectedRows, row];
            } else {
                // If the row is already selected, remove it
                selectedRowsRef.current = prevSelectedRows.filter((_, i) => i !== index);
                return prevSelectedRows.filter((_, i) => i !== index);
            }
        });
    };
        




    /**
     * @section Export CSV
     * @brief Export the selected rows to a CSV file
    */

    const downloadCSV = useCallback(async () => {

        const onSuccess = (response:AxiosResponse) => {
            const blob = new Blob([response.data], { type: 'text/csv' });
            saveAs(blob, 'reports.csv');
        };
        
        const onError = (error:AxiosError) => {
            console.error('Error downloading the CSV:', error);
            if (error.response) {
                console.error(`HTTP error ${error.response.status}: ${error.response.statusText}`);
            }
        }

        Axios(session, 'get', `${config.api.internal}/steamleaks/exportData`, onSuccess, onError);

      }, []);



    /**
     * @section Download PDF
     * @brief Download the PDF of the selected rows
    */
    
    /* States/Ref */
    const [selectedRows, setSelectedRows] = useState<Intervention[]>([]);   
    const [selectedDeletedRows, setSelectedDeletedRows] = useState<Intervention[]>([]);
    const selectedRowsRef = useRef<Intervention[]>([]);
    const selectedDeletedRowsRef = useRef<Intervention[]>([]);                                          
    const [filteredRows, setFilteredRows] = useState<Intervention[]>([]);  
    const [filteredDeletedRows, setFilteredDeletedRows] = useState<Intervention[]>([]);    
    const filteredRowsRef = useRef<Intervention[]>([]);
    const filteredDeletedRowsRef = useRef<Intervention[]>([]);                                          
    const [isDownloading, setIsDownloading] = useState<boolean>(false);     // Flag to indicate if the PDF is being downloaded


    const downloadPDF = useCallback(async () => {

        const container = document.querySelector('#offscreen-report-container');
        if (!container) return; /// Early return if no reports are found

        let reports = container.querySelectorAll('.report-section');
        const a4 = document.querySelector('.report-section') as HTMLElement;
        
        const reportsArray = Array.from(reports).filter(report => {
            // Check if the report section has an ID by inspecting its 'id' property.
            // Only keep the report section if it has a non-empty 'id'.
            return report.id !== "";
        });

        setIsDownloading(true);

        // jsPDF configuration
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'a4',
        });

        // For a single report, download as JPEG
        if (a4 && reportsArray.length === 0) {

            const canvas = await html2canvas(a4, {
                useCORS: true,
                allowTaint: true,
                scale: 2, 
            });

            const imgData = canvas.toDataURL('image/jpeg');
            pdf.addImage(imgData, 'JPEG', 0, 0, 210, 297); 
        }
        
        // For multiple reports, add to PDF
        for (let i = 0; i < reportsArray.length; i++) {

            const canvas = await html2canvas(reportsArray[i] as HTMLElement, {
                scale: 2,
                useCORS: true,
                logging: true,
            });

            const imgData = canvas.toDataURL('image/jpeg');
            if (i > 0) pdf.addPage();
            pdf.addImage(imgData, 'JPEG', 0, 0, 210, 297); 
            
        }
        
        // Download PDF only if there are multiple reports
        pdf.save(`Reports_${new Date().toISOString()}.pdf`);
        setSelectedRows([]);    
        selectedRowsRef.current = [];

        // Timer to unset isDownloading after n_records * 1 second
        setTimeout(() => {
            setIsDownloading(false);
        }, 1000 * reportsArray.length);
        

    }, []);

    


    // Debug selectedRows
    useEffect(() => {
        console.log(selectedDeletedRows);
    }, [selectedDeletedRows]);


    // Patch TimeFrame selector 
    useEffect(() => {
        setTimeFrame("day")
    }, [trendChart]);


    /** 
     * @section Edit Record 
    */
    function extractFormData(formId: string): { [key: string]: string } {
        const form = document.getElementById(formId) as HTMLFormElement | null;
        if (!form) {
          console.error(`Form with ID ${formId} not found.`);
          return {};
        }
      
        const formData: { [key: string]: string } = {};
        const elements = form.querySelectorAll('[data-field-name]');
      
        elements.forEach((element) => {
          const fieldName = element.getAttribute('data-field-name');
          if (fieldName) {
            // Target the input directly if it's a hidden input related to a CustomSelect
            const inputElement = element.tagName === 'INPUT' ? element : element.querySelector('input, select');
            if (inputElement) {
              formData[fieldName] = (inputElement as HTMLInputElement | HTMLSelectElement).value;
            }
          }
        });
      
        return formData;
      }
      
    
    

    //   const sendFormData = async () => {
    //     try {
    //         const formData = extractFormData('global-form'); // Ensure this correctly gathers your form data
    //         const dataToSend = { 
    //             username: session?.data?.userData?.email, 
    //             ...formData 
    //         };
    
    //         const response = await axios.post(`${config.api.internal}/SteamLeaks/updateWebReport`, dataToSend, {
    //             headers: {
    //               'Content-Type': 'application/json'
    //             }
    //         });
    
    //         console.log("Success:", response.data);
    //         alert(`Success: ${response.data.message}`);
    //     } catch (error) {
    //         console.error("Error:", error);
    //         alert(`Error: ${error.response ? error.response.data.message : 'Failed to send data'}`);
    //     }
    // };

    const sendFormData = () => {
        const formData = extractFormData('global-form'); // Ensure this correctly gathers your form data
        const dataToSend = { 
            username: session?.data?.userData?.email, 
            ...formData 
        };
        axios.post(`${config.api.internal}/SteamLeaks/updateWebReport`, dataToSend, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
        .then(response => {
          console.log("Success:", response.data);
          alert(`Success: ${response.data.message}`);
        })
        .catch(error => {
          console.error("Error:", error);
          alert(`Error: ${error.response ? error.response.data.message : 'Failed to send data'}`);
        });
    };

    /* Memos for deleted and undeleted rows */
    const getUndeletedRows = useMemo(() => {
        return rows.filter(row => !row.is_deleted);
    }, [rows]);

    const getDeletedRows = useMemo(() => {
        return rows.filter(row => row.is_deleted);
    }, [rows]);
    




    return (
        <Fade in={true}>
            <div id="home">

                <main>

                    <Card
                        title={""}
                        actions={
                            <div className='dashboardDataActions'>
                                {/* Intervention Type Label and Select */}
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ marginRight: '10px' }}>Tipo intervento:</span>
                                    <CustomSelect
                                        style={{ width: 200 }}
                                        preselected={selectedInterventionType}
                                        options={[
                                            { label: 'GLOBAL', value: -1 },
                                            { label: 'INSULATION REPORT', value: 0 },
                                            { label: 'STEAM LEAK', value: 1 },
                                            { label: 'STEAM TRAP', value: 2 },
                                        ]}
                                        onChange={(value: number) => {
                                            setSelectedInterventionType(value);
                                        }}
                                    />
                                </div>

                                
                                {/* Refresh Button */}
                                <CustomButton
                                    nospam={true}
                                    onClick={fetchData}
                                    style={{ marginRight: '20px' }}
                                >
                                    <RefreshRoundedIcon />
                                </CustomButton>

                                {/* Separator */}
                                <div style={{ height: '24px', borderLeft: '1px solid #ccc', margin: '0 12px' }}></div>

                                {/* Date Filter Label and DateRange Picker */}
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ marginRight: '10px' }}>Filtro per Data:</span>
                                    <DateRangePicker
                                        outerValue={pickerReset}
                                        onConfirm={(v: { start: Date | null; end: Date | null }) => {
                                            setDateInterval({
                                                startDate: v.start,
                                                endDate: v.end,
                                            });
                                        }}
                                    />
                                    <CustomSelect
                                        style={{ width: 200 }}
                                        preselected={selectedTimeFilterType}
                                        options={[
                                            { label: 'DATA ISPEZIONE', value: 0 },
                                            { label: 'DATA RIPARAZIONE', value: 1 },
                                            // { label: 'POSTDATE', value: 2 },
                                        ]}
                                        onChange={(value: number) => {
                                            setSelectedTimeFilterType(value);
                                        }}
                                    />
                                </div>

                                {/* Separator */}
                                <div style={{ height: '24px', borderLeft: '1px solid #ccc', margin: '0 12px' }}></div>

                                {/* Steam Price Input */}
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ marginRight: '10px' }}>Prezzo Vapore (€/t):</span>
                                    <input
                                        type="number"
                                        style={{ width: '50px' }} 
                                        value={steamPrice}
                                        onChange={(e) => {
                                            setSteamPrice(Number(e.target.value));
                                        }}                                        
                                    />
                                </div>

                                {/* Separator */}
                                <div style={{ height: '24px', borderLeft: '1px solid #ccc', margin: '0 12px' }}></div>

                                {/* Recover Deleted Rows Button */}
                                {rows.some((row) => row.is_deleted) && session?.data?.userData?.role === 'admin' &&
                                        <CustomButton
                                            nospam={true}
                                            onClick={() => {
                                                setIsRecoveryModalOpen((state) => !state);
                                            }}
                                            style={{ marginRight: '20px' }}
                                            variant='dark'
                                        >
                                            <SettingsBackupRestoreRoundedIcon />
                                            Recupera Record
                                        </CustomButton>
                                    }


                            </div>
                        }
                    >

                    </Card>
                         
                        <div className={showTable !== "full" ? 'charts' : ''}>


                            {/** LEFT PANEL */}
                            { showTable !== "full" &&
                                <Card
                                    title={currentChart === 'pie' ? 'Distribuzione priorità' : currentChart === 'bar' ? 'Andamento Rientro Perdite e Interventi' : 'Stato interventi'}
                                    actions={
                                        <CustomButton
                                            onClick={toggleChart}
                                        >
                                            {
                                                currentChart === 'pie' ? <PieChartIcon /> : currentChart === 'bar' ? <DonutLargeRoundedIcon /> : <BarChartRoundedIcon />
                                            }
                                        </CustomButton>
                                    }
                                >
                                    {
                                        stats
                                        ?
                                        <>
                                            {
                                            currentChart === 'pie'
                                            ?
                                            <div style={{ display: 'flex', width: '100%', height: '100%' }}>
                                                <div style={{ width: '100%', height: '100%' }}>
                                                    <ResponsiveContainer width="100%" height="100%">
                                                        <CakeChart data={stats} radius={showTable === "hidden" ? 200 : 75} colors={['var(--danger)','var(--sandy-brown)','var(--warning)']} onPrioritySelect={(priority:number | null) => {setPriorityFilter(priority);}}/>
                                                    </ResponsiveContainer>
                                                </div>
                                            </div>    
                                            :
                                            currentChart === 'bar' 
                                            ?
                                            <ResponsiveContainer width="100%" height="100%">
                                                <ComposedChart data={histogramData}>
                                                    <XAxis dataKey="date" />
                                                    <YAxis label={{ value: 't/h', angle: -90, position: 'insideBottom' }} />
                                                    <YAxis yAxisId="right" orientation="right" label={{ value: 'Cumulativo Interventi ', angle: -90, position: 'insideCenter' }} domain={[0, 20]} />
                                                    <Tooltip content={<CustomTooltip />} />  
                                                    <Legend />
                                                    <CartesianGrid stroke="#f5f5f5" />
                                                    <Bar dataKey="leaks" barSize={20} fill="red" name="Leakage" />
                                                    <Bar dataKey="resolvedLeakage" barSize={20} fill="green" name="Resolved Leakage" />
                                                    <Bar dataKey="interventions" barSize={20} fill="#3495eb" name="Interventions" yAxisId="right" />
                                                    {/* <Line type="monotone" dataKey="avgLeakage" stroke="#ff7300" name="Relative Average Leakage Trend" /> */}
                                                    {/* <Line type="monotone" dataKey="resolvedLeakage" stroke="#3495eb" name="Resolved Leakage" /> */}
                                                    {/* Use ReferenceLine for a static average line or the Line for dynamic */}
                                                    {/* <ReferenceLine y={averageLeakage} label="Average Leakage" stroke="red" strokeDasharray="3 3" /> */}
                                                    {/* Add a custom label for daily average leakage resolved */}
                                                    <Customized component={() => (
                                                    <text x="80%" y="10" fill="#666" fontSize="14" textAnchor="end">
                                                            Rientro medio per Intervento:  
                                                            <tspan fill="green"> {resolvedLeakageByIntervention.toFixed(2)} </tspan>
                                                            tonnellate/ora
                                                        </text>
                                                    )} />
                                                </ComposedChart>
                                            </ResponsiveContainer>
                                            :
                                            <div style={{ width: '100%', height: '100%', display: 'flex', alignItems:'center', alignContent:'center', flexDirection:'column', gap:'10px' }}>
                                                
                                                {/** Display total interventions */}
                                                <div style={{ display: showTable === "hidden" ? "" : "none" }}>
                                                    <h1 style={{fontSize:'16px'}}>Totale Interventi: {interventionStatusData.reduce((total, entry) => total + entry.value, 0)}</h1>
                                                </div>

                                                <ResponsiveContainer width="100%" height="90%">
                                                    <PieChart>
                                                        <Pie
                                                            isAnimationActive={true}
                                                            dataKey="value"
                                                            data={interventionStatusData}
                                                            cx="50%"
                                                            cy="50%"
                                                            outerRadius={showTable === "hidden" ? 160 : 80}
                                                            fill="#8884d8"
                                                            label={(entry) => entry.name}
                                                            onClick={(data) => {
                                                                const status = data.name;
                                                                // console.log(status)
                                                                setStatusFilter(status === 'Aperti' ? 'opened' : 'closed');
                                                            }}
                                                        >
                                                            {interventionStatusData.map((entry, index) => (
                                                                <Cell key={`cell-${index}`} fill={entry.name === "Aperti" ? 'red' : 'green'} />
                                                            ))}
                                                        </Pie>
                                                        <Tooltip
                                                            formatter={(value, name, props) => {
                                                                let formattedValue: string = String(Math.round((value as number) * 1000) / 1000);
                                                                let unit = '';

                                                                // Define units based on the data key
                                                                switch (name) {
                                                                case 'Aperti':
                                                                    unit = ' interventi';
                                                                    break;
                                                                case 'Chiusi':
                                                                    unit = ' interventi'; 
                                                                    break;
                                                                default:
                                                                    break;
                                                                }

                                                                // Format the value with the unit
                                                                formattedValue = `${String(formattedValue)}${unit}`;

                                                                // Return the formatted string
                                                                return [formattedValue, name];
                                                            }}
                                                        />  
                                                    </PieChart>
                                                </ResponsiveContainer>
                                            </div>
                                            }
                                        </>
                                        :
                                        <></>
                                    }
                                </Card>
                            }
                                



                            {/** RIGHT PANEL */}
                            {(selectedInterventionType === -1 || selectedInterventionType === 1 || selectedInterventionType === 2) && showTable !== "full" &&

                                <Card
                                    title={titleMap[trendChart]}
                                    actions={
                                        <div style={{display:'flex', justifyContent:'left', alignItems:'center', gap:'10px'}}>
                                            <CustomGroup>

                                                <CustomButton variant={timeFrame === "day" ? "primary" : "secondary"} width={30} onClick={() => {setTimeFrame("day");}}>D</CustomButton>
                                                <CustomButton variant={timeFrame === "week" ? "primary" : "secondary"} width={30} onClick={() => {setTimeFrame("week");}}>W</CustomButton>
                                                <CustomButton variant={timeFrame === "month" ? "primary" : "secondary"} width={30} onClick={() => {setTimeFrame("month");}}>M</CustomButton>
                                                <CustomButton variant={timeFrame === "year" ? "primary" : "secondary"} disabled={timeFrame === "year" ? false : true} width={30}>Y</CustomButton>
                                                {/* <CustomButton variant={timeFrame === "all" ? "primary" : "secondary"} disabled={timeFrame === "all" ? false : true} width={30}>A</CustomButton> */}
            
                                            </CustomGroup>
                                            <CustomSelect
                                                style={{ width: 100 }}
                                                preselected={trendChart}
                                                placeholder={"Seleziona"}
                                                options={[
                                                    { label: 'LOC', value: 'LOC' },
                                                    { label: 'RL', value: 'RL' },
                                                    { label: 'TM', value: 'TM' },
                                                    { label: 'CO2', value: 'CO2' },
                                                    { label: 'CO2-E', value: 'CO2-E'}                                                    
                                                ]}
                                                onChange={(value: string) => {
                                                    toggleTrendChart(value);
                                                }}
                                            />
                                        </div>
                                    }
                                >



                                    {trendChart === 'LOC' &&  processChartDataRef.current.length > 0 &&
                                        <ResponsiveContainer width="100%" height="99%">
                                            <ComposedChart data={processedChartData} margin={{ top: 10, right: 30, left: 20, bottom: 0 }}>

                                            <defs>
                                                <linearGradient id="locColor" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor="#499c25" stopOpacity={0.8}/>
                                                    <stop offset="95%" stopColor="#499c25" stopOpacity={0}/>
                                                </linearGradient>
                                            </defs>
                                            <defs>
                                                <linearGradient id="gainColor" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor="#2a93ad" stopOpacity={0.8}/>
                                                    <stop offset="95%" stopColor="#2a93ad" stopOpacity={0}/>
                                                </linearGradient>
                                            </defs>

                                            <XAxis dataKey="formattedDate" height={60} label={{ value: "Data Riparazione", position: 'insideBottom', offset: 20 }} />
                                            <YAxis label={{ value: '€/anno', angle: -90, position: 'insideBottom', offset: 30}} />
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <Tooltip content={<CustomTooltipContent />} />
                                            <Legend 
                                                verticalAlign="top" // Positions the legend at the top of the chart area
                                                align="right" // Aligns the legend to the right inside the chart
                                                wrapperStyle={{ 
                                                    paddingBottom: '10px', // Adjusts space below the legend
                                                }}
                                            /> 
                                            <Customized component={() => (
                                                    <>
                                                        {/* <ClockIcon width={25} x={645} y={-339} style={{ fill: 'var(--grey-700)' }}/> */}
                                                        <text x="10%" y="28" fill="#666" fontSize="14" textAnchor="start">
                                                            Tempo Medio di Intervento: 
                                                            <tspan fill="green"> {avgRepairTimeDays.toFixed(2)} </tspan>
                                                            giorni dall' Ispezione
                                                        </text>
                                                        <text x="10%" y="40" fill="#666" fontSize="14" textAnchor="start">
                                                            Rientro Economico Medio per Intervento: 
                                                            <tspan fill="green"> {avgRepairProfit.toFixed(2)} </tspan>
                                                            €
                                                        </text>
                                                    </>
                                            )} />

                                            <Area type="monotone" dataKey="loc" stroke="#499c25" fillOpacity={1} fill="url(#locColor)" name="Perdita Economica" />
                                            <Area type="monotone" dataKey="gain" stroke="#2a93ad" fillOpacity={1} fill="url(#gainColor)" name="Rientro Economico" />

                                            <Line type="monotone" dataKey="loc" stroke="#499c25" dot={<CustomDot data={processedChartData} symbol={"€"}/>}  isAnimationActive={false} tooltipType="none" legendType="none" />



                                            </ComposedChart>
                                        </ResponsiveContainer>
                                    }



                                    {trendChart === 'CO2' &&  processChartDataRef.current.length > 0 &&
                                        <ResponsiveContainer width="100%" height="99%">
                                            <ComposedChart data={processedChartData} margin={{ top: 10, right: 30, left: 20, bottom: 0 }}>

                                            <defs>
                                                <linearGradient id="co2Color" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor="#a2d667" stopOpacity={0.8}/>
                                                    <stop offset="95%" stopColor="#a2d667" stopOpacity={0}/>
                                                </linearGradient>
                                            </defs>
                                            <defs>
                                                <linearGradient id="co2GainColor" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor="#2a93ad" stopOpacity={0.8}/>
                                                    <stop offset="95%" stopColor="#2a93ad" stopOpacity={0}/>
                                                </linearGradient>
                                            </defs>

                                            <XAxis dataKey="formattedDate" height={60} label={{ value: "Data Riparazione", position: 'insideBottom', offset: 20 }} />
                                            <YAxis label={{ value: 't/h', angle: -90, position: 'insideBottom', offset: 30}} />
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <Legend 
                                                verticalAlign="top" // Positions the legend at the top of the chart area
                                                align="right" // Aligns the legend to the right inside the chart
                                                wrapperStyle={{ 
                                                    paddingBottom: '10px', // Adjusts space below the legend
                                                }}
                                            /> 
                                            <Customized component={() => (
                                                    <>
                                                        {/* <ClockIcon width={25} x={645} y={-339} style={{ fill: 'var(--grey-700)' }}/> */}
                                                        <text x="10%" y="28" fill="#666" fontSize="14" textAnchor="start">
                                                            Tempo Medio di Intervento: 
                                                            <tspan fill="green"> {avgRepairTimeDays.toFixed(2)} </tspan>
                                                            giorni dall' Ispezione
                                                        </text>
                                                        <text x="10%" y="40" fill="#666" fontSize="14" textAnchor="start">
                                                            Rientro Perdita Medio per Intervento: 
                                                            <tspan fill="green"> {avgRepairTonne.toFixed(2)} </tspan>
                                                            t/h
                                                        </text>
                                                    </>
                                            )} />
                                            <Tooltip 
                                                formatter={(value, name, props) => {
                                                    let formattedValue: string = String(Math.round((value as number) * 1000) / 1000);
                                                    formattedValue = new Intl.NumberFormat('it-IT', {
                                                        minimumFractionDigits: 2, 
                                                        maximumFractionDigits: 2,
                                                        }).format(value as number);
                                                    let unit = '';

                                                    // Define units based on the data key
                                                    switch (name) {
                                                        case 'Perdite CO2':
                                                            unit = ' t/h';
                                                            break;
                                                        case 'Rientro Perdite CO2':
                                                            unit = ' t/h';
                                                            break;
                                                        default:
                                                            break;
                                                        }

                                                    // Format the value with the unit
                                                    formattedValue = `${String(formattedValue)}${unit}`;

                                                    // Return the formatted string
                                                    return [formattedValue, name];
                                                }}
                                            /> 

                                            <Area type="monotone" dataKey="resolvedLeakageCO2" stroke="#2a93ad" fillOpacity={1} fill="url(#co2GainColor)" name="Rientro Perdite CO2" />
                                            <Area type="monotone" dataKey="co2" stroke="#a2d667" fillOpacity={1} fill="url(#co2Color)" name="Perdite CO2" />


                                            <Line type="monotone" dataKey="co2" stroke="#a2d667" dot={<CustomDot data={processedChartData} symbol={"t/h"}/>}  isAnimationActive={false} tooltipType="none" legendType="none" />



                                            </ComposedChart>
                                        </ResponsiveContainer>
                                    }



                                    {/** 
                                    {trendChart === 'EX-RL' && processChartDataRef.current.length> 0 &&
                                            <ResponsiveContainer width="100%" height="98%">
                                                <AreaChart data={processedChartData} margin={{ top: 10, right: 30, left:20, bottom: 0 }}>
                                                    <defs>
                                                        <linearGradient id="regressionLossColor" x1="0" y1="0" x2="0" y2="1">
                                                            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                                            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                                                        </linearGradient>
                                                    </defs>
                                                    <XAxis dataKey="formattedDate" 
                                                        height={60}
                                                        label={{ 
                                                            value: "Data Riparazione ¹", 
                                                            position: 'insideBottom', 
                                                            offset: 20 
                                                        }} 
                                                    />       
                                                              
                                                    <YAxis label={{ value: '€/intervento', angle: -90, position: 'insideBottom', offset:30 }}/>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <Customized component={() => (
                                                        <>
                                                            <text x="10%" y="35" fill="#666" fontSize="14" textAnchor="start">
                                                                <tspan fill="red"> ¹ </tspan>
                                                                La Data di Riparazione fa riferimento al tempo intercorso tra il Censimento e la Risoluzione della Perdita
                                                            </text>
                                                        </>
                                                    )} />
                                                    <Tooltip
                                                        formatter={(value, name, props) => {
                                                            let formattedValue: string = String(Math.round((value as number) * 1000) / 1000);
                                                            formattedValue = new Intl.NumberFormat('it-IT', {
                                                                minimumFractionDigits: 2, 
                                                                maximumFractionDigits: 2,
                                                                }).format(value as number);
                                                            let unit = '';

                                                            // Define units based on the data key
                                                            switch (name) {
                                                                case 'Regression Cost':
                                                                    unit = ' €';
                                                                    break;
                                                                default:
                                                                    break;
                                                                }

                                                            // Format the value with the unit
                                                            formattedValue = `${String(formattedValue)}${unit}`;

                                                            // Return the formatted string
                                                            return [formattedValue, name];
                                                        }}
                                                    /> 
                                                    <Area type="monotone" dataKey="regressionLoss" stroke="#82ca9d" fillOpacity={1} fill="url(#regressionLossColor)" isAnimationActive={true} name={"Regression Cost"}  activeDot={<CustomActiveDot callback={(payload:any) => {
                                                        const selectedRow = rows.find(row => row.id === payload.id);
                                                        if (selectedRow) {
                                                            handleOpenModalWithData(selectedRow);
                                                        } else {
                                                            console.error('Selected data not found for ID:', payload.id);
                                                        }
                                                    }} />}
                                                />
                                                </AreaChart>
                                        </ResponsiveContainer>
                                    }
                                    */}



                                    {trendChart === 'RL' && processChartDataRef.current.length> 0 &&
                                            <ResponsiveContainer width="100%" height="98%">
                                                <AreaChart data={processedChartData} margin={{ top: 10, right: 30, left:20, bottom: 0 }}>
                                                    <defs>
                                                        <linearGradient id="regressionLossColor" x1="0" y1="0" x2="0" y2="1">
                                                            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                                            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                                                        </linearGradient>
                                                    </defs>
                                                    <XAxis dataKey="formattedDate" 
                                                        height={60}
                                                        label={{ 
                                                            value: "Data Riparazione ¹", 
                                                            position: 'insideBottom', 
                                                            offset: 20 
                                                        }} 
                                                    />       
                                                              
                                                    <YAxis label={{ value: '€/intervento', angle: -90, position: 'insideBottom', offset:30 }}/>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <Customized component={() => (
                                                        <>
                                                            {/* <ClockIcon width={25} x={645} y={-339} style={{ fill: 'var(--grey-700)' }}/> */}
                                                            {/* <text x="10%" y="620" fill="#666" fontSize="14" textAnchor="start"> */}
                                                            <text x="10%" y="35" fill="#666" fontSize="14" textAnchor="start">
                                                                <tspan fill="red"> ¹ </tspan>
                                                                La Data di Riparazione fa riferimento al tempo cumulativo intercorso tra il Censimento e la Risoluzione delle Perdite
                                                            </text>
                                                        </>
                                                    )} />
                                                    <Tooltip
                                                        formatter={(value, name, props) => {
                                                            let formattedValue: string = String(Math.round((value as number) * 1000) / 1000);
                                                            formattedValue = new Intl.NumberFormat('it-IT', {
                                                                minimumFractionDigits: 2, 
                                                                maximumFractionDigits: 2,
                                                                }).format(value as number);
                                                            let unit = '';

                                                            // Define units based on the data key
                                                            switch (name) {
                                                                case 'Regression Cost':
                                                                    unit = ' €';
                                                                    break;
                                                                default:
                                                                    break;
                                                                }

                                                            // Format the value with the unit
                                                            formattedValue = `${String(formattedValue)}${unit}`;

                                                            // Return the formatted string
                                                            return [formattedValue, name];
                                                        }}
                                                    /> 
                                                    <Area type="monotone" dataKey="crl" stroke="#82ca9d" fillOpacity={1} fill="url(#regressionLossColor)" isAnimationActive={true} name={"Regression Cost"}  activeDot={<CustomActiveDot />}
                                                />
                                                </AreaChart>
                                        </ResponsiveContainer>
                                    }

                                    {/* 
                                    {trendChart === 'DY' &&  processChartDataRef.current.length > 0 &&
                                        <ResponsiveContainer width="100%" height="98%">
                                                <AreaChart data={processedChartData} margin={{ top: 10, right: 30, left:20, bottom: 0 }}>
                                                    <defs>
                                                        <linearGradient id="regressionLossColor" x1="0" y1="0" x2="0" y2="1">
                                                            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                                            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                                                        </linearGradient>
                                                    </defs>
                                                    <XAxis dataKey="formattedDate" 
                                                        height={60}
                                                        label={{ 
                                                            value: "Data Riparazione ¹", 
                                                            position: 'insideBottom', 
                                                            offset: 20 
                                                        }} 
                                                    />       
                                                            
                                                    <YAxis label={{ value: '€/intervento', angle: -90, position: 'insideBottom', offset:30 }}/>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <Customized component={() => (
                                                        <>
                                                            <text x="10%" y="35" fill="#666" fontSize="14" textAnchor="start">
                                                                <tspan fill="red"> ¹ </tspan>
                                                                La Data di Riparazione fa riferimento al tempo intercorso dal 31/12/2023 fino alla data di Risoluzione della Perdita
                                                            </text>
                                                        </>
                                                    )} />
                                                    <Tooltip
                                                        formatter={(value, name, props) => {
                                                            let formattedValue: string = String(Math.round((value as number) * 1000) / 1000);
                                                            formattedValue = new Intl.NumberFormat('it-IT', {
                                                                minimumFractionDigits: 2, 
                                                                maximumFractionDigits: 2,
                                                                }).format(value as number);
                                                            let unit = '';

                                                            // Define units based on the data key
                                                            switch (name) {
                                                                case 'Regression Cost Y':
                                                                    unit = ' €';
                                                                    break;
                                                                default:
                                                                    break;
                                                                }

                                                            // Format the value with the unit
                                                            formattedValue = `${String(formattedValue)}${unit}`;

                                                            // Return the formatted string
                                                            return [formattedValue, name];
                                                        }}
                                                    /> 
                                                    <Area type="monotone" dataKey="deltaY" stroke="#82ca9d" fillOpacity={1} fill="url(#regressionLossColor)" isAnimationActive={true} name={"Regression Cost Y"}  activeDot={<CustomActiveDot />}
                                                />
                                                </AreaChart>
                                        </ResponsiveContainer>
                                    } */}


                                    {trendChart === 'TM' &&  processChartDataRef.current.length > 0 &&
                                        <ResponsiveContainer width="100%" height="100%">
                                        <ComposedChart data={processedChartData}>
                                            <XAxis dataKey="formattedDate" />
                                            <YAxis label={{ value: 'Tempo Intervento', angle: -90, position: 'insideBottom', offset:60 }} />
                                            <Tooltip />  
                                            <Legend />
                                            <CartesianGrid stroke="#f5f5f5" />
                                            <Bar dataKey="deltaTime" barSize={20} fill="#3495eb" name="Time" />
                                            <text x="80%" y="10" fill="#666" fontSize="14" textAnchor="end">
                                                            Tempo medio per Intervento dalla Data Censimento:  
                                                            <tspan fill="green"> {avgRepairTimeDays.toFixed(2)} </tspan>
                                                            giorni
                                            </text>
                                                    
                                        </ComposedChart>
                                    </ResponsiveContainer>
                                    }


                                    
                                    {trendChart === 'CO2-E' &&  processChartDataRef.current.length > 0 &&
                                        <ResponsiveContainer width="100%" height="98%">
                                                <AreaChart data={processedChartData} margin={{ top: 10, right: 30, left:20, bottom: 0 }}>
                                                    <defs>
                                                        <linearGradient id="co2Color" x1="0" y1="0" x2="0" y2="1">
                                                            <stop offset="5%" stopColor="#499c25" stopOpacity={0.8}/>
                                                            <stop offset="95%" stopColor="#499c25" stopOpacity={0}/>
                                                        </linearGradient>
                                                        <linearGradient id="sparedCO2Color" x1="0" y1="0" x2="0" y2="1">
                                                            <stop offset="5%" stopColor="#2a93ad" stopOpacity={0.8}/>
                                                            <stop offset="95%" stopColor="#2a93ad" stopOpacity={0}/>
                                                        </linearGradient>
                                                    </defs>
                                                    <XAxis dataKey="formattedDate" 
                                                        height={60}
                                                        label={{ 
                                                            value: "Data Riparazione", 
                                                            position: 'insideBottom', 
                                                            offset: 20 
                                                        }} 
                                                    />       
                                                            
                                                    <YAxis label={{ value: 't emesse', angle: -90, position: 'insideBottom', offset:25 }}/>
                                                    <YAxis yAxisId="right" orientation="right" label={{ value: 't/anno risparmiate', angle: -90, position: 'insideCenter', offset: 0 }} domain={[0, 20]} />
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <Customized component={() => (
                                                        <>
                                                            {/* <ClockIcon width={25} x={645} y={-339} style={{ fill: 'var(--grey-700)' }}/> */}
                                                            {/* <text x="10%" y="620" fill="#666" fontSize="14" textAnchor="start"> */}
                                                            {/* <text x="10%" y="35" fill="#666" fontSize="14" textAnchor="start">
                                                                <tspan fill="red"> ¹ </tspan>
                                                                La Data di Riparazione fa riferimento al tempo intercorso dal 31/12/2023 fino alla data di Risoluzione della Perdita
                                                            </text> */}
                                                        </>
                                                    )} />
                                                    <Legend 
                                                        verticalAlign="top" // Positions the legend at the top of the chart area
                                                        align="right" // Aligns the legend to the right inside the chart
                                                        wrapperStyle={{ 
                                                            paddingBottom: '10px', // Adjusts space below the legend
                                                        }}
                                                    /> 
                                                    <Tooltip
                                                        formatter={(value, name, props) => {
                                                            let formattedValue: string = String(Math.round((value as number) * 1000) / 1000);
                                                            formattedValue = new Intl.NumberFormat('it-IT', {
                                                                minimumFractionDigits: 2, 
                                                                maximumFractionDigits: 2,
                                                                }).format(value as number);
                                                            let unit = '';

                                                            // Define units based on the data key
                                                            switch (name) {
                                                                case 'CO2 Emissioni Totali':
                                                                    unit = ' t';
                                                                    break;
                                                                case 'CO2 Emissioni Risparmiate Annuali':
                                                                    unit = ' t/anno';
                                                                    break;
                                                                default:
                                                                    break;
                                                                }

                                                            // Format the value with the unit
                                                            formattedValue = `${String(formattedValue)}${unit}`;

                                                            // Return the formatted string
                                                            return [formattedValue, name];
                                                        }}
                                                    /> 
                                                    <Area type="monotone" dataKey="sparedCO2" stroke="#2a93ad" fillOpacity={1} fill="url(#sparedCO2Color)" isAnimationActive={true} name={"CO2 Emissioni Risparmiate Annuali"} activeDot={<CustomActiveDot />} yAxisId="right"/>
                                                    <Area type="monotone" dataKey="co2" stroke="#499c25" fillOpacity={1} fill="url(#co2Color)" isAnimationActive={true} name={"CO2 Emissioni Totali"}  activeDot={<CustomActiveDot />} />


                                                    
                                                
                                                </AreaChart>
                                        </ResponsiveContainer>
                                    }

                                    
                                </Card>
                                
                            }

                            
                            {selectedInterventionType !== -1 && selectedInterventionType !== 1 && selectedInterventionType !== 2 && showTable !== "full" &&

                                <Card
                                ></Card>

                            }

                        </div>


                    {/* Export Modal */}
                    {isModalOpen && selectedData &&
                        <CustomModal
                            id="export_modal"
                            // class="gallery-modal"
                            isOpen={isModalOpen}
                            scrollableBody={true}
                            onClose={() => {
                                setIsModalOpen(false);
                                setSelectedData(null); 
                                selectedDataRef.current = null;
                            }}
                            closeOnOverlayClick={true}
                            title={<><CustomIconButton onClick={downloadPDF}><DownloadRoundedIcon /></CustomIconButton>{`Report for ${selectedData?.id}`}</>}
                            closeButton={true}
                        >
                            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                {
                                    selectedData.intervention_type === 0 &&
                                    <ReportInsulation reportData={selectedData} />
                                }
                                {
                                    selectedData.intervention_type === 1 &&
                                    <ReportSteamLeak reportData={selectedData} />
                                }
                                {
                                    selectedData.intervention_type === 2 &&
                                    <ReportSteamTrap reportData={selectedData} />
                                }
                            </div>
                            
                        </CustomModal>
                    }


                    {/* Recovery Modal */}
                    {isRecoveryModalOpen && session?.data?.userData?.role === 'admin' &&
                        <CustomModal
                            id="recovery_modal"
                            // class="gallery-modal"
                            isOpen={isRecoveryModalOpen}
                            // scrollableBody={true}
                            onClose={() => {
                                setIsRecoveryModalOpen(false);
                                setSelectedDeletedRows([]);
                                selectedDeletedRowsRef.current = [];
                            }}
                            closeOnOverlayClick={true}
                            title={<>{`Recupero Interventi Cancellati`}</>}
                            closeButton={true}
                        >
                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection: 'column'}}>

                                <CustomTable
                                // tableName={<b>test</b>}
                                columns={currentDeletedColumns}
                                rows={getDeletedRows}

                                multiSelect={true}
                                showMultiSelectColumn={true}
                                // selectedRows={selectedRows}
                                handleResetSelection={selectedDeletedRows.length === 0}
                                onFiltered={(rows:any) => {
                                    console.log(rows)
                                    setFilteredDeletedRows(rows);
                                    filteredDeletedRowsRef.current = rows;
                                }}

                                quickFilter={true}
                                selectiveQuickFilter={true}
                                onSelectionChange={(ids:string[]) => {
                                    const selected = filteredDeletedRowsRef.current.filter(row => ids.includes(row.id));
                                    setSelectedDeletedRows(selected);
                                    selectedDeletedRowsRef.current = selected;
                                    
                                }}
                                // onRowClick={(row:any) => {
                                //     if(selectedRows.length === 0){
                                //         handleOpenModalWithData(row);
                                //     }else{
                                //         handleRowSelect(row);
                                //     }

                                // }}
                                />

                                {selectedDeletedRows.length > 0 && session?.data?.userData?.role === 'admin' &&
                                        <CustomButton
                                            nospam={true}
                                            onClick={() => deleteRows('restore')}
                                            style={{ marginRight: '20px' }}
                                        >
                                            {/* <ClearIcon /> */}
                                            Ripristina Record
                                        </CustomButton>
                                }

                            </div>
                            
                        </CustomModal>
                    }
                    
                                       
                    {/* Editor Modal */}
                    {
                        editingReport
                        &&
                        <CustomModal
                            class="reportEditModal"
                            title={'Modifica Report GLOBALE'}
                            isOpen={editingReport}
                            onClose={() => {
                                setEditingReport(null);
                                editingReportRef.current = null;
                            }}
                            closeButton={true}
                            scrollableBody={true}
                            footer={<>
                                <CustomButton onClick={() => {setEditingReport(null); editingReportRef.current = null;}}>{"Cancel"}</CustomButton>
                                <CustomButton onClick={() => {sendFormData()}}>{lang.save}</CustomButton>
                            </>}
                        >
                            <form id="global-form" className='mb-2'>
                            <CustomInput name="id" label="ID Report" value={editingReport.id} disabled={true} />

                                <fieldset>

                                    <legend>Tempo Intervento</legend>

                                    <CustomInput name="inspection_date" disabled={true} label="Data Ispezione" value={editingReport.f_inspection_date?.replace(/\//g, '-')}/>
                                    <CustomInput name="f_repair_date" disabled={true}  label="Data Riparazione" value={editingReport.f_repair_date?.replace(/\//g, '-')}/>
                                    <CustomInput name="post_date" label="Post Date" value={editingReport.post_date} placeholder={editingReport.post_date}/>

                                </fieldset>

                                <fieldset>
                                        
                                    <legend> Anagrafica Intervento {InterventionType[editingReport.intervention_type].replace(/_/g, ' ').replace("REPORT","")}</legend>

                                    <CustomInput
                                        name="intervention_type"
                                        label="Tipo Intervento"
                                        value={InterventionType[editingReport.intervention_type]}
                                        disabled={true}
                                    />
                                    <CustomInput name="intervention_description" label="Descrizione Intervento" value={editingReport.intervention_description} />
                                    <CustomInput name="operator" label="Operatore" value={editingReport.operator}/>
                                    <CustomInput name="tag" label="Tag" value={editingReport.tag}/>
                                    <CustomSelect 
                                        name="unit"
                                        label="Unità" 
                                        placeholder={editingReport.unit} 
                                        value={editingReport.unit}
                                        options={[
                                            'CANDELA', 'DAU1', 'DAU2', 'EFU1', 'EFU2', 'FCCU', 'LPGS', 'MEK', 'T5', 'VPS1', 'VPS2', 'zolfo1', 'zolfo2'
                                        ].map(unit => ({
                                            label: unit,
                                            value: unit,
                                        }))}
                                    />
                                    
                                    <CustomSelect 
                                        name="business_team"
                                        label="Business Team" 
                                        placeholder={editingReport.business_team} 
                                        value={editingReport.business_team} 
                                        options={[
                                            'CPX-A', 'CPX-B', 'CPX-C', 'LUBE1', 'LUBE2'
                                        ].map(team => ({
                                            label: team,
                                            value: team,
                                        }))}
                                    />
                                    <CustomInput name="location" label="Location" value={editingReport.location}/>
                                    <CustomSelect 
                                        name="competence"
                                        label="Competenza" 
                                        placeholder={editingReport.competence ?? "N/A"} 
                                        value={editingReport.competence ?? "N/A"}
                                        options={[
                                            'Strumentazione (STR)', 'Machinery (MA)', 'Metal Trade (MT)', 'N/A'
                                        ].map(competence => ({
                                            label: competence,
                                            value: competence,
                                        }))}
                                    />
                                    <CustomSelect 
                                        name="service"
                                        label="Servizio" 
                                        placeholder={editingReport.service ?? "N/A"} 
                                        value={editingReport.service ?? "N/A"}
                                        options={[
                                            'Processo', 'Traccia', 'N/A'
                                        ].map(service => ({
                                            label: service,
                                            value: service,
                                        }))}
                                    />
                                    <CustomInput name="notification" label="Notification" value={editingReport.notification}/>
                                    <CustomInput name="closure_notification" label="Chiusura Notification" value={editingReport.closure_notification}/>

                                </fieldset>

                                <fieldset>
                                        
                                    <legend> Dettagli Intervento </legend>

                                    <CustomSelect 
                                        name="status"
                                        label="Status" 
                                        placeholder={editingReport.status === 0 ? "Chiuso" : "Aperto"}
                                        value={editingReport.status.toString()}
                                        options={[
                                            { label: "Chiuso", value: "0" },
                                            { label: "Aperto", value: "1" },
                                        ]}
                                    />                               
                                    <CustomSelect 
                                        name="priority"
                                        label="Priorità" 
                                        placeholder={editingReport.priority}
                                        value={editingReport.priority.toString()}
                                        options={[
                                            { label: "1", value: "1" },
                                            { label: "2", value: "2" },
                                            { label: "3", value: "3" },
                                        ]}
                                    /> 
                                    <CustomInput name="component_equipment" label="Equipaggiamento" disabled={editingReport.component_equipment ? false : true} value={editingReport.component_equipment ?? "N/A"}/>
                                    <CustomSelect 
                                        name="size"
                                        label="Misura" 
                                        placeholder={editingReport.size ?? "N/A"}
                                        value={editingReport.size ?? "N/A"}
                                        options={[
                                            { label: '1"', value: '1"' },
                                            { label: '1" 1/2', value: '1" 1/2' },
                                            { label: '1/2"', value: '1/2"' },
                                            { label: '1/2" a 8mm', value: '1/2" a 8mm' },
                                            { label: '1/2" x 10mm', value: '1/2" x 10mm' },
                                            { label: '1/2" x 8mm', value: '1/2" x 8mm' },
                                            { label: '10"', value: '10"' },
                                            { label: '2"', value: '2"' },
                                            { label: '3"', value: '3"' },
                                            { label: '3/4"', value: '3/4"' },
                                            { label: '3/4" a 8 mm', value: '3/4" a 8mm' },
                                            { label: '4"', value: '4"' },
                                            { label: '6mm', value: '6mm' },
                                            { label: '6"', value: '6"' },
                                            { label: '8mm', value: '8mm' },
                                            { label: '8mm x 1/2"', value: '8mm x 1/2"' },
                                            { label: '8"', value: '8"' },
                                            { label: '10mm', value: '10mm' },
                                            { label: '12mm', value: '12mm' },
                                            { label: '14mm', value: '14mm' },
                                            { label: '16mm', value: '16mm' },
                                            { label: '18mm', value: '18mm' },
                                            { label: '22mm', value: '22mm' },
                                            { label: '?', value: '?' },
                                            { label: 'N/A', value: 'N/A' },
                                        ]}
                                    /> 
                                    <CustomInput name="dn_discharger" label="DN Scaricatore" disabled={editingReport.dn_discharger ? false : true} value={editingReport.dn_discharger ?? "N/A"}/>

                                </fieldset>


                                <fieldset>
                                        
                                    <legend> Dettagli di Campo </legend>

                                    <CustomSelect 
                                        name="malfunctioning_type"
                                        label="Tipo Malfunzionamento" 
                                        placeholder={editingReport.malfunctioning_type ?? "N/A"}
                                        value={editingReport.malfunctioning_type ?? "N/A"}
                                        options={[
                                            { label: "Bloccato", value: "Bloccato" },
                                            { label: "In perdita", value: "In perdita" },
                                            { label: "N/A", value: "N/A" },
                                        ]}
                                    />                                
                                    <CustomSelect 
                                        name="discharger_type"
                                        label="Tipo Scaricatore" 
                                        placeholder={editingReport.discharger_type ?? "N/A"}
                                        value={editingReport.discharger_type ?? "N/A"}
                                        options={[
                                            { label: "galleggiante", value: "galleggiante" },
                                            { label: "secchiello", value: "secchiello" },
                                            { label: "secchiello rovesciato", value: "secchiello rovesciato" },
                                            { label: "termodinamico", value: "termodinamico" },
                                            { label: "N/A", value: "N/A" },
                                        ]}
                                    />

                                    <CustomSelect 
                                        name="plume_length"
                                        label="L. Pennacchio (mt)" 
                                        placeholder={editingReport.plume_length ?? ""}
                                        options={[
                                            { label: "< 0,5 mt", value: "< 0,5 mt" },
                                            { label: "0,5 - 1 mt", value: "0,5 - 1 mt" },
                                            { label: "> 1 mt", value: "> 1 mt" },
                                        ]}
                                    />

                                    <CustomInput name="plume_spec" label="Spec. Pennacchio (mt)" disabled={Number(editingReport.plume_spec) > 0 ? false : true} value={editingReport.plume_spec ?? "N/A"}/>
                                    <CustomSelect name="need_for_insulation" label="Necessario Scoibentare" disabled={editingReport.need_for_insulation ? false : true} placeholder={editingReport.need_for_insulation ?? "N/A"} 
                                            value={editingReport.need_for_insulation ?? -1}
                                            options={[
                                            { label: "Si", value: 1 },
                                            { label: "No", value: 0 },
                                            { label: "N/A", value: -1 },

                                        ]}
                                    />   

                                    <CustomSelect name="asbestos" label="Amianto" disabled={editingReport.asbestos ? false : true} placeholder={editingReport.asbestos ?? "N/A"}
                                            value={editingReport.asbestos ?? -1}
                                            options={[
                                            { label: "Si", value: 1 },
                                            { label: "No", value: 0 },
                                            { label: "N/A", value: -1 },
                                        ]}
                                    />  
                                    <CustomSelect name="metal_sheet" label="Lamierino" placeholder={editingReport.metal_sheet ?? "N/A"}    
                                            value={editingReport.metal_sheet ?? "N/A"}                             
                                            options={[
                                            { label: "Presente", value: "Presente" },
                                            { label: "Assente", value: "Assente" },
                                            { label: "Danneggiato", value: "Danneggiato" },
                                            { label: "N/A", value: "N/A" },
                                        ]}
                                    /> 
                                    <CustomInput name="metal_sheet_temperature" label="T. Lamierino (°C)" disabled={editingReport.metal_sheet_temperature ? false : true} value={editingReport.metal_sheet_temperature ?? "N/A"}/>
                                    <CustomInput name="pipe_temperature" label="T. Tubazione (°C)"  disabled={editingReport.pipe_temperature ? false : true} value={editingReport.pipe_temperature ?? "N/A"}/>
                                    <CustomSelect name="insulation_material" label="Mezzo Isolante" placeholder={editingReport.insulation_material ?? ""}
                                            value={editingReport.insulation_material ?? "N/A"}                             
                                            options={[
                                            { label: "Presente", value: "Presente" },
                                            { label: "Isolante Assente", value: "Presente" },
                                            { label: "Isolante Danneggiato", value: "Danneggiato" },
                                            { label: "N/A", value: "N/A" },

                                        ]}
                                    /> 
                                    <CustomInput name="scaffolding" label="Ponteggio (mt)" disabled={editingReport.scaffolding ? false : true} value={editingReport.scaffolding ?? "N/A"}/>
                                    <CustomSelect name="steam_discharge_to_closed_system" label="Scarico condensa a s.c." placeholder={editingReport.steam_discharge_to_closed_system ?? "N/A"}       
                                            value={editingReport.steam_discharge_to_closed_system ?? -1}                                               
                                            options={[
                                            { label: "Si", value: 1 },
                                            { label: "No", value: 0 },
                                            { label: "N/A", value: -1 },
                                        ]}
                                    /> 
                                    <CustomInput name="trait_length" label="L. tratto (mt)" disabled={editingReport.trait_length ? false : true}  placeholder={editingReport.trait_length ?? "N/A"}/>

                                </fieldset>

                                <fieldset>
                                        
                                    <legend> Valvola </legend>       

                                    <CustomInput name="interception_possibility" label="Possibilità Intercetto"  disabled={editingReport.interception_possibility ? false : true} value={editingReport.interception_possibility ?? "N/A"}/>
                                    <CustomSelect name="interception_valve_status" label="Stato Intercetto Valvola" placeholder={editingReport.interception_valve_status ?? "N/A"}
                                            value={editingReport.interception_valve_status ?? -1}                                                                     
                                            options={[
                                            { label: "Si", value: 1 },
                                            { label: "No", value: 0 },
                                            { label: "N/A", value: -1 },
                                        ]}
                                    /> 
                                    <CustomInput name="reason" label="Motivazione" disabled={editingReport.reason ? false : true} value={editingReport.reason ?? ""}/>

                                </fieldset>

                                <fieldset>
                                        
                                    <legend> Valori Perdita </legend>


                                    <CustomInput name="pressure" label="Pressione (psig)" disabled={editingReport.pressure ? false : true} value={editingReport.pressure ?? "N/A"}/>
                                    <CustomInput name="nominal_flow" label="Portata Nominale (t/h)" disabled={editingReport.nominal_flow ? false : true} value={editingReport.nominal_flow ?? "N/A"}/>
                                    <CustomInput name="steam_flow_kg" label="Portata Vapore (Kg/h)" disabled={editingReport.steam_flow_kg ? false : true} value={editingReport.steam_flow_kg ?? "N/A"}/>
                                    <CustomInput name="steam_flow_tonne" label="Portata Vapore (t/h)" disabled={editingReport.steam_flow_tonne ? false : true} value={editingReport.steam_flow_tonne ?? "N/A"}/>

                                </fieldset>


                                <button /*ref={submitRef}*/ style={{ display: 'none' }}></button>

                            </form>
                        </CustomModal>
                    }

                    
                    {/* Layout Change */}
                    {showTable === "hidden" &&
                        <CustomButton
                        onClick={() => {
                            setShowTable("half" as ShowTableType)
                        }}
                        >
                            Mostra Dettagli
                        
                        </CustomButton>
                    }

                    { showTable === "half" &&
                        <CustomGroup>
                            <CustomButton
                            onClick={() => {
                                setShowTable("hidden" as ShowTableType)
                            }}
                            classes="w-100"
                            >
                                <ArrowDropDownRoundedIcon />
                                Nascondi Dettagli
                            
                            </CustomButton>

                            <CustomButton
                            onClick={() => {
                                setShowTable("full" as ShowTableType)
                            }}
                            classes="w-100"
                            >
                                <ArrowDropUpRoundedIcon />
                                Espandi Dettagli
                            
                            </CustomButton>
                        </CustomGroup>
                    }

                    { showTable === "full" &&
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <CustomButton
                            onClick={() => {
                                setShowTable("half" as ShowTableType)
                            }}
                            classes="w-100"
                            >
                                Riduci Dettagli
                            
                            </CustomButton>
                        </div>
                    }

                    {
                        showTable !== "hidden"
                        &&
                        <Card
                            class={showTable === "half" ? "test" : "full"}
                            title={`ELENCO INTERVENTI`}
                            actions={
                                
                                <div style={{display:'flex', gap:'10px'}}>

                                    <CustomButton
                                        nospam={true}
                                        onClick={downloadCSV}
                                        style={{ marginRight: '20px' }}
                                    >
                                        <GetAppRoundedIcon />
                                        Esporta CSV
                                    </CustomButton>

                                    {selectedRows.length > 0 && session?.data?.userData?.role === 'admin' &&
                                        <CustomButton
                                            nospam={true}
                                            onClick={() => {
                                                deleteRows('delete')
                                                setSelectedRows([]);
                                                selectedRowsRef.current = [];
                                            }}
                                            style={{ marginRight: '20px' }}
                                            variant='danger'
                                        >
                                            <ClearIcon />
                                            Cancella Record
                                        </CustomButton>
                                    }
       

                                    {!isDownloading && selectedRows.length > 0 &&
                                        <CustomButton
                                        onClick={() => {
                                            downloadPDF();
                                        }}
                                        >
                                            <PictureAsPdfRoundedIcon />
                                            Esporta Selezione
                                        </CustomButton>
                                    }

                                </div>
                            }
                        >

                            <CustomTable
                            // tableName={<b>test</b>}
                            columns={currentColumns}
                            rows={getUndeletedRows}

                            multiSelect={true}
                            showMultiSelectColumn={true}
                            // selectedRows={selectedRows}
                            handleResetSelection={selectedRows.length === 0}
                            onFiltered={(rows:any) => {
                                console.log(rows)
                                setFilteredRows(rows);
                                filteredRowsRef.current = rows;
                            }}

                            quickFilter={true}
                            selectiveQuickFilter={true}
                            onSelectionChange={(ids:string[]) => {
                                const selected = filteredRowsRef.current.filter(row => ids.includes(row.id));
                                setSelectedRows(selected);
                                selectedRowsRef.current = selected;
                                
                            }}
                            // onRowClick={(row:any) => {
                            //     if(selectedRows.length === 0){
                            //         handleOpenModalWithData(row);
                            //     }else{
                            //         handleRowSelect(row);
                            //     }

                            // }}
                            />
                        </Card>
                    }

                    
    
                    {/* Offscreen container for report generation */}
                    <div id="offscreen-report-container" style={{ visibility:'visible' }}>
                        {selectedRows.map((reportData:any, index:number) => (
                            // Render each report type based on its intervention_type
                            // You may need to adjust this based on how your data and components are structured
                            <div className="report-section" id={`report-section-${reportData.id}`} key={index}>
                                {reportData.intervention_type === 0 && <ReportInsulation reportData={reportData} />}
                                {reportData.intervention_type === 1 && <ReportSteamLeak reportData={reportData} />}
                                {reportData.intervention_type === 2 && <ReportSteamTrap reportData={reportData} />}
                            </div>
                        ))}
                    </div>

                </main>

            </div>
        </Fade>
    );
};
export default React.memo(Dashboard);


// http://localhost:3001/