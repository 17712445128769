import React from "react";

const CustomActiveDot = (props:any) => {
    const { cx, cy, payload, callback } = props;
  
    return (
      <circle
        cx={cx}
        cy={cy}
        r={6}
        fill="#82ca9d"
        stroke="#fff"
        strokeWidth={2}
        onClick={() => callback(payload)}
      />
    );
  };

  export default CustomActiveDot;