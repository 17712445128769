import {PriorityStats,
    Intervention,
    InterventionType, 
    InterventionStatus, 
    ColorPriority, 
    ColorValue, 
    colorPalettePriority,
    defaultColor} from '../types';
    
    
/* Utility function to calculate the stats of the data */
export const calcStats = (
    selectedInterventionType: number,
    rowsRef: React.MutableRefObject<Intervention[]>
) : PriorityStats[] => {

    // Filter the data based on the selected intervention type
    let filteredByType = selectedInterventionType === -1
        ? rowsRef.current
        : rowsRef.current.filter(row => row.intervention_type === selectedInterventionType);        

    // Use a Record type to correctly type the tmp object
    let bucket: Record<string, number> = {};

    // Count the number of interventions for each priority
    filteredByType.forEach((row) => {
        // Convert null or undefined to 'Pnull'
        const priorityKey = row.priority === null || row.priority === undefined ? 'null' : row.priority.toString();
        bucket[priorityKey] = (bucket[priorityKey] || 0) + 1;
    });

    // Convert the tmp object to an array of objects
    return Object.keys(bucket).map((priorityStr): PriorityStats => {
        // Use parseInt to convert the priority back to a number
        const priority = priorityStr === 'null' ? null : parseInt(priorityStr, 10);
        const color = priority === null ? defaultColor : colorPalettePriority[priority as ColorPriority] || defaultColor;
        
        return {
            name: priorityStr,
            value: bucket[priorityStr],
            color: color
        };
    });

}
