// ---  Stats
/**
 * Interface representing the structure of priority chart pies.
 */
export interface PriorityStats {
    name: string;
    value: number;
    color: string;
}


// --- Interventions
export interface Intervention {
    id: string;
    priority: number;
    tag: string;
    unit: string;
    business_team: string;
    location: string;
    component_equipment: string;
    size: string;
    operator: string;
    inspection_date: string;
    pressure: string;
    plume_length: string;
    plume_spec: string;
    scaffolding: string; 
    interception_possibility: string;
    interception_valve_status: number;
    competence: string;
    need_for_insulation: number; 
    asbestos: number;
    notification: number;
    img_url: string;
    status: number;
    closure_notification: number;
    after_img_url: string;
    repair_date: string;
    intervention_type: number;
    intervention_description: string;
    post_date: string;
    reason: string;
    steam_flow_kg: number;
    steam_flow_tonne: number;
    trait_length: string; 
    metal_sheet: string;
    metal_sheet_temperature: string;
    insulation_material: string;
    pipe_temperature: string;
    nominal_flow: string;
    dn_discharger: string;
    malfunctioning_type: string;
    discharger_type: string;
    service: string;
    steam_discharge_to_closed_system: string;
    f_inspection_date?: string;
    f_repair_date?: string;
    is_deleted?: boolean;
    // --
    rowSelect?: string;
    rowShow?: string;
}

export enum InterventionType {
    GLOBAL = -1,
    INSULATION_REPORT = 0,
    STEAM_LEAK_REPORT = 1,
    STEAM_TRAP_REPORT = 2,
}

export type InterventionStatus = {
    name: string;
    value: number;
}

/** --- Priority Colors */

export enum ColorPriority {
    Black = 0,
    Yellow = 3,
    Orange = 2,
    Red = 1,
}

export type ColorValue = 'black' | '#ebd834' | 'orange' | 'red';

export const colorPalettePriority: Record<ColorPriority, ColorValue> = {
    [ColorPriority.Black]: 'black',
    [ColorPriority.Yellow]: '#ebd834',
    [ColorPriority.Orange]: 'orange',
    [ColorPriority.Red]: 'red',
};

export const defaultColor = 'black';