import './Title.scss';  // import './css/Title.scss';


import React from 'react';

// import { Box } from '@mui/material';


const Title: React.FC<any> = (props: any) => {
    
    let className = "title";
    if(props.class){
        className = className + ' ' + props.class;
    }


    switch(props.size) {
        case 'sm': {
            className += ' sm'
            break;
        }
        case 'lg': {
            className += ' lg'
            break;
        }
        case 'xl': {
            className += ' xl'
            break;
        }
        case 'std':
        default: {
            className += ' std'
            break;
        }
    }

    // console.log(props)
    let childrens = null;
    if( props.children && Array.isArray(props.children) ){
        childrens = props.children.map((component: any, i: number) => component)
    }else{
        childrens = props.children
    }
    // console.log(childrens)
    return (
        <h5 className={className}>
            {
                childrens
            }
        </h5>
    );
}

export default React.memo(Title);