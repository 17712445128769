import mimeTypes from '../config/mimeTypes';


export const copyToClipboard = (value:string) => {
    const textField = document.createElement('textarea');
    textField.innerText = value;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  };


  //ordinamento
export const ASC = (attrName:string|number) => {
    return function(a:any, b:any) {

        if(a[attrName] === undefined || b[attrName] === undefined){
            return 0;
        }

        if( (a === true && b === false) ){
            return 1;
        }else if (  (a === true && b === true) || (a === false && b === false) ) {
            return 0;
        }else if( a === false && b === true ){
            return -1
        }

        let temp_a, temp_b;

        if(isNaN(a[attrName])){
            temp_a = a[attrName].toLowerCase().replaceAll(' ', '');
        }else{
            temp_a = a[attrName];
        }
        if(isNaN(b[attrName])){
            temp_b = b[attrName].toLowerCase().replaceAll(' ', '');
        }else{
            temp_b = b[attrName];
        }

        if ((temp_a > temp_b) || temp_b == null || temp_b === '') {
            //console.log(a[prop].toLowerCase().replaceAll(' ', '') +" | > | "+ b[prop].toLowerCase().replaceAll(' ', ''))

            return 1;
        } else if ((temp_a < temp_b) || temp_a == null || temp_a === '') {
            //console.log(a[prop].toLowerCase().replaceAll(' ', '') +" | < | "+ b[prop].toLowerCase().replaceAll(' ', ''))

            return -1;
        }
        return 0;
    }
}

export const DESC = (attrName:string|number) => {
    return function(a:any, b:any) {
        
        if(a[attrName] === undefined || b[attrName] === undefined){
            return 0;
        }

        if( (a === true && b === false) ){
            return -1;
        }else if (  (a === true && b === true) || (a === false && b === false) ) {
            return 0;
        }else if( a === false && b === true ){
            return 1
        }

        let temp_a, temp_b;

        if(isNaN(a[attrName])){
            temp_a = a[attrName].toLowerCase().replaceAll(' ', '');
        }else{
            temp_a = a[attrName];
        }
        if(isNaN(b[attrName])){
            temp_b = b[attrName].toLowerCase().replaceAll(' ', '');
        }else{
            temp_b = b[attrName];
        }

        if ((temp_a > temp_b) || temp_b == null || temp_b === '') {
            //console.log(a[prop].toLowerCase().replaceAll(' ', '') +" | > | "+ b[prop].toLowerCase().replaceAll(' ', ''))

            return -1;
        } else if ((temp_a < temp_b) || temp_a == null || temp_a === '') {
            //console.log(a[prop].toLowerCase().replaceAll(' ', '') +" | < | "+ b[prop].toLowerCase().replaceAll(' ', ''))

            return 1;
        }
        return 0;
    }
}

//rimozione elemento da un array
export const arrayRemove = (arr:any[], value:any) => {

    return arr.filter((ele:any) => {
        return ele !== value;
    });
}

//escape di una stringa
export const escape = ( str:string ) => {

    return (str+'').replace(/([\\"'])/g, "\\$1").replace(/\0/g, "\\0");
}


export const MinutesToHours = (n:number) => {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return ('0' + rhours).slice(-2)  + ":" + ('0' + rminutes).slice(-2) ;
}


export const downloadJSON = (data:any, filename:string) => {
    var json = JSON.stringify(data);
    var blob = new Blob([json], {type: 'application/json'});
    var url = URL.createObjectURL(blob);
    var a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url);
  }

export const downloadCSV = (data:any, filename:string) => {
    var csv = '';
    var keys = Object.keys(data[0]);

    // Aggiungi le intestazioni
    csv += keys.join(',') + '\n';

    // Aggiungi i dati
    data.forEach(function(item:any) {
        keys.forEach(function(key, index) {
        if (index > 0) {
            csv += ',';
        }
        csv += item[key];
        });
        csv += '\n';
    });

    // Scarica il file
    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    var url = URL.createObjectURL(blob);
    var a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url);
}


export const downloadXML = (data:any, filename:string) => {
        
    // Creare un elemento radice XML
    const xmlRoot = document.createElement('data');

    // Creare un elemento figlio XML per ogni oggetto nell'array
    data.forEach((item:any) => {
    // Creare un elemento figlio per l'oggetto
    const xmlItem = document.createElement('item');

    // Aggiungere gli attributi XML all'elemento figlio
    Object.keys(item).forEach(key => {
        xmlItem.setAttribute(key, item[key]);
    });

    // Aggiungere l'elemento figlio all'elemento radice
    xmlRoot.appendChild(xmlItem);
    });

    // Serializzare l'elemento radice XML in una stringa
    const serializer = new XMLSerializer();
    const xmlString = serializer.serializeToString(xmlRoot);

    // Scaricare il file XML
    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', 'data:text/xml;charset=utf-8,' + encodeURIComponent(xmlString));
    downloadLink.setAttribute('download', filename+'.xml');
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}




export const progressGradient = (progress:number) => {
    if(progress<0) progress = 0;
    if(progress>100) progress = 100;

    let red = 0;
    let green = 0;
    if(progress <= 50){
        red = 255;
        green = Math.round( ( (255/100) * (progress*2) ) )
    }else{
        green = 255
        red = Math.round(   (255/100) / (progress*2)  );
    }

    return [
        red,
        green,
        0
    ]
}

export const slottedColor  = (kpi:number) => {
            
    if(kpi === 0){
        return [ 255, 31, 0 ]
    }else if(kpi > 0 && kpi <= 50){
        // return [ 255, 127, 0 ]
        return [255, 90, 0]

    }else if(kpi > 50 && kpi <= 70){//rgb(255, , )
        return [ 255, 210, 47 ]
    }else if(kpi > 70){
        //return [ 103, 232, 0 ]
        // return [111, 219, 119]
        return [90, 224, 60]
    }

    return [127, 127, 127];

}


export const centerCalculator = (geoArea:any) => {
    let c = {
        lat: 0,
        lng: 0,
    }
    let count = 0;
    
    
    if(Array.isArray(geoArea[0])){
        geoArea.forEach((v:any, i:number) => {
            v.forEach((_v:any, _i:number) => {
                if(_v.lat >= -180 && _v.lat <= 180 && _v.lng >= -180 && _v.lng <= 180){
                    c.lat += _v.lat;
                    c.lng += _v.lng;  
                }
            })
            count += v.length;
        });
    }else if(geoArea){
        geoArea.forEach((_v:any, _i:number) => {
            if(_v.lat >= -180 && _v.lat <= 180 && _v.lng >= -180 && _v.lng <= 180){
                c.lat += _v.lat;
                c.lng += _v.lng;

                count += 1;
            }
        })
    }

    // console.log(c)
    
    c.lat = parseFloat( (c.lat/count).toFixed(6) );
    c.lng = parseFloat( (c.lng/count).toFixed(6) );

    return c;
}



// export const debounce = (ref,  fn, millis, params) => {
//     if (ref) {
//         clearTimeout(ref);
//     }

//     ref = setTimeout(() => {
//         if (params !== undefined) {
//             fn(params);
//         } else {
//             fn();
//         }
//     }, millis);
// }



export const debounce = (func: (...args: any[]) => void, delay: number = 375) => {
    let debounceTimer: ReturnType<typeof setTimeout> | null = null;
    return (...args: any[]) => {
        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }
        debounceTimer = setTimeout(() => func(...args), delay);
    };
};


export const getCookie = (cname:string) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }



export const randomInRange = (maxValue:number, shift:number = 0) => {
    const random = ( parseInt(Math.random().toString().replace('.', '')) % ( (maxValue+shift) + 1) );

    return Math.floor(random);
}


export const globalCenter = (areas:any) => {
    let centerCoords = {
        lat: 0,
        lng: 0
    };
    let c = 0;
    if(areas.length){
       
        areas.forEach((a:any, i:number) => {
            if(a.geoArea){
                const tmpCenter = centerCalculator(a.geoArea);
                centerCoords.lat += tmpCenter.lat;
                centerCoords.lng += tmpCenter.lng;
                c++;
            }
        });
        
        centerCoords.lat = centerCoords.lat/c;
        centerCoords.lng = centerCoords.lng/c; 
    }
    
    if(centerCoords.lat === 0 && centerCoords.lng === 0){
        return {lat: 41.890208, lng: 12.492278} // Rome coords
    }

    return centerCoords;
}

export const stringToColor = (string: string) => {
        let hash = 0;
        let i;
        
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
                hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
        
        let color = '#';
        
        for (i = 0; i < 3; i += 1) {
                const value = (hash >> (i * 8)) & 0xff;
                color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
        
        return color;
}

export const stringAvatar = (name: string | undefined) => {
    if(!name){
        name = 'J N'
    }

    return {
        sx: {
        bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}


export const getBrowserLanguage = () => navigator.language.split('-')[0];




export const extensionMimeTypes = (file:File) => {
    const tmp = file.name.split('.');
    const ext = tmp[tmp.length-1];
    if(mimeTypes[ext]) {
        return mimeTypes[ext];
    }
    return [];
}

export const isFileMimeTypeValid = (file:File) => {
    const mimes = extensionMimeTypes(file);
    if(mimes.includes(file.type)){
        return true;
    }
    return false;
}

//value is extension or mimetype
export const isMimeTypeValid = (value:string) => {
    value = value.replaceAll('.', '');

    if(mimeTypes[value]){
        return true;
    }
    else{
        let mimes : string[] = [];
        let keys : string[] = Object.keys(mimeTypes);
    
        for(var k in keys){
            const mimeTypeValue = mimeTypes[keys[k]];
            
            if(typeof mimeTypeValue === 'string'){
                mimes = [ mimeTypeValue, ...mimes]
            }
            else{
                mimes = [ ...mimeTypeValue, ...mimes]
            }
        }

        if(mimes.includes(value)){
            return true;
        }
    }
    return false;
}

export const getMimeType = (ext:string) => {
    const clearedExt = ext.replaceAll('.', '');
    if(mimeTypes[clearedExt]) {
        return mimeTypes[clearedExt];
    }
    return [];
}





type ObjectMappingFunction = (row: string[] | { [key: string]: string; }) => any;
type parseCSVOptions = {
    caster?:ObjectMappingFunction | null;
    separator?:';'|','|'|';
    skipFirstLine?: boolean;
    isFirstLineHeader?: boolean;
    columns?: string[];
};

export function parseCSV<tupleTemplate>(csvContent: string, options:parseCSVOptions = {caster: null, skipFirstLine: false, isFirstLineHeader: false}): tupleTemplate[] {

    const { caster, separator = ';', skipFirstLine, isFirstLineHeader, columns } = options;
    
    const result: tupleTemplate[] = [];
    
    const lines = csvContent.split('\n').filter((v:string) => v);

    let headerColumns : string[] | undefined = columns;
    // Elabora ogni riga
    for (let i = skipFirstLine ? 1 : 0; i < lines.length; i++) {
        const line = lines[i].trim();
        if (line.split(separator).every((col) => col.trim() === '')) continue;
        
        // Dividi ogni riga in colonne
        let cols : string[] = line.split(separator).map((v:string) => v.trim());

        if(isFirstLineHeader && !headerColumns && i === 0) headerColumns = cols;

        if(headerColumns){
            let obj : any = {};

            headerColumns.forEach((v:string, i:number) => {
                if(cols[i]){
                    obj[v] = cols[i];
                }
            });
            cols = obj;
        }

        caster ? result.push(caster(cols)) : result.push(cols as any);
    }
    return result;
}
























































