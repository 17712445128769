import './css/Auth.scss';
import React, { useCallback } from 'react';

import { Route, Routes, Navigate  } from 'react-router-dom'


import LogIn from './LogIn';
// import ForgotPassword from './ForgotPassword';
// import ResetPassword from './ResetPassword';

// import importer from '../helpers/importer';
// import Datetime from '../helpers/datetime';

const Auth = () => {

    
    let authClass = "default";
 

    return (
        <div id="Auth" className={ authClass } >
            
            {/* <Particles id="tsparticles"
                options={ particlesOption } init={particlesInit} loaded={particlesLoaded}
                /> */}


            <div className={"container"}>
                <Routes>
                    <Route path="/" element={<LogIn /*login = {props.login}*/ />} />
                    {/* <Route path="/forgotPassword" element={<ForgotPassword />} />
                    <Route path="/resetPassword/:otp" element={<ResetPassword />} /> */}
                    <Route
                        path="*"
                        element={<Navigate to="/" />}
                    />
                </Routes>
            </div>
        </div >
    );
};
export default React.memo(Auth);