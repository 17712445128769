import { colors } from '@mui/material';
import React, { useState } from 'react';
import { PieChart, Pie, Cell, Sector, ResponsiveContainer } from 'recharts';

// const data = [
//   { name: 'Group A', value: 400 },
//   { name: 'Group B', value: 300 },
//   { name: 'Group C', value: 300 },
//   { name: 'Group D', value: 200 },
// ];

const renderActiveShape = (props:any) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        P{payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value} Interventi`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};


const CakeChart = (props:any) => {
    const {data, radius, colors, onPrioritySelect } = props;

    const [activeIndex, setActiveIndex] = useState<any>(0);

    const onPieEnter = (_:any, index:any) => {
        setActiveIndex(index);
    };

    const onPieClick = (_:any, index:any) => {
      const priorityNumber = Number(data[index].name);
      onPrioritySelect(isNaN(priorityNumber) ? 0 : priorityNumber);
    };

        return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart width={400} height={400}>
              <Pie
                  activeIndex={activeIndex}
                  activeShape={renderActiveShape}
                  data={data}
                  cx="50%"
                  cy="50%"
                  innerRadius={radius / 2}
                  outerRadius={(radius / 2) + ((radius * 0.2) + 15)}
                  // fill="var(--primary)"
                  dataKey="value"
                  onMouseEnter={onPieEnter}
                  onClick={onPieClick}
              >
                {
                  colors  // true or array
                  ?
                  data.map((entry:any, index: number) => (
                    <Cell key={`cell-${index}`} fill={entry.color ?? colors[index % colors.length] ?? null} />
                  ))
                  :
                  <></>
                }
              </Pie>
            </PieChart>
        </ResponsiveContainer>
        );
}

export default CakeChart;