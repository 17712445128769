import '../../components/CustomTable/CustomTable.scss';


import React, { useState, useEffect, useRef, useMemo, useCallback} from 'react';



import Tooltip from '@mui/material/Tooltip';
import ButtonBase from '@mui/material/ButtonBase';

import CustomSelect from '../../components/CustomSelect/CustomSelect';
import Popover from '../../components/Popover/Popover';
import Switch from '../../components/Switch/Switch';

// ICONS
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';


import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import IndeterminateCheckBoxRoundedIcon from '@mui/icons-material/IndeterminateCheckBoxRounded';


import DensitySmallRoundedIcon from '@mui/icons-material/DensitySmallRounded';
import FormatAlignJustifyRoundedIcon from '@mui/icons-material/FormatAlignJustifyRounded';
import DensityMediumRoundedIcon from '@mui/icons-material/DensityMediumRounded';

import { copyToClipboard, ASC, downloadJSON, downloadCSV, downloadXML } from '../../helpers/tools';
import Datetime from '../../helpers/datetime';


import { ColumnSortModel } from '../../models'


import DataObjectRoundedIcon from '@mui/icons-material/DataObjectRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import CodeRoundedIcon from '@mui/icons-material/CodeRounded';

import importer from '../../helpers/importer';


function CustomTable (props: any) {
    const {
        id = Math.random(),
        tableName,

        class : classes,

        columns = [],
        rows = [],

        draggable,

        multiSort,
        sortLimit,

        hidePerPage,

        toggleColumns,
        switchDensity,

        quickFilter,
        selectiveQuickFilter,
        columnFilter,

        stripped,

        selectedRows : selectedRowsProp,
        handleResetSelection,

        selectRows,
        multiSelect,
        showMultiSelectColumn,

        onRowClick,
        onFiltered,
        onSelectionChange,

        export: exportProp,

        textOverflow,

        disableFooter,

        customID,

        rowClass,
        rowDynamicClass
    } = props;

    // useEffect(( ) => {
    //     console.log(rows)
    // }, [rows])

    let { perPage } = props;


    let perPageList = null;
    if(!perPage){
        perPage = 25;
    }
    else if(perPage < 0){
        perPage = 0
    }

    switch(typeof perPage){
        case 'number': {
            perPageList = [
                {value: perPage, label: perPage},
                {value: perPage*2, label: perPage*2},
                {value: perPage*3, label: perPage*3},
                {value: perPage*5, label: perPage*5}
            ];
            break;
        }
        case 'object': {
            perPageList = perPage.map( (o:any, i:number) => {
                return {
                    value: o,
                    label: o,
                }
            });
            perPageList.sort( ASC( 'value' ) );
            perPage = perPage[0];
            break;
        }
    };

    const [ currentPerPage, setCurrentPerPage ] = useState<number>(perPage);


    function identifyObjectArrayTypes(arr:any[]) {
        if (arr.length === 0) {
            return {};
        }

        const firstObject = arr[0];
        const fieldTypes : any = {};

        for (const key in firstObject) {
            if (firstObject.hasOwnProperty(key)) {
                fieldTypes[key] = typeof firstObject[key];
            }
        }

        return fieldTypes;
    }
    

    const fieldTypeMap = useMemo(() => {
        return identifyObjectArrayTypes(rows.length ? rows : []);
    }, [rows]);
    let [printedColumns, setPrintedColumns] = useState<any[]>([]);
    // let [printedColumnsWidths, setPrintedColumnsWidths] = useState<any[]>([]);
   

    useEffect( () => {
        if(columns.length && rows.length /* && !printedColumns.length*/){ // <===== PROBLEMA UPDATE

            const tmpPC = columns.map((col: any, k: number) => {
                return {
                    hide: false,
                    sort: null,
                    disableSort: false,
                    type: fieldTypeMap[col.name],
                    resizable: false,
                    width: null,
                    resized: 0,
                    class: '',
                    draggable: null,
                    quickSearch: true,
                    searchInRender: false,
                    groupFiltering: false,
                    filtering: columnFilter || false,
                    filterValue: null,
                    preventSelection: false,
                    style: null,
                    clamp: 0,
                    ...col,
                }
            });

            
            setPrintedColumns([...tmpPC]);
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns, rows/*, printedColumns*/]);

    
    
    const [sort, setSort] = useState<ColumnSortModel[]>([]);
    
    const sortTypeIcon = (sort : 'asc' | 'desc' | null) => {
        switch(sort){
            case 'asc': {
                return <ExpandLessRoundedIcon/>
            }
            case 'desc': {
                return <ExpandMoreRoundedIcon/>
            }
            default: {
                return <UnfoldMoreRoundedIcon/>
            }
        }
    }
    const switchSort = (sortType: 'asc' | 'ASC' | 'desc' | 'DESC' | null) => {
        switch(sortType){
            case null: {
                return 'asc';
            }
            case 'asc':
            case 'ASC': {
                return 'desc';
            }
            case 'DESC':
            case 'desc':
            default:{
                return null;
            }
        }
    }

    const handleSort = useCallback( (colKey: number, isShift:boolean = false) => {

        let column = printedColumns[colKey];
        const sortType = switchSort(column.sort);
        
        let col = {
            index: colKey,
            name: column.name,
            sort: sortType,
            type: column.type,
        };

        if( !sortType ) {
            setSort( sort.filter((v:ColumnSortModel) => v.name !== col.name) );
            return;
        };
        
          

        const index = sort.findIndex((v:any) => v.name === col.name);

        if(index === -1){    // not found
            if(!isShift){   //single sort
                setSort([
                    col
                ])
            }else{  // multi-sort
                if(!sortLimit || sort.length < sortLimit){
                    setSort([
                        ...sort,
                        col
                    ]) 
                }
            }
        }
        else{   // found

            if(sort.length > 1 && isShift){   //se multi-sort && shift con index esistente, remove
                setSort(
                    sort.filter((v:ColumnSortModel) => v.name !== col.name)
                )
            }
            else{
                const tmp = [...sort];
                tmp[index] = col;
                
                setSort(tmp);
            }
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [printedColumns]);

    

    const [quickSearchValue, setQuickSearchValue] = useState<string | null>(null)
    const [quickSearchResults, setQuickSearchResults] = useState<boolean>(true)

    let [filteredRows, setFilteredRows] = useState<any[]>([]);
    
    //  Quick Filter
    const [ includeHiddenColumn, setIncludeHiddenColumn ] = useState<boolean>(false);

    

    useEffect(() => {
        // console.log(sort)
        if(!printedColumns.length) return;
        setPrintedColumns(printedColumns => printedColumns.map((col:any, i:number) => {
                if(sort.length){
                    const index = sort.findIndex((v:any) => v.name === col.name);
    
                    if(index !== -1){
                        col.sort = sort[index].sort;
                        col.sortOrder = index+1;
                    }else{
                        col.sort = null;
                        col.sortOrder = null;
                    }
                }
                else {
                    col.sort = null;
                    col.sortOrder = null;
                }
                return col;
            })
        ) 
    }, [sort, rows, columns]);


    function multiLevelSort(arr:any[], criteria:ColumnSortModel[]) {
        return arr.sort((a:any, b:any) => {
            for (let i = 0; i < criteria.length; i++) {
                const criterion = criteria[i];
                const aValue = a[criterion.name];
                const bValue = b[criterion.name];
        
                if(aValue === undefined || bValue === undefined){
                    return 0;
                }
                
                if(aValue === true && bValue === false){
                    return criterion.sort === 'asc' ? 1 : -1;
                }else if( aValue === false && bValue === true){
                    return criterion.sort === 'asc' ? -1 : 1;
                }

                if ((aValue > bValue) || bValue == null || bValue === '') {
                    return criterion.sort === 'asc' ? 1 : -1;
                } else if ((aValue < bValue) || aValue == null || aValue === '') {
                    return criterion.sort === 'asc' ? -1 : 1;
                }


                if (aValue < bValue) {
                    return criterion.sort === 'asc' ? -1 : 1;
                } else if (aValue > bValue) {
                    return criterion.sort === 'asc' ? 1 : -1;
                }
            }
            return 0; // If all criteria are equal
        });
    };
      
      

    // DYNAMIC FILTERING: SORT, QUICKSEARCH
    useEffect(() => {
        if(!rows.length && !printedColumns.length) return;
        if( Array.isArray(rows) ){
            let temp : any[] = rows.map((v:any, k: number) => {
                let newVal : any = {};
                printedColumns.forEach((col: any) => {
                    newVal[col.name] = v[col.name]
                })
                return newVal;
            });

            if(quickSearchValue && quickSearchValue.length){
                temp = temp.filter((r, i) => {
                    const keys = Object.keys(r);
                    for(var k in keys){
                        let src;
                        if(printedColumns[k].searchInRender && printedColumns[k].render){
                            const rendered/*: React.ReactNode */ = printedColumns[k].render(r);
                            if(!React.isValidElement(rendered)){
                                src = rendered;
                            }else{
                                src = r[keys[k]];
                            }
                        }else{
                            src = r[keys[k]];
                        }

                        if(
                            
                            (
                                printedColumns[k].quickSearch
                                && (!printedColumns[k].hide || includeHiddenColumn)
                            )
                            &&
                            (
                                (quickSearchValue === 'null' && src === null)
                                || src === quickSearchValue
                                || (typeof src == 'string' && src.toLowerCase().includes(quickSearchValue.toLowerCase()))
                                || (typeof src == 'number' && src.toString().includes(quickSearchValue.toLowerCase()) )
                            )
                           ){
                            return true;
                        }

                    }
                    return false;
                });
            }

            const filterValue = printedColumns.find((v:any) => v.filterValue);
            if(filterValue){
                temp = temp.filter((r, i) => {
                    const keys = Object.keys(r);
                    let ret = true;

                    for(var k in keys){
                        let src;
                        if(printedColumns[k].searchInRender && printedColumns[k].render){
                            const rendered/*: React.ReactNode */ = printedColumns[k].render(r);
                            if(!React.isValidElement(rendered)){
                                src = rendered;
                            }else{
                                src = r[keys[k]];
                            }
                        }else{
                            src = r[keys[k]];
                        }

                        if(
                            printedColumns[k].filterValue !== null
                            &&
                            !(
                                (printedColumns[k].filterValue === 'null' && src === null)
                                    || src === printedColumns[k].filterValue
                                    || (typeof src == 'string' && src.toLowerCase().includes(printedColumns[k].filterValue.toLowerCase()))
                                    || (typeof src == 'number' && src.toString().includes(printedColumns[k].filterValue.toLowerCase()) )
                        
                            )
                        ){
                            ret = false;
                        }

                    }
                    return ret;
                });
            }

            //  importante - non togliere o loop infinito nel render
            if(!temp.length && quickSearchValue && quickSearchValue.length){
                setQuickSearchResults(false);
                setFilteredRows([]);
                return;
            }else{
                setQuickSearchResults(true)
            }
            
            
            
            if(sort.length){
                temp = multiLevelSort( temp, sort)
            }

            setFilteredRows([...temp]);
            if(onFiltered){
                onFiltered(temp);
            }
            
        }
    }, [quickSearchValue, sort, rows, columns, includeHiddenColumn, printedColumns]);

    

    const [currentPage, setCurrentPage] = useState<number>(1);
    let printedRows = [...filteredRows];
    if(currentPerPage){
        let start = (currentPage-1)*currentPerPage;
        let end = start+currentPerPage;
        printedRows = printedRows.slice(start, end > filteredRows.length ? filteredRows.length : end);
    }

    // const grouping = printedColumns.map((col:any) => {
    //     const values : any[] = [];
    //     if(!col.hide && col.groupFiltering){
    //         filteredRows.forEach((row:any) => {
    //             let val;
    //             if(col.render){
    //                 const rendered/*: React.ReactNode */ = col.render(row);
    //                 if(!React.isValidElement(rendered)){
    //                     val = rendered;
    //                 }else{
    //                     val = row[col.name];
    //                 }
    //             }else{
    //                 val = row[col.name];
    //             }

    //             if(!values.includes(val)){
    //                 values.push(val)
    //             }
    //         })
    //     }
    //     return values;
    // })
    // console.log(grouping)

    const nPages = Math.ceil(filteredRows.length / ( currentPerPage || filteredRows.length) );

    const prevPage = () => {    //click
        const tmp = currentPage-1;
        if(tmp >= 1){
            setCurrentPage(tmp);
        }
    };

    const changePage = (e: React.ChangeEvent<HTMLInputElement>) => {    //on change input type number
        setCurrentPage( parseInt( e.target.value ) );
    };

    const nextPage = () => {    //click
        const tmp = currentPage+1;
        if(tmp <= nPages){
            setCurrentPage(tmp);
        }
    };

    const tableContentRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if(tableContentRef.current && filteredRows.length){
            if(currentPage < 1){
                setCurrentPage(1);
            }else if(currentPage > nPages){
                setCurrentPage(nPages);
            }else{
                tableContentRef.current.className = tableContentRef.current.className.replace('newpage', '')
                tableContentRef.current.className += " newpage"
            }

        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, quickSearchValue, printedRows]);


    let className = '';
    switch(textOverflow){
        case 'break-word': {
            className += 'textBreakWord ';
            break;
        }
        case 'ellipsis':
        default: {
            className += 'textEllipsis ';
            break;
        }
    };
    className += classes;
    


    const isResizing = useRef<boolean>(false);
    
    // const handleResize = (columnKey:number, newWidth:number|"reset") => {
    //     let newWidthStr = typeof newWidth === 'number' ? `${newWidth}px` : newWidth ;
        
    //     const index = columnKey + (showMultiSelectColumn ? 1 : 0);
    //     if(headerRowRef.current){
    //         const element = headerRowRef.current.childNodes[index] as HTMLElement;

    //         if(newWidth === 'reset'){
    //             // console.log('reset')
    //             element.style.width = `${printedColumnsWidths[columnKey].width}px`
    //         }
    //         else{
    //                 if(printedColumns[columnKey].width){
    //                     if(printedColumns[columnKey].width >= 100) {
    //                         if(newWidth >= 100){
    //                             element.style.width = newWidthStr;
    //                         }
    //                         else {
    //                             element.style.width = '100px';
    //                         }
    //                     }
    //                     else {   //tmp[columnKey].width < 100
    //                         if(newWidth >= printedColumns[columnKey].width){
    //                             element.style.width = newWidthStr;
    //                             // printedColumnsWidths[columnKey].resized = newWidthStr;
    //                         }
    //                         else {
    //                             element.style.width = printedColumns[columnKey].width;
    //                             // printedColumnsWidths[columnKey].resized = printedColumnsWidths[columnKey].width;
    //                         }
    //                     }
    //                 }
    //                 else {
    //                     if(newWidth >= 100){
    //                         element.style.width = newWidthStr;
    //                     }
    //                     else {
    //                         element.style.width = '100px';
    //                     }
    //                 }

    //         }

    //         if(tableContentRef.current){
    //             const tableRows = tableContentRef.current.childNodes as NodeList;

    //             tableRows.forEach((tr: Node) => {
    //                 const tableCol = tr.childNodes[index] as HTMLElement;
    //                 tableCol.style.width = element.style.width;
    //             });
    //         }
    //     }

    // };

    const handleResize = useCallback( (columnKey:any, newWidth:any) => {
        
        setPrintedColumns((pc:any) => {
            if(newWidth === 'reset'){
                pc[columnKey].resized = 0;
            }else{
                if(pc[columnKey].width && pc[columnKey].width < 100){ // if width !== null && width < 100 min width = width
                    if(newWidth >= pc[columnKey].width){
                        pc[columnKey].resized = newWidth;
                    }
                    else {
                        pc[columnKey].resized = pc[columnKey].width;
                    }
                }   //if width === null || width > 100 min width = 100
                else if(newWidth >= 100){
                    pc[columnKey].resized = newWidth;
                }
                else {
                    pc[columnKey].resized = 100;
                }
            }
            return [...pc];
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [printedColumns]);



    // const headerRowRef = useRef<HTMLDivElement>(null);
    // useEffect(() => {   // set all columns width at load
    //     if(headerRowRef.current && printedColumnsWidths.length !== printedColumns.length/* && (printedColumnsWidths.length === 0 || columns.length !== printedColumns.length)*/ ){
    //         // se c'è almeno 1 record che non è hide e che non ha width
    //         let i = showMultiSelectColumn ? 1 : 0;
            
    //         setPrintedColumnsWidths(
    //             printedColumns.map((v:any, k:number) => {  // per ogni column next child
    //                 if( headerRowRef.current && !v.hide ){
    //                     const element = headerRowRef.current.childNodes[i] as HTMLElement;
    //                     i++;
    //                     return {
    //                         width: (v.width ?? element.clientWidth >= 100 ? element.clientWidth : 100),
    //                     }
    //                 }
                
    //             })
    //         );
    //     }

    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [headerRowRef.current, printedColumns, printedColumnsWidths]);
    
    const headerRowRef = useRef<HTMLDivElement>(null);
    useEffect(() => {   // set all columns width at load
        if(headerRowRef.current){
            
            // se c'è almeno 1 record che non è hide e che non ha width
            if(printedColumns.some((v:any) => !v.width && !v.hide)/* && printedColumns.some((v:any) => v.resized)*/){

                const tmpPrintedColumns = [...printedColumns];  // copio array

                let i = showMultiSelectColumn ? 1 : 0;
                tmpPrintedColumns.forEach((v:any, k:number) => {  // per ogni column next child
                    if( headerRowRef.current && !v.hide ){
                        const element = headerRowRef.current.childNodes[i] as HTMLElement;

                        if(!tmpPrintedColumns[k].width){
                            tmpPrintedColumns[k].width = element.clientWidth >= 100 ? element.clientWidth : 100; //headerRowRef.current.childNodes[i].clientWidth;   // prendo l'index dall'accumulatore e da qui prendo l'indice per assegnare correttamente la width
                        }

                        i++;
                    }
                    
                });
                
                // console.log(tmpPrintedColumns)
                
                setPrintedColumns(tmpPrintedColumns);
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [headerRowRef.current, printedColumns]);
    


    // const outerSelectedRow : number | string | null = props.selectedRow;
    const innerSelectionRef = useRef<boolean>(true);
    const [selectedRows, setSelectedRows] = useState<any[]>(selectedRowsProp || []);

    useEffect(() => {
        innerSelectionRef.current = false;
        if(selectedRowsProp && !innerSelectionRef.current){
            setSelectedRows(selectedRowsProp);
        }
        if(!selectedRowsProp || !selectedRowsProp.length)
            setSelectedRows([]);
    }, [selectedRowsProp]);


    useEffect(() => {
        if (selectedRows && selectedRows.length && !innerSelectionRef.current && selectedRowsProp) {
            const last = selectedRows[selectedRowsProp.length - 1];

            const i = filteredRows.findIndex((row: any) => last === (customID ? row[customID] : (row.id ?? row.ID ?? row.Id ?? row.uuid ?? row.UUID ?? row.Uuid)))

            const page = Math.ceil((i + 1) / currentPerPage);
            setCurrentPage(page || 1);
        }
    }, [selectedRows, filteredRows, selectedRowsProp, customID, currentPerPage]);


    useEffect(() => {
        if(currentPage && !innerSelectionRef.current){
            if(tableBodyRef && tableBodyRef.current && tableContentRef && tableContentRef.current){
                const selected : HTMLDivElement | null = tableContentRef.current.querySelector('.tableRow.selected');
                
                if(selected && !(selected.offsetTop >= tableBodyRef.current.scrollTop && selected.offsetTop <= (tableBodyRef.current.scrollTop + tableBodyRef.current.clientHeight)) ){
                    tableBodyRef.current.scrollTop = (selected.offsetTop - tableContentRef.current.offsetTop);
                }
                    
            }
        }
    }, [currentPage, selectedRows, innerSelectionRef.current]);



    const selectRow = useCallback( (row:any, multiselect:boolean, action:string='default') => {

        innerSelectionRef.current = true;

        const identifier = (customID ? row[customID] : (row.id ?? row.ID ?? row.Id ?? row.uuid ?? row.UUID ?? row.Uuid) );

        if( selectRows || multiSelect || showMultiSelectColumn ){
            let value = [];
            if(selectedRows.includes(identifier)){
                value = selectedRows.filter((v:any) => identifier !== v )
            }else{
                if((multiSelect || showMultiSelectColumn) && multiselect){
                    value = [...selectedRows, identifier];
                }else{
                    value = [identifier];
                }
            }

            setSelectedRows( value );

            if(onSelectionChange){
                onSelectionChange(value);
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows]);

    /** @deprecated Remove this unused code */
    // const headerMultiSelector = useCallback(() => {
    //     if(filteredRows.length && selectedRows.length === 0){
    //         setSelectedRows(filteredRows.map((row:any) => (customID ? row[customID] : (row.id ?? row.ID ?? row.Id ?? row.uuid ?? row.UUID ?? row.Uuid) ) ));
    //     }
    //     else if( (filteredRows.length && selectedRows.length === filteredRows.length) || selectedRows.length){

    //         setSelectedRows([]);
    //     }

    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [selectedRows, filteredRows]);

    /** @patch HeaderMultiSelector OnChange */
    const headerMultiSelector = useCallback(() => {

        if (filteredRows.length && selectedRows.length === 0) {

            // Select all rows
            const allRowIds = filteredRows.map(row => customID ? row[customID] : (row.id ?? row.ID ?? row.Id ?? row.uuid ?? row.UUID ?? row.Uuid));
            setSelectedRows(allRowIds);
            if (onSelectionChange) {
                onSelectionChange(allRowIds);
            }

        } else {

            // Deselect all rows
            setSelectedRows([]);
            if (onSelectionChange) {
                onSelectionChange([]);
            }
            
        }
    }, [filteredRows, selectedRows, onSelectionChange]);

    /** @patch Reset selection immediately because Client is waiting */
    useEffect(() => {
        if (handleResetSelection) {

            setSelectedRows([]); 
            if (onSelectionChange) {
                onSelectionChange([]);
            }

        }
    }, [handleResetSelection]);
        



    const rowClick = useCallback( (e:any, row:any, ctrlKey:boolean) => {
        let multiselect = ctrlKey;

        //se c'è la multi-select-column e non c'è il multiselect, evitare che con shift si selezionino più righe
        if(showMultiSelectColumn && !multiSelect){
            multiselect = false;
        }
        
        //se si è cliccato sulla colonna di multiselezione, selezione multipla attiva
        if (e.target.tagName === 'path') {
            const parentElement = e.target.parentNode; // Otteniamo il genitore (parent) del <path> --> <svg>
            if(parentElement.getAttribute('data-type') === 'multiselect'){
                multiselect = true;
            }
        }else if(e.target.getAttribute('data-type') === 'multiselect'){
            multiselect = true;
        }

        selectRow(row, multiselect);


        if(onRowClick){
            onRowClick(row, e);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows]);



    const handleDataPrint = (value:string|number|boolean|undefined|null) => {
        switch(value) {
            case undefined: {
                return <p><i>{'missing data'}</i></p>;
            }
            case null: {
                return <p><i>{'null'}</i></p>;
            }
            default: return <p>{value}</p>;
        }
    };


    
    const tableContainerRef = useRef<HTMLDivElement>(null);
    const tableTitleRef = useRef<HTMLDivElement>(null);
    const tableToolbarRef = useRef<HTMLDivElement>(null);
    const tableHeaderRef = useRef<HTMLDivElement>(null);
    const tableBodyRef = useRef<HTMLDivElement>(null);
    const tableFooterRef = useRef<HTMLDivElement>(null);

    const handleBodyScroll = () => {
        if(tableHeaderRef.current && tableBodyRef.current){
            tableHeaderRef.current.scrollLeft = tableBodyRef.current.scrollLeft;
            
            handleStickies();
        }
    };

    // const handleBodyHeight = () => {
    //     if(tableContainerRef && tableContainerRef.current){
    //         let height : number | null | undefined = tableContainerRef.current.parentElement?.clientHeight;
    //         if(height){
    //             if( tableTitleRef && tableTitleRef.current) height -= tableTitleRef.current.clientHeight;
    //             if( tableToolbarRef && tableToolbarRef.current) height -= tableToolbarRef.current.clientHeight;
    //             if( tableHeaderRef && tableHeaderRef.current) height -= tableHeaderRef.current.clientHeight;
    //             if( tableFooterRef && tableFooterRef.current) height -= tableFooterRef.current.clientHeight;

    //             if(height > 0){
    //                 if(tableBodyRef && tableBodyRef.current){
    //                     // tableBodyRef.current.style.height = `${height}px!important`;
    //                     tableBodyRef.current.style.height = `${height}px`;
    //                     // console.log(height)
    //                 }
    //                 console.log(height)
    //             }
    //         }
    //     }
    // }

    const handleStickies = () => {
        if(tableHeaderRef.current && tableBodyRef.current){
            const boundingRect = tableHeaderRef.current.getBoundingClientRect();

            const searchboxes : any = tableHeaderRef.current.querySelectorAll('.searchBox');
            let totalOffset = 0;
            searchboxes.forEach((v:any, k:number) => {

                const partialOffset = totalOffset + (v.offsetParent.clientWidth/2) ;
                totalOffset += v.offsetParent.clientWidth;  //larghezza della cella


                const icon = v.querySelector('.icon');
                const input = v.querySelector('input');

                if(tableHeaderRef && tableHeaderRef.current && input.value){

                        if(tableHeaderRef?.current.scrollLeft > partialOffset){
                            v.classList.add('sticky');
                            icon.style.left = `${boundingRect.left}px`
                            icon.classList.add('left');
                            icon.classList.remove('right');
                        }
                        else if((tableHeaderRef.current.scrollLeft + tableHeaderRef.current.clientWidth) < partialOffset ){
                            v.classList.add('sticky');
                            icon.classList.add('right');
                            icon.classList.remove('left');
                            // icon.style.left = `${boundingRect.width}px`
                            icon.style.left = `${boundingRect.right - icon.clientWidth}px`
                        }
                        else{
                            icon.style.left = `unset`
                            icon.style.right = `unset`
                            v.classList.remove('sticky');
                            icon.classList.remove('left');
                            icon.classList.remove('right');
                        }
                }
            });
        }
    }

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {

        for (let entry of entries) {
            if (entry.target === tableHeaderRef.current) {
                handleStickies();
            }
        }
        });

        if (tableHeaderRef.current) {   // Collega l'osservatore alla tabella
            observer.observe(tableHeaderRef.current);
        }

        window.addEventListener('resize', handleStickies);
        // handleBodyHeight();

        return () => {
            if (tableHeaderRef.current) {
              observer.unobserve(tableHeaderRef.current);
            }
            window.removeEventListener('resize', handleStickies);
        };
    },[]);


    const [draggedColumn, setDraggedColumn] = useState<any>({start: null, end: null});
    const draggableRef = useRef<boolean>(true);
    const handleDragStart = (colKey: number) => {
      setDraggedColumn({...draggedColumn, start: colKey});
    };
  
    const handleDragOver = (colKey: number) => {
      if (draggedColumn.start !== null) {
        setDraggedColumn({...draggedColumn, end: colKey});
      }
    };
  
    const handleDragEnd = () => {
        if ( draggedColumn.start !== null && draggedColumn.end !== null ) {
            const temp = [...printedColumns];
            temp[draggedColumn.start] = printedColumns[draggedColumn.end];
            temp[draggedColumn.end] = printedColumns[draggedColumn.start];
            setPrintedColumns(temp);
            setDraggedColumn({start: null, end: null});
        }
    };


    const [density, setDensity] = useState<"sm"|"std"|"lg">("std");



    //  Export
    const [ exportSelected, setExportSelected ] = useState<boolean>(false);
    const [ excludeHiddenColumn, setExcludeHiddenColumn ] = useState<boolean>(false);
    
    
    const exportData = useCallback( (format:string) => {

        let tmp = [...filteredRows];

        if(exportSelected){
            tmp = tmp.filter((row:any) => selectedRows.includes(customID ? row[customID] : (row.id ?? row.ID ?? row.Id ?? row.uuid ?? row.UUID ?? row.Uuid) ));
        }

        if(excludeHiddenColumn){
            tmp = tmp.map((v:any, k: number) => {
                let newVal : any = {};
                printedColumns.forEach((col: any) => {
                    if(!col.hide){
                        newVal[col.name] = v[col.name];
                    }
                })
                return newVal;
            });
        }

        
        switch(format){
            case 'xml': {
                downloadXML(tmp, tableName+'_'+new Datetime().getUnixTimestampMillis() );
                break;
            }
            case 'csv':{
                downloadCSV(tmp, tableName+'_'+new Datetime().getUnixTimestampMillis() );
                break;
            }
            case 'json':{
                downloadJSON(tmp, tableName+'_'+new Datetime().getUnixTimestampMillis() );
                break;
            }
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredRows, exportSelected, excludeHiddenColumn, selectedRows]);



    let debounceTimer:any|null = null;


    const Renderer = (column: any, row:any) => {
        const rendered/*: React.ReactNode */ = column.render(row);
        if(React.isValidElement(rendered)){
            return rendered
        }else{
            return <p>{rendered}</p>
        }
    }

    return (
        <>
        <div id={id} ref={tableContainerRef} className={"tableContainer " + className}>
            <div ref={tableTitleRef} className="tableTitle">
            {
                tableName
                &&
                <h4 className="title">
                    { tableName }
                </h4>
            }
            </div>
            <div ref={tableToolbarRef} className="tableToolbar">
                <div className='tools'>
                    { !hidePerPage &&
                    <div className="perPage">
                        <label>Per page:
                            <CustomSelect
                                placeholder={perPage}
                                options={perPageList}
                                preselected={currentPerPage}
                                onChange={(value:any) => {
                                    setCurrentPerPage(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </label>
                    </div>
                    }
                    {
                    toggleColumns
                    &&
                    <div className="columns">
                        <Popover
                            placeholder={<img src={importer.ic.require('columns.svg')} alt="" />}
                            // placeholder={<ViewWeekRoundedIcon />}
                            title={'Columns'}
                        >
                            {
                                printedColumns.map((v:any, k:number) =>
                                    <div
                                        key={k}
                                        className='toggleColumns'
                                    >
                                        <p>
                                        {v.displayName || v.name}
                                        </p>
                                        <div>
                                            <Switch
                                                value={!v.hide}
                                                onChange={(value:any) => {
                                                    const tmp = [...printedColumns];
                                                    tmp[k].hide = !v.hide;
                                                    tmp[k].width = tmp[k].width || 100;
                                                    setPrintedColumns(tmp);
                                                }}
                                            />
                                        </div>
                                    </div>
                                )

                            }
                        </Popover>
                    </div>
                    }
                    {
                    switchDensity
                    &&
                    <div className="columns">
                        <Popover
                            placeholder={<img src={importer.ic.require('rowDensity.svg')} alt="" />}
                            // placeholder={<DensitySmallRoundedIcon />}
                            title={'Density'}
                        >
                            <div className='switchDensity'
                                onClick={() => {
                                    setDensity('sm');
                                }}
                            >
                                <FormatAlignJustifyRoundedIcon />
                                Small
                            </div>
                            <div className='switchDensity'
                                onClick={() => {
                                    setDensity('std');
                                }}
                            >
                                <DensitySmallRoundedIcon />
                                Standard
                            </div>
                            <div className='switchDensity'
                                onClick={() => {
                                    setDensity('lg');
                                }}
                            >
                                <DensityMediumRoundedIcon />
                                Large
                            </div>
                        </Popover>
                    </div>
                    }
                    { 
                    exportProp
                    &&
                    <div className="exports">
                        {
                        exportProp
                        &&
                        <Popover
                            placeholder={<img src={importer.ic.require('download.svg')} alt="" />}
                            // placeholder={<FileDownloadRoundedIcon />}
                            title={'Export'}
                            options={[
                                    {
                                        name: 'only_selected',
                                        label: 'Only selected',
                                        action: <Switch
                                            value={exportSelected}
                                            onChange={() => {
                                                setExportSelected(!exportSelected)
                                            }}
                                        />
                                    },
                                    {
                                        name: 'hidden_columns',
                                        label: 'Exclude Hidden Columns',
                                        action: <Switch
                                            value={excludeHiddenColumn}
                                            onChange={() => {
                                                setExcludeHiddenColumn(!excludeHiddenColumn)
                                            }}
                                        />
                                    }
                                ]}
                        >
                            {exportProp && exportProp.json &&
                                <Tooltip title="Export JSON">
                                    <ButtonBase
                                    onClick={() => {
                                        exportData('json');
                                    }}>
                                        <DataObjectRoundedIcon/>
                                        JSON
                                    </ButtonBase>
                                </Tooltip>
                            }
                            {exportProp && exportProp.csv &&
                                <Tooltip title="Export CSV">
                                    <ButtonBase
                                    onClick={() => {
                                        exportData('csv');
                                    }}>
                                        <DescriptionRoundedIcon/>
                                        CSV
                                    </ButtonBase>
                                </Tooltip>
                            }
                            {exportProp && exportProp.xml &&
                                <Tooltip title="Export XML">
                                    <ButtonBase
                                    onClick={() => {
                                        exportData('xml');
                                    }}>
                                        <CodeRoundedIcon/>
                                        XML
                                    </ButtonBase>
                                </Tooltip>
                            }
                        </Popover>
                        }
                    </div>
                    }
                </div>
                { quickFilter &&
                    <div className='searchBoxContainer'>
                    {
                        selectiveQuickFilter
                        &&
                        <Popover
                            placeholder={<img src={importer.ic.require('filter.svg')} alt="" />}
                            // placeholder={<FilterAltRoundedIcon />}
                            title={'COLUMNS'}
                            options={[
                                {
                                    name: 'hidden_columns',
                                    label: 'Include Hidden Columns',
                                    action: <Switch
                                        value={excludeHiddenColumn}
                                        onChange={() => {
                                            setIncludeHiddenColumn(!includeHiddenColumn)
                                        }}
                                    />
                                }]
                            }
                        >
                            {
                                printedColumns.map((v:any, k:number) =>
                                    <div
                                        key={k}
                                        className='toggleColumns'
                                    >
                                        {v.displayName || v.name}
                                        <Switch
                                            value={v.quickSearch}
                                            onChange={() => {
                                                const tmp = [...printedColumns];
                                                tmp[k].quickSearch = !v.quickSearch;
                                                setPrintedColumns(tmp);
                                            }}
                                        />
                                    </div>
                                )
                            }
                        </Popover>
                    }

                        <div className={`searchBox ${quickSearchResults ? '' : 'noResults' }`}>
                            <div className='icon'>
                                <div className="i"></div>
                            </div>
                            <input type="search"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setQuickSearchValue(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                }
            </div>
            <div ref={tableHeaderRef} className="tableHeader">

                { printedColumns.length > 0 &&
                    <div
                        ref={headerRowRef}
                        className='headerRow'
                        onDragOver={() => {}}
                        onDrop={() => {}}
                    >
                        {
                            showMultiSelectColumn &&
                            <div
                                key={'multiselect'}
                                className="headerCell multiSelectColumn"
                                onClick={headerMultiSelector}
                            >
                                {
                                    selectedRows.length === 0
                                    ?
                                    <CheckBoxOutlineBlankRoundedIcon />
                                    :
                                    (
                                        selectedRows.length === filteredRows.length
                                        ?
                                        <CheckBoxRoundedIcon />
                                        :
                                        <IndeterminateCheckBoxRoundedIcon />
                                    )
                                }
                            </div>
                        }

                        {printedColumns.map((col: any, colKey: number) =>
                        col.hide !== undefined && !col.hide &&
                        // <ButtonBase>
                        <div key={col.name} 
                            className={'headerCell ' + (colKey === draggedColumn.start || colKey === draggedColumn.end ? ' dragging' : '') }
                            style={{
                                width: col.resized || col.width//printedColumnsWidths[colKey] ? printedColumnsWidths[colKey].width : null,
                            }}
                            onClick={!col.disableSort ? (e: React.MouseEvent) => {
                                if(isResizing.current) return;

                                if ((e.target instanceof HTMLInputElement)) return;
                                
                                if( (e.target instanceof HTMLDivElement) 
                                    &&
                                    (e.target.classList.contains('resizer') || e.target.classList.contains('searchBox'))
                                ) return;
                                
                                handleSort(colKey, (multiSort && e.shiftKey) ? true : false);
                            } : () => {}}

                            draggable={ (draggableRef.current && (draggable || col.draggable !== false) ) ? true : false}
                            onDragStart={(e) => {
                                if( (draggable || col.draggable) && col.draggable !== false){
                                    handleDragStart(colKey)
                                }
                            }}
                            onDragOver={(e:any) => {
                                e.preventDefault();
                                if( (draggable || col.draggable) && col.draggable !== false){
                                    e.dataTransfer.dropEffect = 'move';
                                    handleDragOver(colKey);
                                }
                            }}
                            onDragEnd={(e:any) => {
                                e.preventDefault();
                                if( (draggable || col.draggable) && col.draggable !== false){
                                    handleDragEnd();
                                }
                            }}
                        >
                                {/* <ButtonBase> */}
                                <p>
                                {col.displayName === "" ? "" : col.displayName || col.name || ""}
                                
                                {
                                    !col.disableSort
                                    ?
                                    <span className={col.displayName === "" ? "sortable noDisplayName" : "sortable" }>
                                        {sortTypeIcon(col.sort)}
                                        {sort.length > 1 && col.sortOrder && 
                                            <span className='sortOrder'>
                                                {col.sortOrder}
                                            </span>
                                        }
                                    </span>
                                    :
                                    <></>
                                }
                                </p>
                                {/* </ButtonBase> */}
                                {col.filtering
                                &&
                                <div className='searchBox'>
                                    <div className='icon'>
                                        <div className="i"></div>
                                    </div>
                                    <input type={"search"} 
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {

                                            if (debounceTimer) {
                                                clearTimeout(debounceTimer);
                                                debounceTimer = null;
                                            }
                                        
                                            if (!debounceTimer) {
                                                debounceTimer = setTimeout(() => {
                                                    const tmp = [...printedColumns];
                                                    tmp[colKey].filterValue = e.target.value;
                                                    setPrintedColumns(tmp);
                                                }, 375); // 300 millisecondi di ritardo
                                            }
                                        }}
                                    />
                                </div>
                                }

                                {col.resizable &&  (colKey !== printedColumns.length-1) ?
                                    <div
                                        className={`resizer`}
                                        
                                        onMouseDown={(e:any) => {
                                            e.preventDefault(); 
                                            isResizing.current = true;
                                            
                                            const w = e.target.clientWidth + e.target.offsetLeft;
                                            const msX = e.clientX;
                                            const startX = e.clientX;
                        
                                            const handleMouseMove = (e:any) => {
                                                const newX = e.clientX;

                                                if(startX && newX){                                            
                                                    const newWidth = w + (newX - msX);
                                                    
                                                    handleResize(colKey, newWidth);
                                                }  
                                            };
                        
                                            const handleMouseUp = () => {
                                                window.removeEventListener('mousemove', handleMouseMove);
                                                window.removeEventListener('mouseup', handleMouseUp);

                                                return setTimeout(() => {
                                                    isResizing.current = false;
                                                }, 15);
                                            };
                        
                                            window.addEventListener('mousemove', handleMouseMove);
                                            window.addEventListener('mouseup', handleMouseUp);
                                        }}
                                        onDoubleClick={() => {
                                            handleResize(colKey, 'reset');
                                        }}
                                    >
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                            //</ButtonBase>
                        )}
                    </div>
                }
{/* 
                <div className='test2'>

                </div> */}
            </div>
            <div ref={tableBodyRef} className='tableBody'
                onScroll={handleBodyScroll}
            >
                <div ref={tableContentRef} className={`tableContent density-${density} ${stripped ? 'stripped' : '' } ${printedRows.length ? '' : 'noData'}`}>
                {
                ( printedRows.length && printedRows.map((row: any, rowKey: number) => {
                    return  <div
                                key={rowKey}
                                onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                                    rowClick(e, row, (multiSelect || showMultiSelectColumn) && e.ctrlKey)
                                }}
                                className={'tableRow ' 
                                                        + (rowDynamicClass ? rowDynamicClass(row) : '') + ' '
                                                        + (rowClass ?? '') + ' '
                                                        + (selectedRows.length && selectedRows.includes(customID ? row[customID] : (row.id ?? row.ID ?? row.Id ?? row.uuid ?? row.UUID ?? row.Uuid) ) ? 'selected' : '') 
                                                        + (selectedRows.length > 1 ? ' multiSelect' : '' ) }
                            >
                                {
                                    showMultiSelectColumn &&
                                    <div
                                        key={'multiselect'}
                                        className='tableCell multiSelectColumn'
                                        data-type={'multiselect'}
                                    >
                                        {
                                            selectedRows.includes(customID ? row[customID] : (row.id ?? row.ID ?? row.Id ?? row.uuid ?? row.UUID ?? row.Uuid) )
                                            ?
                                            <CheckBoxRoundedIcon data-type={'multiselect'} />
                                            :
                                            <CheckBoxOutlineBlankRoundedIcon data-type={'multiselect'} />
                                        }
                                        
                                    </div>
                                }
                        
                                {row && Object.keys(row).map((key, index) =>
                                    printedColumns[index] !== undefined &&
                                    !printedColumns[index].hide &&
                                    <div key={key+index}
                                        
                                        className={'tableCell '
                                                                + (printedColumns[index].dynamicClass ? printedColumns[index].dynamicClass(row) : '' ) + ' ' 
                                                                + (printedColumns[index].class || '') + ( printedColumns[index].copy ? ' copy' : '' ) 
                                                                + (printedColumns[index].sort ? ' sorted' : '' ) 
                                                                + (index === draggedColumn.start || index === draggedColumn.end ? ' dragging' : '') 
                                                                + (printedColumns[index].clamp ? ' clamp' : '')}
                                        onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                                            if(printedColumns[index].preventSelection){
                                                e.stopPropagation();
                                            }
                                            
                                            if(printedColumns[index].onClick){
                                                printedColumns[index].onClick(row[key], row, e);
                                            }
                                            if(printedColumns[index].copy){
                                                copyToClipboard(row[key]);
                                                //add toast?
                                            }
                                        } }
                                        style={{
                                            width: printedColumns[index].resized || printedColumns[index].width,
                                            ...printedColumns[index].style,
                                                WebkitLineClamp: printedColumns[index].clamp ? printedColumns[index].clamp : null,
                                            
                                            }}
                                    >
                                        {
                                            printedColumns[index].render
                                            ?
                                            Renderer(printedColumns[index], row)
                                            :
                                            handleDataPrint(row[key])
                                        
                                        }
                                    </div>
                                )}
                            </div>
                })
                ) || <div className='noData'>No Data</div>}
                </div>
            </div>

            {!disableFooter && 
                <div ref={tableFooterRef} className="tableFooter">
                    <div className="records">
                        <p>
                            There are {rows.length} records.
                            {
                                quickSearchValue || printedColumns.find((v:any) => v.filterValue) ? ` (${filteredRows.length}) Filtered.` : ''
                            }
                            {` (${printedRows.length})`} Shown.
                            {
                                selectedRows.length ? ` (${selectedRows.length}) Selected.` : ''
                            }
                        </p>
                    </div>

                    <div className="pagination">
                        { currentPerPage
                            ?
                            <div className="page">
                                <span onClick={prevPage}><ChevronLeftRoundedIcon/></span>
                                <p>Page <input type="number" min="1" max={nPages} value={currentPage} onChange={changePage}/> / {nPages}</p>
                                <span onClick={nextPage}><ChevronRightRoundedIcon/></span>
                            </div>
                            :
                            <></>
                        }
                    </div>
                </div>
            }
        </div>
       </>
    );
}

export default CustomTable;




    // const rowsFiller = () => {
    //     const fill = currentPerPage - printedRows.length;
    //     const fillerRow : any[]= [];
    //     for(var i = 0; i < fill; i++){
    //         fillerRow.push(<tr key={Math.random()}></tr>);
    //     }
    //     return <>
    //         { fillerRow }
    //     </>
    // }
