import React from 'react';
import './CustomGroup.scss';

interface CustomGroupProps {
    type?: 'separated' | 'union';
    align?: 'center' | 'right' | 'end' | 'left' | 'start';
    reverse?: boolean;
    class?: string;
    children?: React.ReactNode;
}

const CustomGroup: React.FC<CustomGroupProps> = ({
    type = 'union', // default to 'union'
    align = 'left', // default to 'left'
    reverse = false,
    class: additionalClass,
    children
}) => {
    let className = "group ";
    
    // Handle the type of group and alignment
    switch (type) {
        case "separated":
            className += 'separated ';
            switch (align) {
                case 'center':
                    className += 'align-center ';
                    break;
                case 'right':
                case 'end':
                    className += 'align-right ';
                    break;
                case 'left':
                case 'start':
                default:
                    className += 'align-left ';
                    break;
            }
            break;
        case "union":
        default:
            className += 'union ';
            break;
    }

    // Apply reverse class if necessary
    if (reverse) {
        className += 'reverse ';
    }

    // Add any additional classes passed through props
    if (additionalClass) {
        className += ` ${additionalClass}`;
    }

    return (
        <div className={className}>
            {children}
        </div>
    );
}

export default React.memo(CustomGroup);
