
import axios from 'axios';


// (method:string, url:string, params:object, then : (...args: any[]) => void) => {
const Catch = (error, auth) => {
    // console.error(error)
    if (error && error.response && error.response.status) {

        switch (error.response.status) {
            case 401: {
                localStorage.clear();
                if (auth.data && auth.set) auth.set(null);
                return false;
                // break;
            }
            // case 451: {
            //   break;
            // }
            default: {
                return true;
                // break;
            }
        }
    }
    return true;
}

const Axios = (auth, method, url, then, _catch, data = {}, config = {}, dataType = 'formData') => {
    let reqConfig = {
        withCredentials: true,
        ...config,
    }

    reqConfig.headers = {
        'Access-Control-Allow-Origin': 'http://localhost:8081/', // Replace '*' with your desired origin
        'Access-Control-Allow-Methods': 'GET,PUT,PATCH,POST,DELETE',
        'Access-Control-Allow-Headers': 'Content-Type',
        ...config.header,
    }

    if (dataType === 'formData') {
        reqConfig.headers = {
            ...reqConfig.headers,
            "Content-Type": "multipart/form-data",
        }
    }


    method = method.toLowerCase();
    const request = axios[method];
    
    switch (method) {
        //   get<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: RawAxiosRequestConfig<D>): Promise<R>;
        case 'get': {
            if(data){
                reqConfig.params = data;
            }
            request(url, { ...reqConfig }, )
                .then((result) => result.status !== 204 ? then(result) : console.log('204 : No Content'))
                .catch(error => {
                    if (Catch(error, auth)) {
                        _catch(error);
                    }
                });
            break;
        }
        //   post<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: RawAxiosRequestConfig<D>): Promise<R>;
        case 'post': {
            request(url, { ...data }, { ...reqConfig })
                .then((result) => result.status !== 204 ? then(result) : console.log('204 : No Content'))
                .catch(error => {
                    if (Catch(error, auth)) {
                        _catch(error);
                    }
                });
            break;
        }
        //   put<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: RawAxiosRequestConfig<D>): Promise<R>;
        case 'put': {
            request(url, { ...data }, { ...reqConfig })
                .then((result) => result.status !== 204 ? then(result) : console.log('204 : No Content'))
                .catch(error => {
                    if (Catch(error, auth)) {
                        _catch(error);
                    }
                });
            break;
        }
        //   patch<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: RawAxiosRequestConfig<D>): Promise<R>;
        case 'patch': {
            request(url, { ...data }, { ...reqConfig })
                .then((result) => result.status !== 204 ? then(result) : console.log('204 : No Content'))
                .catch(error => {
                    if (Catch(error, auth)) {
                        _catch(error);
                    }
                });
            break;
        }
        //   delete<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: RawAxiosRequestConfig<D>): Promise<R>;
        case 'delete': {
            request(url, { ...reqConfig })
                .then((result) => result.status !== 204 ? then(result) : console.log('204 : No Content'))
                .catch(error => {
                    if (Catch(error, auth)) {
                        _catch(error);
                    }
                });
            break;
        }
        default: {
            console.error('Bad method')
            break;
        }
    }

}

export default Axios;


// export class Axios {
//   constructor(config?: RawAxiosRequestConfig);
//   defaults: AxiosDefaults;
//   interceptors: {
//     request: AxiosInterceptorManager<AxiosRequestConfig>;
//     response: AxiosInterceptorManager<AxiosResponse>;
//   };
//   getUri(config?: RawAxiosRequestConfig): string;
//   request<T = any, R = AxiosResponse<T>, D = any>(config: RawAxiosRequestConfig<D>): Promise<R>;
//   head<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: RawAxiosRequestConfig<D>): Promise<R>;
//   options<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: RawAxiosRequestConfig<D>): Promise<R>;
//   postForm<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: RawAxiosRequestConfig<D>): Promise<R>;
//   putForm<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: RawAxiosRequestConfig<D>): Promise<R>;
//   patchForm<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: RawAxiosRequestConfig<D>): Promise<R>;
// }
