// Import necessary React features and components
import React, { useState, useCallback } from 'react';
import './ContextMenuContent.scss'; // Import the associated styles

// Type definitions for the menu options
interface OptionType {
    disabled?: boolean;
    onClick?: (data: any) => void;
    subs?: OptionType[];
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    label: string;
}

// Props type definition for ContextMenuContent component
interface ContextMenuContentProps {
    options: OptionType[];
    class?: string;
    data?: any;
    style?: React.CSSProperties;
    variant?: 'island' | 'std';
    anchor?: 'left' | 'right';
    innerRef?: React.Ref<HTMLDivElement>;
    icon?: React.ReactNode;
    title?: string;
    actions?: React.ReactNode;
}

// The ContextMenuContent component definition
const ContextMenuContent: React.FC<ContextMenuContentProps> = ({
    options,
    class: classProp,
    data = null,
    style,
    variant = 'std', // Default to 'std' if not specified
    anchor = 'right', // Default to 'right' if not specified
    innerRef,
    icon,
    title,
    actions
}) => {
    // State to track the hover index for submenu visibility
    const [hoverIndex, setHoverIndex] = useState<string>('');

    // Function to render each menu option
    const printMenuOption = useCallback((option: OptionType, index: number, level: string | number) => {
        const lv: string = level.toString() + index;

        return (
            <div
                key={lv} // Use level and index for a unique key
                className={`option ${option.disabled ? 'disabled' : ''}`}
                onClick={() => {
                    if (option.onClick && !option.disabled && !option.subs) {
                        option.onClick(data);
                    }
                }}
                onMouseEnter={() => setHoverIndex(lv)}
                onMouseLeave={() => setHoverIndex(prev => prev.slice(0, -1))}
            >
                <div className='label'>
                    {option.leftIcon && <div className='leftIcon'>{option.leftIcon}</div>}
                    <div className="text">{option.label}</div>
                </div>
                {
                    option.subs && option.subs.length > 0 ? (
                        <img className='arrow' src="./chevronRight.svg" alt="" />
                    ) : (
                        option.rightIcon && <div className='rightIcon'>{option.rightIcon}</div>
                    )
                }
                {
                    option.subs && option.subs.length > 0 && lv === hoverIndex &&
                    <div className='contextMenuContent subMenu'>
                        {option.subs.map((o, i) => printMenuOption(o, i, lv))}
                    </div>
                }
            </div>
        );
    }, [hoverIndex, data]);

    // Component rendering logic
    return (
        <div
            ref={innerRef}
            className={`contextMenuContent ${variant} ${anchor} ${classProp || ''}`}
            style={style}
        >
            {/* Header section for icon, title, and actions */}
            {(icon || title || actions) && (
                <div className='contextMenu-header'>
                    {(icon || title) && (
                        <div className='contextMenu-title'>
                            {icon && <div className="contextMenu-icon">{icon}</div>}
                            {title && <div>{title}</div>}
                        </div>
                    )}
                    {actions && (
                        <div className='contextMenu-actions'>{actions}</div>
                    )}
                </div>
            )}
            {/* Body section that maps through the options to render them */}
            <div className='contextMenu-body'>
                {options.map((option, index) => printMenuOption(option, index, index))}
            </div>
        </div>
    );
}

// Export the component, wrapped in React.memo for performance optimization
export default React.memo(ContextMenuContent);
