// Import React and required components
import React from 'react';
import './Card.scss';  // Assuming relative path might differ based on your project structure
import Title from '../Title/Title';

// Define types for props
interface CardProps {
    type?: 'floating' | 'vanilla';  // Specify expected types
    class?: string;
    children?: React.ReactNode;
    icon?: React.ReactNode;
    title?: string;
    actions?: React.ReactNode;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    onMouseDown?: React.MouseEventHandler<HTMLDivElement>;
    onMouseUp?: React.MouseEventHandler<HTMLDivElement>;
    onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
    onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
    onDragOver?: React.DragEventHandler<HTMLDivElement>;
    style?: React.CSSProperties;
    id?: string;
}

// Define the Card component with typed props
const Card: React.FC<CardProps> = ({
    type,
    class: additionalClass,
    children,
    icon,
    title,
    actions,
    onClick,
    onMouseDown,
    onMouseUp,
    onMouseEnter,
    onMouseLeave,
    onDragOver,
    style,
    id
}) => {
    // Build className based on props
    let className = "card";
    if (type === 'floating' || type === 'vanilla') {
        className += ` ${type}`;
    }
    if (additionalClass) {
        className += ` ${additionalClass}`;
    }

    // Render the component
    return (
        <div
            id={id}
            className={className}
            style={style}
            onClick={onClick}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onDragOver={onDragOver}
        >
            {(icon || title || actions) && (
                <div className='card-header'>
                    {(icon || title) && (
                        <div className='card-title'>
                            {icon && (
                                <div className="card-icon">
                                    {icon}
                                </div>
                            )}
                            {title && <Title>{title}</Title>}
                        </div>
                    )}
                    {actions && (
                        <div className='card-actions'>
                            {actions}
                        </div>
                    )}
                </div>
            )}
            <div className='card-body'>
                {children}
            </div>
        </div>
    );
}

export default React.memo(Card);
