import { createContext } from "react";
import { Session } from './contexts/Session';
import { Settings } from './contexts/Settings';
import { Snackbar } from './contexts/Snackbar';

export const ctxSession = createContext<Session | null>(null);

export const ctxSettings = createContext<Settings | null>(null);

export const ctxSnackbar = createContext<Snackbar | null>(null);

export const ctxGeneric = createContext(null);



// export ctxSession;

// export default createContext<ctxSession>({});
// export default {
//     Session
// };