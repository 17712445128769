import './css/Navbar.scss'

import React, { useState, useCallback, useContext } from "react";
import { useNavigate } from 'react-router-dom';


import Avatar from '@mui/material/Avatar';

import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

import {
    Popover,
    CustomIconButton,
    ContextMenuContent,
    CustomModal,
    CustomButton,
    Title,
} from '../components';



import { stringAvatar, getBrowserLanguage } from '../helpers/tools';

import { ctxSession, ctxSettings } from '../store';


import Axios from '../helpers/axios';
import importer from '../helpers/importer';
import config from '../config';

import language from '../languages';


import MeetingRoomRoundedIcon from '@mui/icons-material/MeetingRoomRounded';

import DirectionsRunRoundedIcon from '@mui/icons-material/DirectionsRunRounded';


const Navbar = (props: any/*props: HeaderProps*/) => {
    const navigate = useNavigate();

    const session = useContext(ctxSession);


    const settings = useContext(ctxSettings);
    const lang = language(settings?.data?.lang);


    const [infoOpened, setInfoOpened] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);


    const handleToggle = () => {
        setInfoOpened((prevOpen) => !prevOpen);
    };
    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setInfoOpened(false);
    };
    

    const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);


    const logout = () => {
        const onSuccess = () => {
            if(session && session.set){
                localStorage.clear();
                session.set(null);
            }
        }
        const onError = () => { }
        
        Axios(session, 'get', `${config.api.internal}/Auth/logout`, onSuccess, onError);
    };


    const updateLanguage = useCallback( (lang: "it" | "en" | "fr") => {
        const formData = {
            lang
        };

        const onSuccess = () => {
            let settingsLS = JSON.parse( localStorage.getItem('settings') || '{}' );
            if(settings && settings.set){
                settingsLS.lang = formData.lang;
                settings.set(settingsLS);
                localStorage.setItem('settings', JSON.stringify(settingsLS) );
                console.log(settings)
            }
        }
        const onError = () => { }

        
        Axios(session, 'patch', `${config.api.internal}/Account/lang`, onSuccess, onError, formData);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [settings, settings?.data, settings?.set]);


    return (
        <nav id="navbar">
            <div className="logoBox">
                <img id="logo"
                    src={importer.img.require("whiteLogo.png")}
                    alt=""
                    onClick={() => { navigate('/') }}
                />
                STEAM LEAKS
            </div>

            <div className="utils">
                <Popover
                    class="nav-popover"
                    variant="ghost"
                    anchor="bottom-right"
                    arrow={true}
                    overflow={true}
                    placeholder={
                        <div className='account'>
                            <Avatar {...stringAvatar(session?.data?.userData?.name)} />
                            <div className="anag">
                                <h4>{`${session?.data?.userData?.name}`}</h4>
                                <h5>@{session?.data?.userData?.email}</h5>
                            </div>
                        </div>
                    }
                >
                    <ContextMenuContent
                        anchor="right"
                        options = {[
                                {
                                    leftIcon: <PublicRoundedIcon style={{fill:"var(--primary)"}} />,
                                    label: lang.language,
                                    subs: [{
                                            leftIcon: <img style={{}} src={importer.flag.require('it.svg')} alt="" />,
                                            label: lang.universal.languages.italian,
                                            rightIcon: settings?.data?.lang === 'it' || (!settings?.data?.lang && getBrowserLanguage() === 'it') ? <CheckRoundedIcon /> : <></>,
                                            onClick: () => {
                                                updateLanguage('it');
                                            }
                                        },
                                        // {
                                        //     leftIcon: <img style={{}} src={importer.flag.require('gb.svg')} alt="" />,
                                        //     label: lang.universal.languages.english,
                                        //     rightIcon: settings?.data?.lang === 'en' || (!settings?.data?.lang && getBrowserLanguage() === 'en') ? <CheckRoundedIcon /> : <></>,
                                        //     onClick: () => {
                                        //         updateLanguage('en');
                                        //     }
                                        // }
                                    ]
                                }
                        ]}
                    />
                </Popover>
                

                <CustomIconButton
                    variant="light"
                    size="lg"
                    onClick={() => {
                        setIsLoggingOut(true);
                    }}
                >
                    <LogoutRoundedIcon />
                </CustomIconButton>
            </div>

            
            <CustomModal
                isOpen={isLoggingOut}
                
                onClose={() => {
                    setIsLoggingOut(false);
                }}
                closeButton={true}
                footer={<>
                    <CustomButton
                        variant="secondary"
                        onClick={() => {
                            setIsLoggingOut(false);
                        }}
                    >
                        {lang.cancel}
                    </CustomButton>
                    <CustomButton
                        onClick={logout}
                    >
                        {lang.logout}
                    </CustomButton>
                </>}
            >
                
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <DirectionsRunRoundedIcon style={{fill: 'var(--primary)', width: 100, height: 100, transform: 'translateX(40px)'}} />
                    <MeetingRoomRoundedIcon style={{fill: 'var(--primary)', width: 150, height: 150, transform: 'scaleX(-1)'}}  />
                </div>
                <Title class="m-2">{lang.really_want_logout}</Title>
                
            </CustomModal>
        </nav>
    );
};
export default React.memo(Navbar);