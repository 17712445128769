import './css/LogIn.scss';
import React, { useContext, useState } from 'react';
import { useNavigate  } from 'react-router-dom'

import {
    Card,
    Title,
    CustomButton,
    CustomInput
} from '../components';



import importer from '../helpers/importer'


import Axios from '../helpers/axios';
import { ctxSession } from '../store';

import { UserData_model } from '../models/data/UserData_model';
import { SessionModel } from '../models';
import formDataValidator from '../helpers/formData';


import { decodeToken } from 'react-jwt';

import config from '../config/'

import language from '../languages';

import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import LockRoundedIcon from '@mui/icons-material/LockRounded';



const LogIn = () => {

    const navigate = useNavigate();
    const session = useContext(ctxSession);

    const [loading, setLoading] = useState<boolean>(false);
    const [errorAnimation, setErrorAnimation] = useState<boolean|string>(false);
    const [error, setError] = useState<boolean|string>(false);

    const lang = language();

    const animateError = () => {
        setErrorAnimation(true);
        setTimeout( () => {
            setErrorAnimation(false);
        }, 100);
    }
    
    const logIn = (e:any) => {
        e.preventDefault();

        const formData:any = formDataValidator(e.target, 'object');
        
        
        if(formData.rawEmail && formData.rawPassword && formData.rawPassword.length >= 8){
            setError(false);
            setLoading(true);


            const onSuccess = (response:any) => {
                if( response.data.token ){
                
                    localStorage.clear();
                    localStorage.setItem('session', 'true');

                    let userData : UserData_model | null = decodeToken(response.data.token);    // decodedToken
                    
                    if(session?.set){
                        const sess:SessionModel = {
                            userData,
                        };

                        session.set(sess)
                    } 
                }else{
                    console.log('no Token');
                }

            }

            const onError = (error:any) => {
                animateError();
                localStorage.clear();
                setLoading(false);
            }

            Axios(session, 'post', `${config.api.internal}/auth/`, onSuccess, onError, formData);
            
        }else{
            setError('bad_credentials');
            animateError();
        }
    }

    return (
        <Card type="floating" class="shadow" style={{width: '350px'}}>
            <div className="logo">
                <img className="mb-3" src={ importer.img.require('fullLogo.png') } alt="ISME SRL"/>
                {/* <img className={`mb-3 ${loading ? 'spin' : '' } ${errorAnimation ? 'shake' : '' } `} src={ importer.img.require('fullLogo.png') } alt="" /> */}
                {/* <h1 className='mb-2'>{lang.log_in}</h1> */}
                <Title size="lg">STEAM LEAK</Title>
                <h4 className='mb-0'>{lang.log_in_into_your_account}</h4>
            </div>
            <div className='my-4'>
                <form onSubmit={logIn}>
                    <div className="mb-3">
                        <CustomInput name="rawEmail" variant="cloud" type="email" icon={<AccountCircleRoundedIcon className='text-primary' />} placeholder={lang.username} class="mb-2"></CustomInput>
                        <CustomInput name="rawPassword" variant="cloud" minLength={8} type="password" icon={<LockRoundedIcon className='text-primary' />} placeholder={lang.password} showable={true}></CustomInput>
                    </div>

                    <div>
                        <CustomButton variant="primary" type={"submit"} classes="w-100 mb-2">{lang.login}</CustomButton>
                        {/* <a className='' onClick={() => {navigate('/forgotPassword')}}><strong>{lang.forgot_password}</strong></a> */}
                    </div>
                </form>
            </div>
        </Card>
    );
};
export default React.memo(LogIn);


// http://localhost:3001/