const dev = {
    app: {
        name: 'ISME SRL Local'
    },
    api: {
        internal: `${window.location.origin.replace(window.location.protocol, 'http:').replace(window.location.port, '8081')}/api`,
        old: `${window.location.origin}/old`
    },
    platforms: {
        support: ''
    }
};


const prod = {
    app: {
        name: 'ISME SRL'
    },
    api: {
        internal: `${window.location.origin}/api`,
        old: `${window.location.origin}/old`
    },
    platforms: {
        support: ''
    }
};


const config = process.env.NODE_ENV === "development" ? dev : prod;

export default config;