import React from 'react';
import { Tooltip, TooltipProps } from 'recharts';

const units = [' t/h', ' t/h', ' interventions'];


// Define a custom tooltip component
const CustomTooltip = ({ active, payload, label }:any) => {

  if (active && payload?.length) {
    
    return (
      <div style={{backgroundColor:'var(--white)', padding:'10px', borderRadius:'5px', border:'1px solid var(--gray-700)'}}>
        <strong>{`${payload[0].payload.date}`}</strong>
        {payload.map((entry:any, index:any) => (
          <p key={`item-${index}`} style={{ color: entry.color }}>
            {`${entry.name}: ${entry.value.toFixed(2)}${units[index]}`}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
