import './CustomIconButton.scss';   // import './css/CustomIconButton.scss';


import React from 'react';

import { IconButton, Tooltip } from '@mui/material';

// TODO: Typization after project recovery

const CustomIconButton: React.FC<any> = (props: any) => {

    let className = 'iconButton ';
    switch(props.variant){
        case "primary": {
            className += 'btn-primary ';
            break;
        }
        case "secondary": {
            className += 'btn-secondary ';
            break;
        }
        case "success": {
            className += 'btn-success ';
            break;
        }
        case "warning": {
            className += 'btn-warning ';
            break;
        }
        case "danger": {
            className += 'btn-danger ';
            break;
        }
        case "info": {
            className += 'btn-info ';
            break;
        }
        case "light": {
            className += 'btn-light ';
            break;
        }
        case "dark": {
            className += 'btn-dark ';
            break;
        }
        case "blank": {
            className += 'btn-blank ';
            break;
        }
        default: {
            className += 'btn-primary ';
            break;
        }
    }


    if (props.class) {
        className += props.class;
    }
    
    let childrens = null;
    if( props.children && Array.isArray(props.children) ){
        childrens = props.children.map((component: any, i: number) => component)
    }else{
        childrens = props.children
    }

    
    switch(props.size) {
        case 'small':
        case 'sm': {
            className += 'btn-sm ';
            break;
        }
        
        case 'large':
        case 'lg': {
            className += 'btn-lg ';
            break;
        }
    }

    return (

        <Tooltip placement={props.placement} title={props.title}>
            <IconButton
                id={props.id ? props.id : null}
                className={className}
                size={props.size}
                disabled={props.disabled ? true : false}
                sx={props.sx}
                type={props.submit ? "submit" : undefined}
                ref={props.ref || null}
                onClick={(e) => {
                    if(props.onClick){
                        props.onClick(e)
                    }else{
                        console.log('onClick')
                    }
                }}
                style={props.style}
            >
                {
                    childrens
                    ||
                    <img
                        src={props.icon}
                        alt={props.alt || props.name || null}
                    // style={{ height: props.size === "small" ? "15px" : "18px", width: props.size === "small" ? "15px" : "18px" }}
                    />
                }
            </IconButton>
        </Tooltip>
    );
}

export default React.memo(CustomIconButton);