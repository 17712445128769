import en from "./languages/en";
import it from "./languages/it";
import fr from "./languages/fr";

import LocalizedStrings from 'react-localization';

const supported_languages = ['it', 'en'];

function languages(language = navigator.language.split('-')[0]) {
    // console.log(language);
    if(!supported_languages.includes(language)){
        language = navigator.language.split('-')[0];
        if(!supported_languages.includes(language)){
            language = 'en';
        }
    }

    let strings = new LocalizedStrings<any>({
        'en': en,
        'it': it
    });

    strings.setLanguage(language);
    
    // strings.datagrid = ( (language && language === 'ita') || false ) ? itIT : enUS;

    return strings;
}



export default languages;