// import React from 'react'


const importer = {
    img:{
        require: (filename, extension) => {
            
            if(extension){
                if( extension.includes('.') ){
                    return require('../../assets/images/' + filename + extension);
                }else{
                    return require('../../assets/images/' + filename + '.' + extension);
                }
            }else{
                return require('../../assets/images/' + filename);
            }
        }
    },
    flag:{
        require: (filename, extension) => {
            
            if(extension){
                if( extension.includes('.') ){
                    return require('../../assets/images/flags/' + filename + extension);
                }else{
                    return require('../../assets/images/flags/' + filename + '.' + extension);
                }
            }else{
                return require('../../assets/images/flags/' + filename);
            }
            // return loader('../../assets/images/icons/', {filename, extension})
        }
    },
    ic:{
        require: (filename, extension) => {
            
            if(extension){
                if( extension.includes('.') ){
                    return require('../../assets/images/icons/' + filename + extension);
                }else{
                    return require('../../assets/images/icons/' + filename + '.' + extension);
                }
            }else{
                return require('../../assets/images/icons/' + filename);
            }
            // return loader('../../assets/images/icons/', {filename, extension})
        }
    },
    animated:{
        require: (filename, extension) => {
            
            if(extension){
                if( extension.includes('.') ){
                    return require('../../assets/images/animated/' + filename + extension);
                }else{
                    return require('../../assets/images/animated/' + filename + '.' + extension);
                }
            }else{
                return require('../../assets/images/animated/' + filename);
            }
            // return loader('../../assets/images/icons/', {filename, extension})
        }
    }
}

export default importer;



// const importer = {

//     img:{
//         require: (filename, extension) => {
//             const path = "../../assets/images/";

//             if(extension){
//                 if( extension.includes('.') ){
//                     return require(path + filename + extension);
//                 }else{
//                     return require(path + filename + '.' + extension);
//                 }
//             }else{
//                 return require(path + filename);
//             }
//         }
//     },

//     ic:{
//         require: (filename, extension) => {
//             const path = "../../assets/images/icons/";

//             if(extension){
//                 if( extension.includes('.') ){
//                     return require(path + filename + extension);
//                 }else{
//                     return require(path + filename + '.' + extension);
//                 }
//             }else{
//                 return require(path + filename);
//             }
//         }
//     }

// }



























// const loader = (path, file) => {
//     const { filename, extension = false } = file;

//     if(extension){

//         if( extension.includes('.') ){

//             // if(extension.includes('svg')){
//             //     const tmp = require(path + filename + extension);
//             //     return <img src={tmp} alt="Nome componente" />;
//             // }
//             return require(path + filename + extension);
//         }else{
            
//             // if(extension == 'svg'){
//             //     const tmp = require(path + filename + '.' + extension);
//             //     return <img src={tmp} alt="Nome componente" />;
//             // }
//             return require(path + filename + '.' + extension);
//         }

//     }else{

//         // if( filename.endsWith('.svg') ){
//         //     const tmp = require(path + filename );
//         //     return <img src={tmp} alt="Nome componente" />;
//         // }
//         return require(path + filename);
//     }
// }
