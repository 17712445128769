import './css/Site.scss';
import React, { useState, useEffect, useContext } from 'react';

import Navbar from './Navbar';


import { Routes, Route, Navigate, useLocation } from 'react-router-dom';


import { ctxSession, ctxSettings } from '../store';


import { SessionModel } from '../models';
import { UserData_model } from '../models/data/UserData_model';

import { decodeToken } from 'react-jwt';
import Dashboard from './Dashboard';


import {CustomSnackbarContainer} from '../components';
import Axios from '../helpers/axios';
import config from '../config';

import { SettingsModel } from '../models';

const Site = () => {

    // const navigate = useNavigate();
    const location = useLocation();
    const session = useContext(ctxSession); 

    const [settings, setSettings] = useState<SettingsModel | null>(null);
    

    
    return (
        
            <ctxSettings.Provider value={React.useMemo(() => ({data: settings, set: setSettings}), [settings, setSettings])} >
                <div id="Site">
                <CustomSnackbarContainer>
                    {/* <Sidebar /> */}
                    <div id="content">
                        <Navbar />
                        
                        <div className="pages">
                            <Routes>
                                <Route path="/" element={<Dashboard />} />

                                {/* <Route path="/2" element={<Dashboard />} /> */}

                                <Route
                                    path="*"
                                    element={<Navigate to="/" />}
                                />
                                
                            </Routes>
                        </div>

                    </div>

                </CustomSnackbarContainer>
                </div>
            </ctxSettings.Provider>
    );
};
export default React.memo(Site);

