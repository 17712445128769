import React from 'react';
import './CustomInput.scss';    // import './css/CustomInput.scss';


import {useEffect, useState} from 'react';

// TODO: Typization after project recovery

const strengthLevels = [
    /(?=.*?[a-z])/, // at least 1 digit lowercase
    /(?=.*?[A-Z])/, // at least 1 digit uppercase
    /(?=.*?[0-9])/, // at least 1 digit nomber
    /(?=.*?[#?!@$%^&*-])/, // at least 1 digit special char
    /.{8,}/   // minimum length = 8
]


const CustomInput: React.FC<any> = (props: any) => {

    const {
        style,
        classes,
        type,
        variant,
        error,

        label,
        icon,
        img,
        placeholder,
        name,
        pattern,
        required,
        onChange,
        disabled,
        minLength,
        maxLength,
        min,
        max,
        step,
        fixedLabel,


        showable,

        showStrength,


        value : valueProp,
    } = props;


    const [value, setValue] = useState<string | number | readonly string[] | undefined>( valueProp !== 'NULL' ? (valueProp ?? '') :  '');
    const [focused, setFocused] = useState<boolean>(valueProp ? true : false);

    const [strengthLevel, setStrengthLevel] = useState<number>(0);


    useEffect(() => {
        if(valueProp !== undefined) setValue(valueProp);
    }, [valueProp]);


    const randID = Math.random().toString()
    // console.log(props)
    let className = "customInput ";
    switch(variant){
        case 'underline': {
            className += "inputUnderline ";
            break;
        }
        case 'cloud': {
            className += "inputCloud ";
            break;
        }
        case 'classic': 
        default: {
        }
    }
    
    if(classes) className = className + ' ' + classes + ' ';


    const selectType = () => {
        switch(type){
            case 'password': {
                if(showPassword){
                    return 'text';
                }else{
                    return 'password';
                }
            }
            default: {
                return type
            }
        }
    }

    useEffect( () => {
        if(type === 'password'){
            let level = 0;
            strengthLevels.forEach((v, k) => {
                if(typeof value === 'string' && value?.match(v)){
                    level++;
                }
            })
            
            setStrengthLevel(level)
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const [showPassword, setShowPassword] = useState<boolean>(false);

    return (
        <>                
            <div style={style} 
                className={`${className} ${focused || (value !== null && value !== undefined && value !== "") ? 'focused' : ''} ${error ? 'error' : ''}`} 
                data-field-name={name}>   
                             
                {label &&
                    <label className={fixedLabel ? 'fixed' : 'slide'} htmlFor={randID} data-content={label}>
                        {label}
                    </label>
                }
                
                {
                    (icon || img)
                    &&
                    <div className="icon">
                        {icon ? 
                            icon
                            :
                            <></>
                        }
                        {!icon && img ? 
                            <img src={img} alt="Username Icon" />
                            :
                            <></>
                        }
                    </div>
                }

                <input
                    value={type !== 'password' ? value : undefined}
                    onBlur={() => {
                        if(!value){
                            setFocused(false)
                        }
                    }}
                    onFocus={() => {
                        setFocused(true)
                    }}
                    placeholder={!label && placeholder ? placeholder : ''}
                    id={randID}
                    type={selectType()}
                    name={name ? name : Math.random() }
                    pattern={pattern ? pattern : null }
                    required={required ? true : false}
                    onChange={(e:any) => {
                        setValue(e.target.value)
                        if(onChange){
                            onChange(e.target.value, e);
                        }
                    } }
                    disabled={disabled}
                    minLength={minLength}
                    maxLength={maxLength}
                    min={min}
                    max={max}
                    step={step}
                    onInputCapture={(e:any) => {
                        // console.log(e.target.value);
                    }}
                />

                
                {
                    (showable && type === 'password')
                    &&
                    <div
                        className={'eye ' + (showPassword ? 'show' : 'hide' )}
                        onClick={() =>  {
                            setShowPassword(!showPassword)
                        }}
                    >
                    </div>
                }


                {
                    ( (value !== undefined && value !== null) && showStrength && type === 'password')
                    &&
                    <div className={`showStrength strengthLevel_${strengthLevel}`}>
                        <div>
                        </div>

                        <div>
                        </div>

                        <div>
                        </div>

                        <div>
                        </div>

                        <div>
                        </div>
                    </div> 
                }
            </div>
        </>
    );
}

export default CustomInput;