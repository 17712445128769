import './CustomSelect.scss';   // import './css/CustomSelect.scss';

import React, { useState, useEffect, useRef } from 'react';

import Datetime from '../../helpers/datetime';
import importer from '../../helpers/importer';

import CustomIconButton from '../CustomIconButton/CustomIconButton';

// TODO: Apply typescript, add maxElements to enable scrolling and enableSearch to switch between a fixed span element or an input search
// TODO: in the css file add custom scrollbar and give more highlight to selected element with secondary color and white text

interface CustomSelectProps {
    style?: React.CSSProperties;
    preselected?: number;
    options: Array<{
        label: string;
        value: number;
    }>;
    onChange: (value: number) => void;
}

/**
 * Should be
 = ({
    label,
    fixedLabel,
    placeholder = 'Select an option',
    value,
    preselected,
    onChange,
    options = []
}) => {
 */
const CustomSelect: React.FC<any> = (props:any) => {
    
    const {
        label,
        fixedLabel,
        placeholder = 'Select an option',
        value,
        preselected,
        onChange,
        options = []
    } = props;

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState(null);
    // console.log(options)
    const [hasBeenSelected, setHasBeenSelected] = useState<boolean>(false);

    const [focused, setFocused] = useState<boolean>( (value || preselected) ? true : false );
    
    useEffect( () => {
        if( options.length /*&& props.value*/ ){

            if(value !== null && value !== undefined){
                const i = options.findIndex((o:any, i:number) => {
                    return o.value === value;
                });
    
                selectOption(i);
            }
            else{
                selectOption(null)
            }
        }
    }, [options, value]);


    const toggleSelect = () => {
      setIsOpen(!isOpen);
    };
  
    const selectOption = (optionIndex:any) => {
      setSelectedOption(optionIndex);
      setIsOpen(false);
      setHasBeenSelected(true);
    };

    let className = 'customSelect ';
    switch(props.variant){
        case 'fullwidth':{
            className += 'fullwidth '
            break;
        }
        case 'cloud': {
            className += "selectCloud ";
            break;
        }
        case 'auto':
        case 'classic':
        default: {
            
        }
    }

    switch(props.size){
        case 'sm':
        case 'small': {
            className += 'sm '
            break;
        }
        case 'medium':
        case 'md':
        default: {
            break;
        }
        case 'large':
        case 'lg': {
            className += 'lg '
        }
    }

    if(props.classes){
        className += props.classes;
    }

    
    let preselectedIndex = options.findIndex((v:any, i:number) => {
        return v.value === preselected;
    });
    if(preselectedIndex === -1){
        preselectedIndex = null;
    }
    
    const ref = useRef<any>();

    useEffect( () => {
        if(onChange && selectedOption !== null && hasBeenSelected){
            onChange(options[selectedOption].value, options[selectedOption].data || null, selectedOption, ref);
        
            setHasBeenSelected(false);
        }
    }, [options, selectedOption, hasBeenSelected, onChange]);

    
    useEffect(() => {
        if(isOpen){
            const handleOutsideClick = (event: MouseEvent) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsOpen(false);
                }
            };

            document.addEventListener('click', handleOutsideClick);

            return () => {
                document.removeEventListener('click', handleOutsideClick);
            };
        }
    }, [isOpen]);
    

    const [matchingOptionIndex, setMatchingOptionIndex] = useState<any>(-1); // Aggiunto
    
    const scrollToOption = (index:number) => {
        let totalHeight = 0;
        for (let i = 0; i < index; i++) {
            const optionElement = scrollRef.current.children[i];
            const { height } = optionElement.getBoundingClientRect();
            totalHeight += height;
        }
        scrollRef.current.scrollTop = totalHeight;
    }
    
    // const keyDownListenerRef = useRef<any>();
    const digits = useRef<string>("");
    const lastDigitTS = useRef<number>(0);
    const scrollRef = useRef<any>();
    useEffect(() => {
        // Collega la funzione di gestione degli eventi della tastiera
        if (isOpen) {
            const handleKeyDown = (event:any) => {
                // console.log(event)
                if (isOpen) {
                  // Verifica se la tendina è aperta
                  switch (event.key) {
                    case 'ArrowDown': {
                        if( matchingOptionIndex < options.length-1 ){
                            if(selectedOption !== null && matchingOptionIndex === -1) {
                                setMatchingOptionIndex( selectedOption+1 );
                            }
                            else{
                                setMatchingOptionIndex( matchingOptionIndex+1 );
                            }
                        }
                        break;
                    }
                    case 'ArrowUp': {
                        if( matchingOptionIndex > 0 ){
                            setMatchingOptionIndex( matchingOptionIndex-1 );
                        }else{
                            if(selectedOption != null){
                                setMatchingOptionIndex( selectedOption-1 );
                            }
                        }
                        break;
                    }
                    case 'Enter': {
                        // console.log(matchingOptionIndex)
                        if(matchingOptionIndex  > -1){
                            selectOption(matchingOptionIndex);
                            // setMatchingOptionIndex( null );
                        }
                        break;
                    }
                    default: {
                        const now = new Datetime().getUnixTimestampMillis();
                        if(now - lastDigitTS.current >= 375) {   // 40 parole al minuto 60/40, considerati 4 digit al secondo  (60/40)/4
                            digits.current = "";
                        }
                        lastDigitTS.current = now;
                        // Cerca un'opzione corrispondente quando si digita sulla tastiera
                        const typedChar = event.key.toLowerCase();
                        digits.current += typedChar;
                        const matchingIndex = options.findIndex(
                            (option:any) =>
                            option.label.toLowerCase().startsWith(digits.current) && !option.disabled
                        );
                        
                        scrollToOption(matchingIndex);

                        setMatchingOptionIndex(matchingIndex)
                    }
                  }
                }
              };

            document.addEventListener('keydown', handleKeyDown);

            // Rimuovi l'ascoltatore degli eventi quando il componente viene smontato
            return () => {
                document.removeEventListener('keydown', handleKeyDown);
            };
        }else{
            digits.current = "";
            lastDigitTS.current = 0;
            setMatchingOptionIndex( -1 );
        }

    }, [isOpen, options, matchingOptionIndex, selectedOption]);


    useEffect( () => {
        if(isOpen){
           
            if(selectedOption !== null && matchingOptionIndex === -1){
                scrollToOption(selectedOption);
            }else{
                scrollToOption(matchingOptionIndex);
            }
        }
    }, [isOpen, matchingOptionIndex, selectedOption])

  
    return (
        <div ref={ref} 
            className={`${className} ${focused || (value !== null && value !== undefined && value !== "") ? 'focused' : ''}`} 
            style={props.style} 
            id={props.id}
            data-field-name={props.name}> 

            { label && <label data-content={label} >{label}</label> }
            <div className={ `selectedOption` } onClick={toggleSelect}>
                <span>
                { ( (selectedOption !== null || preselectedIndex !== null) ) ? options[selectedOption  !== null ? selectedOption : preselectedIndex ].label : placeholder || ''}
                </span>
                {
                    isOpen
                    ?
                    <img className='arrow' src={importer.ic.require('chevronTop.svg')} alt="" />
                    :
                    <img className='arrow' src={importer.ic.require('chevronBottom.svg')} alt="" />
                }
            </div>
            {isOpen && (
                <div ref={scrollRef} className="options">
                    {options && 
                        options.map((option:any, index:number) => (
                        <div
                            key={index}
                            className={`option ${index === matchingOptionIndex ? 'matching' : '' } ${ (preselectedIndex !== null || selectedOption !== null) ? options[selectedOption !== null ? selectedOption : preselectedIndex ].value === option.value ? 'selected' : '' : ''}`}
                            onClick={() => {
                                selectOption(index)
                            }}
                        >
                            <span>
                            {option.label}
                            </span>
                            {
                                option.action
                                ?
                                <div className="action">
                                    {}
                                    <CustomIconButton
                                        onClick={(e:MouseEvent) => {
                                            e.stopPropagation();
                                            option.action.onClick(e, option.value, option.label, option.data);
                                        }}
                                    >
                                        {option.action.icon}
                                    </CustomIconButton>
                                </div>
                                :
                                <></>
                            }
                        </div>
                        ))
                    }
                </div>
            )}
            
            <input type="hidden" name={props.name} value={options.length && (preselectedIndex != null || selectedOption != null) ? options[selectedOption  !== null ? selectedOption : preselectedIndex ].value : ''} />
        </div>
    );
  };

export default React.memo(CustomSelect);