import './css/SplashScreen.scss';
import React, { useEffect, useContext } from 'react';

import { useLocation } from 'react-router-dom';

import { ctxSession, ctxSettings } from '../store';

import config from '../config';

import { SessionModel } from '../models';
import { UserData_model } from '../models/data/UserData_model';

import Axios from '../helpers/axios';
import importer from '../helpers/importer';

import { decodeToken } from 'react-jwt';


const SplashScreen = () => {
    
    const location = useLocation();

    const session = useContext(ctxSession);


    useEffect( () => {
        if(session && session.data && !session.data?.userData ){

            const onSuccess = (response:any) => {
                if(session && session.data && session.set){
                    const decodedToken : UserData_model | null = decodeToken(response.data.token);
        
                    const sess : SessionModel = {
                        userData: decodedToken,
                    }
                    session.set(sess)

                }
            }

            const onError = (error:any) => {
                // localStorage.clear();
                // if(session && session.set){
                //     session.set(null)
                // }
                if(!error.response){
                    if(error.code === 'ERR_NETWORK'){
                        setTimeout(canILog, 1000);
                    }
                }
                console.log(error)
            }

            const canILog = () => {
                Axios(session, 'get', `${config.api.internal}/auth`, onSuccess, onError);
            };

            canILog();
        }
    }, [session, location] ); //React Hook useEffect has a missing dependency: 'session'

    return  (
        <div id="SplashScreen">
            {/* <img src={importer.animated.require('loading.gif')} /> */}
            <img src={importer.img.require('fullLogo.png')} />
        </div>
    );
};
export default React.memo(SplashScreen);