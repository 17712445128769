import './CustomModal.scss';    // import './css/CustomModal.scss';


import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import ReactDOM from 'react-dom';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';


import Title from '../Title/Title';

const CustomModal: React.FC<any> = (props: any) => {

    const {scrollableBody} = props;

    const [ isOpen, setIsOpen ]= useState<boolean>( Boolean(props.isOpen) );
    const [ hider, setHider ]= useState<boolean>(false);
    // const [ isHiding, setIsHiding ]= useState<boolean>( false );

    let classes = props.class + ' ';
    switch(props.titlePosition){
        case 'right':
        case 'end': {
            classes += 'titleEnd ';
            break;
        }
        case 'center': {
            classes += 'titleCenter ';
            break;
        }
        case 'left':
        case 'start':
        default: {
            break;
        }
    }

    useEffect(() => {
        modalToggler(Boolean(props.isOpen));
    }, [props.isOpen]);
    
    const modalToggler = useCallback((value:boolean|null = null) => {
        let val = (value != null) ? value : !isOpen ;

        if(val){
            setIsOpen(val);
            setHider(false);
        }
        else{
            setHider(true);
            setTimeout(() => {
                setIsOpen(val);
            }, 100);
        }
    }, [isOpen]);
    
    
    const overlayRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const closeButton : boolean = props.closeButton ? Boolean(props.closeButton) : true ;


    let childrens = null;
    if( props.children && Array.isArray(props.children) ){
        childrens = props.children.map((component: any, i: number) => component)
    }else{
        childrens = props.children
    }


    const modalToolkit = useMemo(() => {
        
        if(props.modalToolkit){
            return (
                <div className="modal-toolkit" onClick={(e) => {
                    e.stopPropagation()
                }}>
                    {
                        props.modalToolkit.map((v:any, k:number) => 
                            <div className="modal-tool-container" key={k}>
                                {
                                    v.map((_v: any, _k: number) => <div onClick={_v.action} key={_k}>{_v.icon}</div> )
                                }
                            </div>
                        )
                    }
                </div>
            );
        }

        return <></>
    }, [props.modalToolkit]);


    useEffect(() => {
        if(!isOpen && props.onClose) props.onClose();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);


    const [contentHighlight, setContentHighlight] = useState<boolean|null>(null);




    return (
        <>
        {isOpen ?
            ReactDOM.createPortal(
            <div
                ref={overlayRef}
                className={`modal-overlay ${hider ? 'hide' : ''}`} //
                onClick={(e:any) => {
                    if(props.onOverlayClick) props.onOverlayClick();
                    
                    if(props.closeOnOverlayClick) {
                        if(!containerRef.current?.contains(e.target)){
                            modalToggler(false);
                        }
                    }
                    else if( e.target.classList.contains('modal-overlay') || e.target.classList.contains('modal-container') ) {
                        setContentHighlight(true);
                        setTimeout(() => {
                            setContentHighlight(false);
                        }, 500);
                    }
                }}
            >
                <div className="modal-container">
                    
                    <div
                        ref={containerRef}
                        id={props.id}
                        className={`modal-content ${classes ?? '' } ${scrollableBody ? 'scrollableBody' : ''} ${ contentHighlight ? 'highlight' : contentHighlight != null ? 'anim-unset' : '' }`}
                        // onClick={(e) => e.stopPropagation()}
                    >

                        {
                            ( props.title || closeButton )
                            ?
                            <div className="modal-header">
                                <Title>{props.title}</Title>
                                {
                                    closeButton
                                    &&
                                    <div
                                        className='closeButton'
                                        onClick={() => {
                                            // setIsOpen(false);
                                            modalToggler(false);
                                        }}
                                    >
                                        <CloseRoundedIcon />
                                    </div>
                                }
                            </div>
                            :
                            <></>
                        }

                        <div className="modal-body">
                        {
                            childrens
                        }
                        </div>
                        

                        {
                            props.footer
                            &&
                            <div className="modal-footer">
                            {props.footer}
                            </div>
                        }


                    </div>
                </div>

                {modalToolkit}
            </div>,
            document.getElementById('root') as Element
        )
        :
        <></>
    }
        
        </>
    );
}

export default React.memo(CustomModal);