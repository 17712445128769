import React from 'react';
import { Tooltip, TooltipProps } from 'recharts';


const CustomTooltipContent: React.FC<TooltipProps<any, any>> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        const data: any = payload[0].payload;

        // Format the value as a string in European currency format
        let formattedValue: string = String(Math.round((data.loc as number) * 1000) / 1000);
        formattedValue = new Intl.NumberFormat('it-IT', {
            minimumFractionDigits: 2, 
            maximumFractionDigits: 2,
        }).format(data.loc as number);

        // Format the gain value as a string in European currency format
        let formattedGainValue: string = String(Math.round((data.gain as number) * 1000) / 1000);
        formattedGainValue = new Intl.NumberFormat('it-IT', {
            minimumFractionDigits: 2, 
            maximumFractionDigits: 2,
        }).format(data.gain as number);

        // Format the co2 value as a string in European currency format
        let formattedCO2Value: string = String(Math.round((data.co2 as number) * 1000) / 1000);
        formattedCO2Value = new Intl.NumberFormat('it-IT', {
            minimumFractionDigits: 2, 
            maximumFractionDigits: 2,
        }).format(data.co2 as number);

        return (
            <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
                <p>Data Riparazione: {data.formattedDate || 'N/A'}</p>
                <p>Perdita Economica: {formattedValue} € </p>
                <p>Rientro Economico: {formattedGainValue} € </p>
                {/* <p>Perdita CO2: {formattedCO2Value} € </p> */}
                <p>Q.tà Interventi: {data.interventionsAmount}</p>
                {/* You can dynamically add more data points here if needed */}
            </div>
        );
    }

    // Return null if not active or no data is available
    return null;
};

export default React.memo(CustomTooltipContent);