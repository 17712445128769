import errors from './errors';
import universal from '../../universal';

export default {

    errors,
    universal,

    home: 'Homepage',
    test: 'Test',

    field_data_survey: 'Qualifiche',
    field_data_monitor: 'Monitor Qualifiche',
    export: 'Esportazione',
    import: 'Importazione',
    fixed_network: 'Rete Fissa',
    import_export: 'Import/Export',
    infoworks_export: 'Esportazione Infoworks',
    dat_export: 'Esportazione Dat',


    network_analysis: 'Analisi Network',
    lab_analytics: 'Analitiche Laboratorio',
    performances: 'Prestazioni',
    trash_by_statistics: 'Statistiche Trash-By',

    monitor: 'Monitor',
    consumption_monitor: 'Monitor Consumi',
    district_monitor: 'Monitor Distretto',
    pressure_monitor: 'Monitor Pressioni',
    realtime_monitor: 'RealTime Monitor',

    operating_tools: 'Strumenti Operativi',
    user_registries: 'Anagrafiche Utente',
    route_analysis: 'Analisi Giri',
    logical_paths: 'Percorsi Logici',

    workforcemanagement: 'Workforce Management',
    replacement: 'Sostituzioni',
    replacement_interventions: 'Interventi di Sostituzione',
    planning: 'Pianificazione',

    synchronization: 'Sincronizzazione',
    qualifications_sync: 'SYNC Qualifiche',
    replacements_sync: 'SYNC Sostituzioni',
    walk_by_drive_by_sync: 'SYNC Walk-by/Drive-by',
    WFM_sync: 'SYNC WFM',
    work_force_management: 'Work Force Management',

    work_progress_status: 'Stato Avanzamento Lavori',




    "e-mail" : "e-mail",
    wrong_email_password : "e-mail o password errati",
    login : "Accedi",
    username: "Username",
    password: "Password",
    log_in: "Accesso",
    log_in_into_your_account: "Accedi al tuo account",
    forgot_password: "Password dimenticata?",
    reset_password: "Reimposta password",
    reset: "Reimposta",
    back_to_login: "Torna al Login",
    password_request_completed: 'Richiesta di reset della password eseguita con successo.',
    check_your_inbox: 'Controlla la tua casella di posta.',
    didnt_receive_email: 'Non hai ricevuto l\'email?',  //Didn't receive the email?
    send_again: 'Invia di nuovo',
    repeat_password: "Ripeti password",
    password_reset_request_failed_or_expired: 'La richiesta di reset della password è fallita o scaduta.',
    password_reset_successfully: 'La password è stata reimpostata con successo!',
    logout : "Disconnetti",
    

    alarm: 'Allarme',
    alarms: 'Allarmi',
    alarmsDictionary: {
        no_alarm: 'NO ALARM',   //null
        BF: 'FLUSSO INVERSO',
        PE: 'PERSISTENZA',
        BX: 'BATTERIA',
        FM: 'SENSORE',
        TP: 'MANOMISSIONE',
        EX: 'CONSUMO ECCESSIVO',
        EM: 'CONSUMO ALTO',
        RS: 'AZZERAMENTO',
        DF: 'FLUSSO DIRETTO',
        TK: 'RIPRISTINO MANOMISSIONE',
        RT: 'RESET',
        CR: 'FIRMWARE ERROR',
        PM: 'MAGNETE PROGRAMMAZIONE',
        MG: 'MANOMISSIONE MAGNETICA',
        MC: 'MANOMISSIONE MECCANICA',
        RV: 'MONTATO AL CONTRARIO',
        AD: 'RILEVATA ARIA',
        BP: 'TUBATURA ROTTA',
        DR: 'ASCIUTTO',
        MT: 'METROLOGY',
        SS: 'SISTEMA',
        WQ: 'QUALITA ACQUA',
        PF: 'FERMO',
        LT: 'TEMPERATURA BASSA',
        PX: 'PERSISTENZA ECCESSIVA',
        PP: 'PERSISTENZA PASSATA',
        PI: 'PERDITA INTERMITTENTE',
        122: 'MOROSO',
        242: 'IN DISDETTA',
        342: 'DISDETTATO',
        306: 'RIMOSSO',
        132: 'ALLARME SCONOSCIUTO',
        116: 'SOSTITUITO',
        206: 'RIMOSSO',
        999: 'ALLARME SCONOSCIUTO',
        ICSE: 'IN CORSO DI CESSAZIONE',
        CNFA: 'CESSATA DA FATTURARE',
        CFAT: 'CESSATA FATTURATA',
        ICSO: 'IN CORSO DI SOSPENSIONE',
        SOSP: 'SOSPESO',
        1: 'STATO SERVIZIO 1',
        2: 'STATO SERVIZIO 2',
        3: 'STATO SERVIZIO 3',
    },

    utilities:'Utenze',
    meter: 'Meter',
    meters: 'Meters',
    smart_meter: 'Smart Meter',
    non_smart: 'Non-Smart',
    towns: 'Comuni',
    municipalities: 'Comuni',
    valid_readings:'Letture Valide',
    readings:'Letture',
    active_users: 'Utenti attivi',

    brand: 'Marca',
    brands: 'Marche',

    communication: 'Comunicazione',
    communication_type: 'Tipo Comunicazione',

    device_type: 'Tipo Device',

    successfully_updated: 'Aggiornato con successo!',
    server_error: 'Errore del Server.',

    pick_a_date: 'Seleziona data',
    date_format: 'gg/mm/aaaa',
    confirm: 'Conferma',
    selected_days: 'Giorni selezionati',

    all_towns: 'Tutti i comuni',
    all_municipalities: 'Tutti i comuni',

    failed_update_no_changes: `Aggiornamento non avvenuto.\r\nNon hai apportato modifiche al record.`,


    last_30_days: 'Ultimi 30 giorni',
    last_7_days: 'Ultimi 7 giorni',

    language: 'Lingua',
    back: 'Indietro',
    cancel: 'Annulla',
    
    really_want_logout: 'Vuoi davvero effettuare la disconnessione?',

    "Lavorazione Sospesa": "Lavorazione Sospesa",
    "Sostituito": "Sostituito",
    "Mancata Rimozione": "Mancata Rimozione",
    "Mancata Sostituzione": "Mancata Sostituzione",
    "Non Lavorato": "Non Lavorato",
    "Rimosso": "Rimosso",

    save: 'Salva',

    plan: 'Piano'
};