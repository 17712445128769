import React, { useState, useEffect, useMemo } from 'react';
import './App.scss';
import './animations.scss';


import { ctxSession } from './store';
import { SessionModel, SnackbarModel } from './models';

import SplashScreen from './viewmodels/SplashScreen';
import Site from './viewmodels/Site';
import Auth from './viewmodels/Auth';



function App() {
   
    // let main = <div>No Website</div>;

    const [session, setSession] = useState<SessionModel | null>({
        userData: null,
    })

    
    const main = useMemo( () => {
        if( session && !session.userData ){
            return <SplashScreen />
        }
        else if( session && session.userData ){ //&& session.userData
            return <Site />
        }else{
            // return <Site />
            return <Auth />
        }
    }, [session]);


    return useMemo(() => (
        <ctxSession.Provider value={{data: session, set: setSession}} >
            {main}
        </ctxSession.Provider>
    ), [session]);
}

export default App;
