import React, { useRef } from 'react';
import './CustomButton.scss';
import { Tooltip } from '@mui/material';

interface CustomButtonProps {
    title?: string;
    placement?: 'bottom' | 'top' | 'right' | 'left';  // Add other placements if needed
    variant?: 'primary' | 'secondary' | 'emphasis' | 'success' | 'warning' | 'danger' | 'info' | 'light' | 'dark' | 'blank';
    size?: 'small' | 'sm' | 'large' | 'lg';
    classes?: string;
    onClick?: () => void;
    nospam?: boolean;
    id?: string;
    type?: 'button' | 'submit' | 'reset';
    disabled?: boolean;
    children?: React.ReactNode;
    style?: React.CSSProperties;  
    width?: number;  
}

const CustomButton: React.FC<CustomButtonProps> = ({
    title,
    placement = 'bottom',
    variant = 'primary',
    size,
    classes,
    onClick,
    nospam,
    id,
    type = 'button',
    disabled,
    children,
    width,
    style
}) => {
    const lastClick = useRef<number>(Date.now());

    let className = "btn " + `btn-${variant} `;

    if (size) {
        className += size === 'small' || size === 'sm' ? 'btn-sm ' : '';
        className += size === 'large' || size === 'lg' ? 'btn-lg ' : '';
    }

    if (classes) {
        className += ' ' + classes;
    }

    const handleClick = () => {
        if (onClick && (!nospam || Date.now() > lastClick.current)) {
            onClick();
            lastClick.current = Date.now() + 2000; // 2 seconds delay for nospam
        }
    };

    const buttonElement = (
        <button
            onClick={handleClick}
            id={id}
            className={className}
            type={type}
            disabled={disabled}
        >
            {children}
        </button>
    );

    return (
        <>
            {title ? (
                <Tooltip placement={placement} title={title}>
                    {buttonElement}
                </Tooltip>
            ) : (
                buttonElement
            )}
        </>
    );
}

export default React.memo(CustomButton);
